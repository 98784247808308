import React, { useEffect, useState } from 'react';
import './Login.css';

import CardImage1 from '../../../Assets/Image_1.webp';
import CardImage2 from '../../../Assets/Image_2.jpeg';
import CardImage3 from '../../../Assets/Image_3.jpeg';
import CardImage4 from '../../../Assets/Image_4.jpeg';

import StarteleLogo from '../../../Assets/Star.png'
import { useAuth } from '../../../AuthContext'; // Update the import path accordingly

import { Link } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../../Typography";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import {
    styled,
    Button,
    FormControl,
    Switch,
    FormGroup,
    FormControlLabel, Radio,
    TextField,
    Checkbox,
    Box,
    OutlinedInput,
    withTheme,
} from '@mui/material';
import { Label } from '@mui/icons-material';
import { Bounce, Flip, Slide, toast, Zoom } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import OtpTimer from 'otp-timer'
import Modal from "@mui/material/Modal";
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Bars, ColorRing, FallingLines, Hourglass, RotatingLines } from 'react-loader-spinner';


const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "10px",
    border: "0px",
    p: 3,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#107a02d8",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color:
                theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#afafd1" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));

const Login = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const { login } = useAuth();

    // For the Login Page States
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState('');
    const [emailFlag, setEmailFlag] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [Remember, setRemember] = useState(false)
    const [ShowLoginPassword, setShowLoginPassword] = useState(false);
    const [ShowLoginButtonLoader, setShowLoginButtonLoader] = useState(false)


    const [OpenVerifyEmailModal, setOpenVerifyEmailModal] = useState(false); // This state is for opening Verify Email Modal.
    const SignUpEmailForOtpVerify = localStorage.getItem("SignUpEmailForOtpVerify");
    const [VerifyOtp, setVerifyOtp] = useState("");

    // verify email button showing state 
    const [VerifyEmailButton, setVerifyEmailButton] = useState(false);

    const togglePasswordVisibility = (value, SetValue) => {
        SetValue(!value);
    };


    console.log(REACT_APP_BASE_API_URL,'______________________')
    const HandleLogin = (e) => {

        setShowLoginButtonLoader(true)

        e.preventDefault();
        if (!email || !password) {
            toast.warn("Please fill in all required fields", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });

            setShowLoginButtonLoader(false)
            return;
        }
        if (!emailFlag) {
            toast.warn("Please enter valid email address", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": email,
            "password": password
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/login`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                if (result.Status === "1") {
                    setShowLoginButtonLoader(false)
                    navigate('/homePage');

                    handleGetResume(result.accounts.user_uuid)

                    localStorage.setItem('FullName', result.accounts.full_name)
                    localStorage.setItem('EmailId', result.accounts.email_id)
                    localStorage.setItem('UserUuid', result.accounts.user_uuid)
                    localStorage.setItem('EnterpriseUuid', result.accounts.tenant_uuid)
                    localStorage.setItem('Account_Type', result.accounts.account_type)
                    localStorage.setItem('MobileNumber', result.accounts.mobile_number)
                    localStorage.setItem('EmailId', result.accounts.email_id)
                    localStorage.setItem('Designation', result.accounts.designation)
                    localStorage.setItem('Experience', result.accounts.experience)

                    localStorage.setItem('isLoggedIn', "true")


                    localStorage.setItem('CheckProfileId', 'profile_uuid' in result.profiles)

                    if (result.accounts.account_type === "Enterprise") {
                        localStorage.setItem('CheckCompanyProfileID', 'company_profile_uuid' in result.enterprises)
                    }

                    setInterval(() => {
                        window.history.pushState(null, null, window.location.href);
                        window.onpopstate = function () {
                            window.history.go(0);
                        };
                    }, 500);

                    login() //  Call the login function from context
                    localStorage.setItem('isLoggedIn', "true")

                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });

                } else if (result.Status === "0") {
                    setShowLoginButtonLoader(false)

                    if (result.Msg === "Email Addess is not verified with us!,Please verify your Email Id first.")
                        setVerifyEmailButton(true);
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    const handleGetResume = (useruuid) => {
        const requestOptions = {
            method: "POST",
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/get_resume_data?user_uuid=${useruuid}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    localStorage.setItem('CheckResume', 'resumes' in result)
                }
            })
    };


    // Login page validation start here
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        validateEmail(newEmail);

    }

    const validateEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!email) {
            setEmailError("Please enter your email id");
            setEmailFlag(false);
        } else if (!emailPattern.test(email)) {
            setEmailError("Invalid email address");
            setEmailFlag(false);
        } else {
            setEmailError("");  // Clear any previous error
            setEmailFlag(true); // Set flag to true for valid email
        }
    }

    const validatePassword = (password) => {
        if (!password) {
            setPasswordError("Please Enter Your Password");
        } else {
            setPasswordError("");
        }
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const CardArray = [
        {
            "img": CardImage1,
            "title": "Your 360° Talent Solution",

            "KeyPoints": [
                "Streamline candidate assessments with our advanced , data driven insights.",
                "Efficient and effective hiring with startele-hire.",
                "Designed to revolutionize hiring process.",
                "Refine your skills & boost confidence.",
            ]
        },
        {
            "img": CardImage4,
            "title": "AI Driven Interviews",
            "KeyPoints": [
                "24/7 Interview Room.",
                "More personalized interview experience.",
                "Efficient screening & predictive power.",
                "Data-driven decisions / insights.",
                "Enhanced diversity and inclusion",
            ]
        },
        {
            "img": CardImage3,
            "title": "Benefits For Enterprises",
            "KeyPoints": [
                "Cost effective hiring process.",
                "Intelligent decision making.",
                "Scalable hiring solutions.",
                "Collaborative platform.",
            ]
        },
        {
            "img": CardImage2,
            "title": "Benefits For Job Seekers",
            // background: " linear-gradient(to left right , #eff9f8, #eff9f8, #eff9f8, #ffffff, #ffffff)",
            "KeyPoints": [
                "Get noticed",
                "Instant feedback & more opportunities.",
                "Connect with great companies.",
                "Customised interview prep.",
                "Confidence building.",
            ]
        }
    ]
    const [CardIndex, setCardIndex] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setCardIndex((prevIndex) => (prevIndex + 1) % CardArray.length);
        }, 5000);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, [CardArray.length]);


    // verify email api function start here

    const HandleResendOtp = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            email_id: email,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };


        fetch(`${REACT_APP_BASE_API_URL}/resend_otp`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    setOpenVerifyEmailModal(true)
                }
            });
    };

    const HandleVerifyEmail = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if (!VerifyOtp) {
            toast.warn("Please enter the OTP.", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }

        const raw = JSON.stringify({
            email_id: email,
            otp: VerifyOtp,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_BASE_API_URL}/verify_email`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    setOpenVerifyEmailModal(false);
                    navigate("/login");
                    setVerifyOtp("");
                    window.location.reload();
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            });
    };

    return (

        <>

            <div className='Login'>
                {
                    CardArray.map((card, index) => {
                        return (
                            <div className='Box_1' key={index} style={{
                                // backgroundImage: card.background ? `${card.background     }, url(${card.img})` : `url(${card.img})`,
                                // backgroundImage: `url(${card.img})`,

                                display: CardIndex !== index ? "none" : "block",
                                webkitBorderRadius: "10px 0px 10px 0px",
                                mozBorderRadius: "50px",
                                borderadius: "50px",



                            }} >
                                <img src={card.img} alt="images" />
                                <div className="Card" >
                                    <H2>{card.title}</H2>
                                    <ul>
                                        {
                                            card.KeyPoints.map((points, index2) => {
                                                return <li key={index2} > <p>
                                                    <DoneIcon id="TickIcon" /> {points} </p>
                                                </li>
                                            })
                                        }

                                    </ul>

                                    <div>
                                        <Button
                                            style={{
                                                fontSize: "15px",
                                                color: "white",
                                                backgroundColor: "#176D96",
                                                borderRadius: "10px",
                                                padding: '6px 18px',
                                                margin: '20px 30px',
                                                alignItems: 'flex-start'
                                            }}
                                            onClick={() => { navigate('/signUp') }}
                                        >
                                            Sign Up
                                        </Button>
                                    </div>
                                </div>
                            </div>)
                    })
                }

                <Box component="form" onSubmit={HandleLogin} className='Box_2' >
                    <div id='LoginTitle' >
                        <H1>Login Page </H1>
                        <img src={StarteleLogo} />
                    </div>

                    <div id="login_data" >

                        <TextField
                            label="Email Id"
                            variant="outlined"
                            autoComplete='off'
                            inputProps={{ tabIndex: "1" }}
                            value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            onChange={handleEmailChange}
                            style={{ margin: '5px 0px' }}
                        />
                        {emailError && (
                            <div style={{ color: "red", fontSize: "14px" }}>
                                {emailError}
                            </div>
                        )}



                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                            }}
                        >
                            <TextField
                                fullWidth
                                type={ShowLoginPassword ? "text" : "password"}
                                label="Password"
                                variant="outlined"
                                autoComplete='off'
                                inputProps={{ tabIndex: "2" }}
                                value={password}
                                // onChange={(e) => setPassword(e.target.value)}
                                onChange={handlePasswordChange}
                                style={{ margin: '20px 0px' }}
                            />

                            <span
                                onClick={() => togglePasswordVisibility(ShowLoginPassword, setShowLoginPassword)}
                                style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "40%",
                                    right: "5%",
                                    fontSize: '20px',
                                    color: '#4B64FF'
                                }}
                            >
                                {ShowLoginPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                            {passwordError && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                    {passwordError}
                                </div>
                            )}

                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginTop: '10px' }} >
                            <FormControl component="fieldset">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <IOSSwitch
                                                name="Remember Me"
                                                checked={Remember}
                                                onChange={() => {
                                                    setRemember(!Remember);
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography style={{ fontSize: '16px', marginLeft: '10px' }}>
                                                Remember Me
                                            </Typography>
                                        } />
                                </FormGroup>
                            </FormControl>

                            <p style={{
                                color: "#176D96",
                                fontSize: "18px",
                                textDecoration: "underline",
                                cursor: 'pointer'
                            }}
                                onClick={() => { navigate('/forgotPassword') }}
                            >
                                Forgot password?
                            </p>
                        </div>
                    </div>


                    <Button
                        onClick={HandleLogin}
                        type="submit"
                        style={{
                            width: "55%",
                            fontSize: "15px",
                            color: "white",
                            border: "1px solid #011015",
                            backgroundColor: "#176D96",
                            border: "0px ",
                            borderRadius: "10px",
                            margin: '10px 0px ',
                        }}
                    >

                        {ShowLoginButtonLoader ?
                            <RotatingLines
                                visible={true}
                                height="26"
                                width="26"
                                color="white"
                                strokeWidth="5"
                                animationDuration="1"
                                strokeColor='white'
                            /> : "Log In"
                        }
                    </Button>




                    {VerifyEmailButton && (
                        <Button
                            onClick={HandleResendOtp}
                            // type="submit"
                            style={{
                                width: "55%",
                                fontSize: "15px",
                                color: "black",
                                border: "1px solid #011015",
                                backgroundColor: "#90e0ef",
                                border: "0px ",
                                borderRadius: "10px",
                                margin: '10px 0px ',
                            }}
                        >
                            Verify Email
                        </Button>
                    )}

                </Box>
            </div>

            {/* Modal for veryfing the OTP Mail */}
            <Modal open={OpenVerifyEmailModal}>
                <Box sx={ModalStyle2}>
                    <div
                        className="box2_1"
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <H2
                            style={{
                                fontSize: "16px",
                                width: "100%",
                                fontWeight: "300",
                                margin: "10px 0px",
                            }}
                        >
                            A Verificatioin Email with OTP has been sent to your
                            <span style={{ color: "#E25141", textDecoration: "underline" }}>
                                {" "}
                                {email}{" "}
                            </span>{" "}
                            ,
                            <br /> Please Verify it.
                        </H2>

                        <div
                            className="inputInfoBox "
                            style={{ width: "85%", marginBottom: "20px" }}
                        >
                            <OutlinedInput
                                autoFocus={true}
                                type="text"
                                inputProps={{ tabIndex: "1" }}
                                value={VerifyOtp}
                                onChange={(e) => setVerifyOtp(e.target.value)}
                                style={{ width: "100%", height: "40px" }}
                                placeholder="Enter the OTP here."
                            />

                            <H2
                                style={{
                                    fontSize: "14px",
                                    fontWeight: "200",
                                    marginTop: "10px",
                                }}
                            >
                                <OtpTimer
                                    seconds={59}
                                    minutes={2}
                                    resend={HandleResendOtp}
                                    background={"#FFFFFF"}
                                    buttonColor={"#E25141"}
                                    ButtonText="Click to resend OTP"
                                />
                            </H2>
                        </div>

                        <div>
                            <Button
                                onClick={HandleVerifyEmail}
                                style={{
                                    color: "green",
                                    marginRight: "20px",
                                }}
                                variant="outlined"
                                color="inherit"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpenVerifyEmailModal(false);
                                    setVerifyOtp("");
                                }}
                                style={{ color: "indianRed" }}
                                variant="outlined"
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </>
    )
}

export default Login;
