import React from 'react';
import Star from '../../Assets/Star.png';
import './Footer.css';

const Footer = () => {
    return (
        <div className="Footer">
            <p >
                Powered by
            </p>

            <a href="https://www.startelelogic.com/" target='_blank' >
                <span >
                    startelelogic
                </span>
                <img src={Star} style={{ height: '18px' }} />
            </a>
        </div>
    )
}

export default Footer
