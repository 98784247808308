import React, { useEffect, useState } from 'react';
import './AppliedVacancyPage.css';
import { History, WorkHistory } from '@mui/icons-material';
import { Box, Button, Modal, OutlinedInput } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { H2 } from '../../Typography';
import OtpTimer from "otp-timer";
import SentImg from "../../../Assets/Files sent.gif";
import { toast } from 'react-toastify';

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: 'none',
};

const AppliedVacancyPage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;

    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const CurrentDate = new Date()

    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const TimeGap = CurrentDate - date;

        const seconds = Math.floor(TimeGap / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };

    const UserUuid = localStorage.getItem("UserUuid")
    const EmailId = localStorage.getItem("EmailId")
    const VacancyIdForStartAssessment = localStorage.getItem("VacancyIdForStartAssessment")


    const [VacanciesList, setVacanciesList] = useState([])
    const [AppliedDates, setAppliedDates] = useState([])
    const [ResponseStatus, setResponseStatus] = useState("")

    const [OpenVerifyOtpModal, setOpenVerifyOtpModal] = useState(false)
    const [AssessmentOtp, setAssessmentOtp] = useState("")

    const ViewAppliedVacancy = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_applied_vacancies`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setResponseStatus(result.Status)
                setVacanciesList(result.vacancies)
                setAppliedDates(result.apply.map((date) => date.apply_at))
            })
    }

    const HandleViewVacancyPage = (vacancyId, enterpriseId) => {
        localStorage.setItem("VacancyIdForViewVacancyPage", vacancyId);
        localStorage.setItem("EnterpriseIdForViewVacancyPage", enterpriseId);
        navigate("/vacancy_page");
    };

    useEffect(() => {
        ViewAppliedVacancy()
    }, [])

    const HandleSendOtp = (vacancyId) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": EmailId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/send_assessment_otp`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    localStorage.setItem("VacancyIdForStartAssessment", vacancyId);
                    setOpenVerifyOtpModal(true)
                    toast(result.Msg, {
                        position: "top-right",
                        autoClose: 4000
                    })
                } else if (result.Status === "0") {
                    toast(result.Msg, {
                        position: "top-right",
                        autoClose: 4000
                    })
                }
            })
    }

    const HandleSubmitOtp = () => {

        if (!AssessmentOtp) {
            toast("Please enter OTP", {
                position: "top-right",
                autoClose: 4000
            })
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": EmailId,
            "otp": AssessmentOtp
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/verify_assessment_otp`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast(result.Msg, {
                        position: "top-right",
                        autoClose: 4000,
                    })
                    HandleStartAssessment()
                } else if (result.Status === "0") {
                    toast(result.Msg, {
                        position: "top-right",
                        autoClose: 4000
                    })
                }
            })
    }

    const HandleStartAssessment = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid,
            "vacancy_uuid": VacancyIdForStartAssessment
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/interview_assessment`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                navigate('/extempore_round')
            })
    }

    return (
        <>
            <div className='AppliedVacancyPage' style={{ opacity: ResponseStatus ? '1' : '.3', }}  >
                <div className='Profile_left' >
                    <div className="section_1">
                        <h2>Applied <span> Vacancies <WorkHistory /> </span> </h2>
                        <p>  This section displays the vacancies you have applied for. Here, you can view the job details of the applied positions, start your assessment based on your skills and resume, and ensure you understand the job role, responsibilities, qualifications, and other essential criteria. <br /> Please review the information carefully to confirm you meet the necessary requirements and expectations for each position.  </p>
                    </div>

                    {
                        ResponseStatus ? (
                            VacanciesList.length > 0 ?
                                VacanciesList.map((data, index) => {
                                    return <div className="section_2">
                                        <h2>{index + 1}</h2>

                                        <div id='name' >
                                            <h3>{data.job_title} </h3>
                                            <p>Startelelogic Pvt. Ltd.</p>
                                        </div>

                                        <p>
                                            <History id="icon" /> Applied {convertDateFormat(`${AppliedDates[index]}`)}
                                        </p>

                                        <Button
                                            className="Button_1"
                                            variant="contained"
                                            onClick={() =>
                                                HandleViewVacancyPage(
                                                    data.vacancy_uuid,
                                                    data.tenant_uuid
                                                )
                                            }
                                        >
                                            View Job Details
                                        </Button>

                                        {/* <Button
                                            className="Button_2"
                                            variant="outlined"
                                            color="inherit"
                                            onClick={(vacancyId) => HandleSendOtp(data.vacancy_uuid)}
                                        >
                                            Start Assessment
                                        </Button> */}


                                    </div>
                                })
                                : (<div className="section_2">
                                    <h2>S.No. </h2>

                                    <div id='name' >
                                        <h3>Job Title</h3>
                                        <p>Company Name</p>
                                    </div>

                                    <p>
                                        {/* <History id="icon" /> Applied {convertDateFormat(`${AppliedDates[index]}`)} */}
                                        NO APPLIED VACANCY CURRENTLY
                                    </p>

                                    <Button
                                        className="Button_1"
                                        variant="contained"
                                        disabled
                                    >
                                        View Job Details
                                    </Button>

                                    {/* <Button
                                        className="Button_2"
                                        variant="outlined"
                                        color="inherit"
                                        disabled
                                    >
                                        Start Assessment
                                    </Button> */}


                                </div>)
                        ) : (
                            <div
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    left: '0px',
                                    top: '0px',
                                    justifyContent: "center",
                                    alignItems: "center",
                                    animationName: 'none !important'
                                }}
                            >
                                <Bars
                                    height="40"
                                    width="40"
                                    color="#9c88ff"
                                    ariaLabel="bars-loading"
                                    visible={true}
                                />
                            </div>
                        )
                    }
                </div>
            </div >

            {/* Modal for entering the OTP to start assessment */}
            <Modal open={OpenVerifyOtpModal} >
                <Box sx={ModalStyle2} className='AppliedVacancyModal' >
                    <img src={SentImg} style={{ height: "220px", width: "auto" }} />
                    <H2
                        style={{
                            fontSize: "17px",
                            width: "100%",
                            fontWeight: "300",
                            margin: "10px 0px",
                        }}
                    >
                        A Verificatioin Email with OTP has been sent to your <br />
                        <span style={{ color: "#232a58", textDecoration: "underline" }}>
                            {EmailId}
                        </span>
                    </H2>

                    <div
                        className="inputInfoBox "
                        style={{ width: "85%", marginBottom: "20px" }}
                    >
                        <OutlinedInput
                            autoFocus={true}
                            type="text"
                            value={AssessmentOtp}
                            onChange={(e) => setAssessmentOtp(e.target.value)}
                            style={{ width: "100%", height: "42px" }}
                            placeholder="Enter the OTP here."
                        />
                    </div>

                    <div>
                        <Button
                            className="Button_2"
                            variant="outlined"
                            style={{ margin: '0px 10px' }}
                            onClick={HandleSubmitOtp}
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={() => {
                                setOpenVerifyOtpModal(false);
                                setAssessmentOtp("");
                            }}
                            style={{ color: "indianRed" }}
                            variant="outlined"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal >
        </>
    )
}

export default AppliedVacancyPage
