import React, { useEffect, useState } from 'react';
import './Navbar.css';
import StarteleName from '../../Assets/StarteleLogo.png';

import { useNavigate, Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '../../Assets/Avtar.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { Bookmark, BookmarkBorderOutlined, BusinessCenterOutlined, BusinessOutlined, HouseRounded, LocationOnOutlined, WorkHistoryRounded, WorkOutline, WorkspacePremiumOutlined } from '@mui/icons-material';
import { toast } from "react-toastify";
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAuth } from '../../AuthContext';
import { colors } from '@mui/material';



const Navbar = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const location = useLocation();
    const navigate = useNavigate()

    const { logout } = useAuth();

    const FullName = localStorage.getItem("FullName")
    const UserUuid = localStorage.getItem("UserUuid")
    const EmailId = localStorage.getItem("EmailId")
    const Account_Type = localStorage.getItem("Account_Type")

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [ShowSideNav, setShowSideNav] = useState(false);

    const [scroll, setScroll] = useState(false);     // This is for making the navbar sticky.

    const [Toggle, setToggle] = useState(true)
    const ShowToggle = (location.pathname !== '/forgotPassword' && location.pathname !== '/login' && location.pathname !== '/signUp');


    // const ActiveHomePage = (location.pathname == '/homePage');
    // const ActiveProfilePage = (location.pathname == '/profilePage');
    // const ActiveAppliedVacancy = (location.pathname == '/applied_vacancy')

    const isActive = (path) => location.pathname === path;

    const ChangeToggle = () => {
        if (location.pathname == "/login") {
            setToggle(true)
        } else if (location.pathname == "/signUp") {
            setToggle(false)
        }
    }

    useEffect(() => {
        ChangeToggle()
    })

    const handleLogout = () => {
        setShowSideNav(false);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email_id": EmailId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/logout`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "0") {
                    if (result.Msg === "Account Doesn't Exists") {
                        localStorage.clear()
                        window.location.reload();
                        navigate("/login")
                    }

                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });


                } else if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    navigate("/login")
                    localStorage.clear()
                    setInterval(() => {
                        window.history.pushState(null, null, window.location.href);
                        window.onpopstate = function () {
                            window.history.go(1);
                        };
                    }, 500);
                    logout() // call the function from authcontext
                }
            })
            .catch((error) => {
                console.error(error)
            });
    }

    return (
        <>
            <div className={`${scroll ? "navbar stickyNavbar" : "navbar"} `}>
                <Link to={!ShowToggle ? "/login" : "/homePage"} >
                    <img
                        className='navbar-brand' src={StarteleName}
                    />

                </Link>

                <div style={{ display: 'flex' }} >
                    {
                        !ShowToggle ?
                            <div className='Toggle' >
                                <Link to="/login" >
                                    <div className='loginToggle' style={{ backgroundColor: Toggle ? '#6D9EC0' : '', }}
                                        onClick={() => setToggle(true)}
                                    >
                                        <p> Login </p>
                                    </div>
                                </Link>

                                <Link to="/signUp" >
                                    <div className='signupToggle' style={{
                                        backgroundColor: Toggle ? '' : '#6D9EC0',
                                    }}
                                        onClick={() => setToggle(false)}
                                    >
                                        <p> Sign Up</p>
                                    </div>
                                </Link>
                            </div>
                            :
                            <>
                                <div id='navbarMenu'>

                                    {
                                        Account_Type == "Enterprise" &&
                                        <div >
                                            <Link to="/homePage" >Home</Link>
                                            {/* <Link to="/all_vacancies" >Current Vacancies</Link> */}
                                            <Link to="/profilePage" >Company Profile</Link>
                                            <Link to="#" >My Profile</Link>
                                        </div>
                                    }

                                    {
                                        Account_Type == "Job_Seeker" &&
                                        <div >
                                            <Link to="/homePage" style={isActive('/homePage') ? { color: '#6675ca', borderBottom: '2px solid #6675ca', paddingBottom: '5px' } : {}} >Home</Link>
                                            <Link to="/profilePage" style={isActive('/profilePage') ? { color: '#6675ca', borderBottom: '2px solid #6675ca', paddingBottom: '5px' } : {}}>My Profile</Link>
                                            <Link to="/applied_vacancy" style={isActive('/applied_vacancy') ? { color: '#6675ca',  borderBottom: '2px solid #6675ca', paddingBottom: '5px' } : {}} >Applied Jobs</Link>
                                        </div>
                                    }

                                    <div className='ProfileInfo' >
                                        <>
                                            <div className='Avatar' style={{ textTransform: 'uppercase' }}>
                                                {/* <img src={Avatar} /> */}

                                                {FullName && FullName.split(' ').map(name => name.charAt(0)).join('')}

                                            </div>
                                            <p id='name' >{FullName} </p>
                                        </>

                                        <div className='UserOptionsMenu' >
                                            <ul>
                                                <li onClick={() => navigate('/profilePage')}>View & Edit Profile</li>
                                                {/* <li>Applied Jobs</li> */}
                                                {/* <li>Settings</li> */}
                                                <li onClick={handleLogout}>Logout <LogoutIcon style={{
                                                    fontSize: '18px',
                                                    color: '#9D4EDD'
                                                }} /> </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <MenuIcon id='SideNavButton' onClick={() => setShowSideNav(!ShowSideNav)} />
                            </>
                    }
                </div>

                <div className="sidenav" style={{ width: ShowSideNav ? '350px' : '0vw', }} >
                    <div className="sidenavbox-1">
                        <div className='Avatar' >
                            <img src={Avatar} alt="User Avatar" />
                        </div>
                        <p id="name">{FullName}</p>
                        <a href="javascript:void(0)" className="closebtn" onClick={() => setShowSideNav(!ShowSideNav)} >&times;</a>
                    </div>

                    <div className='sidenavbox-2'>
                        <ul>
                            <li>
                                <HouseRounded id="icon" /><Link to="/homePage" onClick={() => setShowSideNav(!ShowSideNav)} >Home</Link>
                            </li>
                            <hr id='borderLine' />
                            <li><PersonIcon id="icon" /> <Link to="/profilePage" onClick={() => setShowSideNav(!ShowSideNav)} >My Profile</Link></li>
                            <hr id='borderLine' />
                            <li><SettingsIcon id="icon" /> <Link to="/profilePage" onClick={() => setShowSideNav(!ShowSideNav)} >Setting</Link></li>
                            <hr id='borderLine' />
                            <li> <LogoutIcon id='icon' /><Link onClick={handleLogout} >Logout</Link></li>
                        </ul>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Navbar
