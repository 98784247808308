import React, { useEffect, useState } from 'react';
import './AllVacancies.css'
import { Box, Button, Chip, Modal, OutlinedInput, Tooltip } from '@mui/material';
import { Bookmark, BookmarkBorderOutlined, BusinessCenterOutlined, BusinessOutlined, HouseRounded, LocationOnOutlined, WorkHistoryRounded, WorkHistory, WorkOutline, WorkspacePremiumOutlined, Create, Delete, History } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: 'none',
};

const AllVacancies = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const CurrentDate = new Date()

    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const TimeGap = CurrentDate - date;

        const seconds = Math.floor(TimeGap / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };

    const getTruncatedText = (text) => {
        const words = text.split(' ');
        const wordLimit = 20;
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return text;
    };

    // API Reponse status states to manage loading
    const [AllDataResponse, setAllDataResponse] = useState("");

    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");  //This is the tenant uuid
    const [AllVacanciesArray, setAllVacanciesArray] = useState([])
    const [CompanyName, setCompanyName] = useState("")
    const [OpenEditVacancyModal, setOpenEditVacancyModal] = useState(false)

    // States for Showing and editing the vacancy details
    const [JobTitle, setJobTitle] = useState("")
    const [JobExperience, setJobExperience] = useState("")
    const [JobSalary, setJobSalary] = useState("")
    const [JobLocation, setJobLocation] = useState("")
    const [JobOpening, setJobOpening] = useState("")
    const [JobRole, setJobRole] = useState("")
    const [JobDescription, setJobDescription] = useState("")
    const [JobResponsibilities, setJobResponsibilities] = useState("")
    const [JobQualification, setJobQualification] = useState("")
    const [JobSkills, setJobSkills] = useState('')

    const [SkillsArray, setSkillsArray] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && JobSkills.trim() !== '') {
            setSkillsArray([...SkillsArray, JobSkills.trim()]);
            setJobSkills('');
        }
    };

    const HandleDeleteSkill = (chipToDelete) => () => {
        setSkillsArray((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const ShowAllVacancies = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    setAllDataResponse(result.Status);
                    setAllVacanciesArray(result.vacancies)
                    setCompanyName(result.enterprises[0].company_name)
                }
                else if (result.Status === "0") {
                    setAllVacanciesArray([])
                }
            })
    }

    const HandleDeleteVacancy = (vacancy_uuid) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "vacancy_uuid": vacancy_uuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/delete_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    ShowAllVacancies()
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    useEffect(() => {
        ShowAllVacancies()
    }, [])

    const HandleViewVacancyPage = (vacancyId, enterpriseId) => {
        localStorage.setItem("VacancyIdForViewVacancyPage", vacancyId)
        localStorage.setItem("EnterpriseIdForViewVacancyPage", enterpriseId)
        navigate('/vacancy_page')
    }

    const handleEditVacancyPage = (vacancy_uuid) => {
        localStorage.setItem("EditVacancyUUID", vacancy_uuid);
        navigate('/edit_vacancy')
    }


    return (
        <>

            <div style={{ opacity: AllDataResponse === "1" ? "1" : "0.3" }} className='AllVacancies' >
                <div className="section_1">
                    <div className="box_1">
                        <h2>List of All <span> Vacancies <WorkHistory /> </span> </h2>
                        <p>Below is a comprehensive list of all the vacancies you have created. This section allows you to review, edit, and manage your current job openings efficiently. By clicking on each vacancy, you can see detailed information and make any necessary updates. To expand your opportunities and attract more candidates, simply click on the "Create Vacancy" button to add new job openings. </p>
                    </div>
                    <div className="box_2">
                        <Button variant="contained"
                            onClick={() => navigate('/create_vacancy')}
                            style={{
                                fontSize: '18px',
                                color: 'white',
                                border: "1px solid #4B64FF",
                                backgroundColor: '#4B64FF',
                                border: '0px ',
                            }}
                        >
                            Create Vacancy
                        </Button>
                    </div>
                </div>
                {AllDataResponse === "1" &&
                    <div className="section_2">
                        {AllVacanciesArray.length > 0 ?
                            (AllVacanciesArray.map((vacancy, index) => {
                                return <div className="card" key={index} >
                                    <h3> {vacancy.job_title ? vacancy.job_title : "No Title"} </h3>
                                    <h1> {vacancy.job_role ? vacancy.job_role : "No Role Name"} </h1>
                                    <p><WorkOutline id="icon" />{CompanyName} </p>
                                    <p><BusinessCenterOutlined id="icon" />
                                        {vacancy.job_experience ? vacancy.job_experience : 'Add experience'}
                                    </p>
                                    <p><LocationOnOutlined id="icon" />
                                        {vacancy.job_location ? vacancy.job_location : "Add location"}
                                    </p>
                                    <p style={{ color: 'grey', fontSize: 'small' }} ><History id="icon" />
                                        Posted {convertDateFormat(vacancy.created_at)}
                                    </p>
                                    <hr />
                                    <h2>
                                        {vacancy.job_description ? getTruncatedText(vacancy.job_description) : "Add job description ...."}
                                    </h2>

                                    <div id='cardButtons' >
                                        <Button variant="contained"
                                            onClick={(vacancyId) => {
                                                HandleViewVacancyPage(vacancy.vacancy_uuid, vacancy.tenant_uuid)
                                            }}
                                            style={{
                                                fontSize: '13px',
                                                color: 'white',
                                                border: "1px solid #011015",
                                                backgroundColor: `#8ecae6`,
                                                border: '0px ',
                                            }}
                                        >
                                            Read More
                                        </Button>

                                        <div>
                                            <Button variant="contained"
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    border: "0px",
                                                    backgroundColor: `#8ECAE6`,
                                                    margin: '0px 5px'
                                                }}
                                                onClick={() => handleEditVacancyPage(vacancy.vacancy_uuid)}
                                            >
                                                <Create />
                                            </Button>

                                            <Button variant="outlined"
                                                onClick={(vacancy_uuid) => HandleDeleteVacancy(vacancy.vacancy_uuid)}
                                                style={{
                                                    fontSize: '14px',
                                                    color: 'indianred',
                                                    margin: '0px 5px'
                                                }}
                                                color='inherit'
                                            >
                                                <Delete />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            })
                            )
                            :
                            (
                                <div className="card">
                                    <h3> Job Title </h3>
                                    <h1> Role Name </h1>
                                    <p><WorkOutline id="icon" /> Company Name</p>
                                    <p><BusinessCenterOutlined id="icon" /> Experience</p>
                                    <p><LocationOnOutlined id="icon" />  Location</p>
                                    <hr />
                                    <h2>
                                        Curently there are no vacancies , simply click on the "Create Vacancy" button to add new job openings...
                                    </h2>

                                    <div id='cardButtons' >
                                        <Tooltip title="View detailed vaccancy" placement='right'>
                                            <Button variant="contained"
                                                onClick={() => navigate('/create_vacancy')}
                                                style={{
                                                    fontSize: '13px',
                                                    color: 'white',
                                                    border: "1px solid #011015",
                                                    backgroundColor: `#8ecae6`,
                                                    border: '0px ',
                                                }}
                                            >
                                                Create Vacancy
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                }

            </div >

            {AllDataResponse !== "1" && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "95vw",
                        position: "fixed",
                        top: "10%",
                        right: "0%",
                        left: "10%",
                    }}
                >
                    <Bars
                        height="35"
                        width="35"
                        color="#9c88ff"
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>
            )}

            {/* This modal is for editing the vacancy */}
            <Modal
                open={OpenEditVacancyModal}
            >
                <Box sx={ModalStyle2} className='EditVacancyModal' >
                    <>
                        <h2>Edit vacancy </h2>

                        <p style={{ color: 'grey' }} >Edit the essential details that will help job seekers connect with you and understand more about this job vacancy.</p>

                        <div id='Type1' >
                            <h3>Job Title </h3>
                            <p>Please enter the required job title here eg : Web Developer</p>
                            <input
                                type="text"
                                placeholder='Enter job title here'
                                value={JobTitle}
                                onChange={(e) => setJobTitle(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Exprience Required </h3>
                            <p>Please specify the required experience for eg : Freshers , 1-2 Years , 3+ Years</p>
                            <input
                                type="text"
                                placeholder='Enter experience here'
                                value={JobExperience}
                                onChange={(e) => setJobExperience(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Offered Salary</h3>
                            <p>Please specify the offered salary for eg : 4-5 LPA</p>
                            <input
                                type="text"
                                placeholder='Enter salary amount here'
                                value={JobSalary}
                                onChange={(e) => setJobSalary(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Job Location</h3>
                            <p>Please specify the required Job location for eg : Delhi, Banglore</p>
                            <input
                                type="text"
                                placeholder='Enter location here'
                                value={JobLocation}
                                onChange={(e) => setJobLocation(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Total Openings</h3>
                            <p>Please specify the total openings for this role for eg: 3, 4</p>
                            <input
                                type="number"
                                placeholder='Enter number of openings here'
                                value={JobOpening}
                                onChange={(e) => setJobOpening(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Role Here</h3>
                            <p>Please specify the particular role for this vacancy eg: Front End Developer</p>
                            <input
                                type="text"
                                placeholder='Enter the role name here'
                                value={JobRole}
                                onChange={(e) => setJobRole(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Job Description </h3>
                            <p>Please provide a detailed job description for this vacancy.</p>
                            <OutlinedInput
                                multiline
                                rows={5}
                                type="text"
                                value={JobDescription}
                                onChange={(e) => setJobDescription(e.target.value)}
                                style={{ width: "100%" }}
                                placeholder='Enter decsription here'
                            />
                        </div>

                        <div id='Type1'>
                            <h3>Job Responsibilities</h3>
                            <p>Please list down the responsibilities involved in this job vacancy for eg:
                                <br />
                                1. Design integrated UI / UX designs using HTML , CSS , Javascript.
                                <br />
                                2. Developing front-end by liaising with the design team
                            </p>
                            <OutlinedInput
                                multiline
                                rows={5}
                                type="text"
                                value={JobResponsibilities}
                                onChange={(e) => setJobResponsibilities(e.target.value)}
                                style={{ width: "100%" }}
                                placeholder='Enter decsription here'
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Qualification Required</h3>
                            <p>Please specify the qualification required for this vacancy for eg:B.Tech / MCA / etc. </p>
                            <input
                                type="text"
                                placeholder='Enter qualification here'
                                value={JobQualification}
                                onChange={(e) => setJobQualification(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Job Skills</h3>
                            <p>Please specify the skills to be required in this vacancy. </p>
                            <input
                                type="text"
                                placeholder='Enter the skill and press enter.'
                                value={JobSkills}
                                onChange={(e) => setJobSkills(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <div id='SkillsBox' >
                                {SkillsArray.map((data, index) => (
                                    <Chip
                                        key={index}
                                        label={data}
                                        onDelete={HandleDeleteSkill(data)}
                                    />
                                ))}
                            </div>
                        </div>

                        <div>
                            <Button
                                // onClick={HandleEditVacancy}
                                className='Button_1'
                                variant="contained"
                                style={{ marginRight: '12px' }}
                            >
                                Update
                            </Button>

                            <Button
                                onClick={() => setOpenEditVacancyModal(false)}
                                className='Button_2'
                                variant="outlined"
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                </Box>
            </Modal >
        </>
    )
}

export default AllVacancies
