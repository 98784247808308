import React, { useState } from 'react';
import './EnterpriseProfilePage.css';

import Logo from '../../Assets/StarteleLogo.png';
import CoverPhoto from '../../Assets/EnterpriseCoverPhoto.jpg';

import { H1, H2 } from '../Typography';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Country, State, City } from 'country-state-city';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Add, AddRounded, BusinessRounded, Call, ContactMailRounded, CreateRounded, Delete, DeleteOutline, Edit, Email, GpsFixed, Phone } from '@mui/icons-material';
import { Box, Button, FormControl, MenuItem, Modal, OutlinedInput, Select, useMediaQuery } from '@mui/material';
import { useFetcher, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: 'none',
};

const EnterpriseProfilePage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const isSmallScreen = useMediaQuery('(max-width:800px)');
    const CheckProfileId = localStorage.getItem("CheckProfileId");
    const CheckCompanyProfileID = localStorage.getItem("CheckCompanyProfileID");
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");


    const CurrentDate = new Date()
    const generateYearsArray = (startYear, endYear) => {
        const years = [];
        for (let year = endYear; year >= startYear; year--) {
            years.push(year);
        }
        return years;
    };
    const currentYear = new Date().getFullYear();
    const yearsArray = generateYearsArray(1950, currentYear);
    const MonthsArray = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    const [OpenEditEnterpriseModal, setOpenEditEnterpriseModal] = useState(false)

    useEffect(() => {
        if (CheckCompanyProfileID == "true") {
            setOpenEditEnterpriseModal(false);
        } else {
            setOpenEditEnterpriseModal(true);
        }
    }, []);

    const [EnterpriseName, setEnterpriseName] = useState("")
    const [EnterpriseTagLine, setEnterpriseTagLine] = useState("")
    const [EnterpriseEstablishmentYear, setEnterpriseEstablishmentYear] = useState("")
    const [EnterpriseCinNumber, setEnterpriseCinNumber] = useState("")
    const [EnterpriseAddress, setEnterpriseAddress] = useState("")
    const [EnterprisePincode, setEnterprisePincode] = useState("")
    const [EnterpriseCity, setEnterpriseCity] = useState("")
    const [EnterpriseState, setEnterpriseState] = useState("")
    const [EnterpriseCountry, setEnterpriseCountry] = useState("")
    const [EnterpriseAboutUs, setEnterpriseAboutUs] = useState("")
    const [EnterpriseType, setEnterpriseType] = useState("")
    const [EnterpriseWebsiteLink, setEnterpriseWebsiteLink] = useState("")
    const [EnterpriseMobileNumbers, setEnterpriseMobileNumbers] = useState([])
    const [EnterpriseEmailId, setEnterpriseEmailId] = useState("")

    // country state city code start here 
    const [AllStates, setAllStates] = useState([]);
    const [statesMap, setStatesMap] = useState({});
    const [AllCities, setAllCities] = useState([]);

    const countries = Country.getAllCountries();

    const countryMap = countries.reduce((acc, country) => {
        acc[country.name] = country.isoCode;
        return acc;
    }, {});

    const updatedCountries = countries.map((country) => ({
        label: country.name,
        value: country.name,
        isoCode: country.isoCode,
        ...country
    }));

    const getStates = (countryIsoCode) => {
        const stateList = State.getStatesOfCountry(countryIsoCode);

        const statesMap = stateList.reduce((acc, state) => {
            acc[state.name] = state.isoCode;
            return acc;
        }, {});

        setStatesMap(statesMap);

        const mappedStates = stateList.map((state) => ({
            label: state.name,
            value: state.name,
            isoCode: state.isoCode,
            ...state
        }));
        setAllStates(mappedStates);

        return mappedStates;
    };

    // Updating the selected state value 
    useEffect(() => {
        if (EnterpriseCountry) {
            const countryIsoCode = countryMap[EnterpriseCountry];
            getStates(countryIsoCode);
        }
    }, [EnterpriseCountry]);

    // Geeting the all cities name based on selected state
    const getCities = (countryIsoCode, stateIsoCode) => {
        const cityList = City.getCitiesOfState(countryIsoCode, stateIsoCode);

        const mappedCities = cityList.map((city) => ({
            label: city.name,
            value: city.name,
            ...city
        }));
        setAllCities(mappedCities);

        return mappedCities;
    };

    // Updating the cities name in the city selectbox  
    useEffect(() => {
        if (EnterpriseState) {
            const countryIsoCode = countryMap[EnterpriseCountry];
            const stateIsoCode = statesMap[EnterpriseState];
            getCities(countryIsoCode, stateIsoCode);
        }
    }, [EnterpriseState, statesMap]);

    const ViewEnterpriseDetails = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setEnterpriseName(result.enterprises[0].company_name)
                setEnterpriseTagLine(result.enterprises[0].company_tag_line)
                setEnterpriseEstablishmentYear(result.enterprises[0].year_of_establishment)
                setEnterpriseCinNumber(result.enterprises[0].cin_number)
                setEnterpriseAddress(result.enterprises[0].company_address)
                setEnterprisePincode(result.enterprises[0].company_pincode)
                setEnterpriseCity(result.enterprises[0].company_city)
                setEnterpriseState(result.enterprises[0].company_state)
                setEnterpriseCountry(result.enterprises[0].company_country)
                setEnterpriseAboutUs(result.enterprises[0].company_about)
                setEnterpriseType(result.enterprises[0].company_type)
                setEnterpriseWebsiteLink(result.enterprises[0].company_website)
                // setEnterpriseMobileNumbers(result.enterprises[0].company_contact_number)
                setEnterpriseEmailId(result.enterprises[0].company_email_id)
            })
    }

    useEffect(() => {
        if (CheckCompanyProfileID == "true") {
            ViewEnterpriseDetails()
        }
    }, [OpenEditEnterpriseModal]);

    const HandleCreateEnterprise = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "company_name": EnterpriseName,
            "company_tag_line": EnterpriseTagLine,
            "year_of_establishment": EnterpriseEstablishmentYear.toString(),
            "cin_number": EnterpriseCinNumber,
            "company_country": EnterpriseCountry,
            "company_state": EnterpriseState,
            "company_city": EnterpriseCity,
            "company_pincode": EnterprisePincode,
            "company_address": EnterpriseAddress,
            "company_about": EnterpriseAboutUs,
            "company_website": EnterpriseWebsiteLink,
            "company_email_id": EnterpriseEmailId,
            "company_contact_number": EnterpriseMobileNumbers,
            "company_type": EnterpriseType,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/create_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    localStorage.setItem(
                        "CheckCompanyProfileID", "company_profile_uuid" in result.enterprises[0]
                    );
                    setOpenEditEnterpriseModal(false);
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })

    }

    const HandleEditEnterprise = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "company_name": EnterpriseName,
            "company_tag_line": EnterpriseTagLine,
            "year_of_establishment": EnterpriseEstablishmentYear.toString(),
            "cin_number": EnterpriseCinNumber,
            "company_country": EnterpriseCountry,
            "company_state": EnterpriseState,
            "company_city": EnterpriseCity,
            "company_pincode": EnterprisePincode,
            "company_address": EnterpriseAddress,
            "company_about": EnterpriseAboutUs,
            "company_website": EnterpriseWebsiteLink,
            "company_email_id": EnterpriseEmailId,
            "company_contact_number": EnterpriseMobileNumbers,
            "company_type": EnterpriseType,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/edit_enterprise`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    setOpenEditEnterpriseModal(false);
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    const HandleCancelButton = () => {
        if (CheckCompanyProfileID == "true") {
            setOpenEditEnterpriseModal(false);
        } else if (CheckCompanyProfileID == "false") {
            navigate("/homePage");
            setOpenEditEnterpriseModal(false);
        }
    }

    return (
        <>
            <div className='EnterpriseProfilePage' >
                <div className="section_1">
                    <div className="part1"
                        style={{ backgroundImage: `url(${CoverPhoto})` }}
                    >
                        <div className='one_icons' >
                            <Delete id="icon" />
                            <Edit id="icon" />
                        </div>
                    </div>

                    <div className="part2">
                        <div className="imageBox">
                            <img src={Logo} alt="" />
                        </div>
                    </div>

                    <div className="part3">
                        <div id="companyName">
                            <h2>
                                {EnterpriseName === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Company Name
                                </span> : EnterpriseName}
                            </h2>
                            <p>{EnterpriseTagLine === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Company Tag Line
                            </span> : EnterpriseTagLine}</p>
                        </div>

                        <div id="companyAddress">
                            <p><Phone id="icon" />{
                                EnterpriseMobileNumbers.length == 0 ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Number
                                </span> : EnterpriseMobileNumbers.map((number) => {
                                    return number
                                })}
                            </p>

                            <a href="mailto:info@startelelogic.com"> <Email id="icon" />{EnterpriseEmailId === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Email Id
                            </span> : EnterpriseEmailId}</a>

                            <p> <GpsFixed id="icon" />
                                {EnterpriseAddress === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Address
                                </span> : EnterpriseAddress},

                                {EnterpriseCity === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    City
                                </span> : EnterpriseCity},

                                {EnterpriseState === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    State
                                </span> : EnterpriseState}

                                ({EnterprisePincode === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Pincode
                                </span> : EnterprisePincode})
                            </p>
                        </div>
                    </div>
                </div>

                <div className="section_2">
                    <div className='part1' >
                        <h3>Company Details <CreateRounded id="icon" onClick={() => setOpenEditEnterpriseModal(true)} />  </h3>
                        <p>Welcome to your company information page. Below you will find comprehensive details about your company, including the official name, a brief overview of our history and about and your complete contact information, including your physical address, phone number, and email address, to ensure peoples can reach you easily for any inquiries or further information. If not updated or provided , kindly update the information by clicking the edit button.
                        </p>
                    </div>

                    <div className='part2'>
                        <ul>
                            <li> <h2>Company Name </h2>  <p> {EnterpriseName === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Company Name
                            </span> : EnterpriseName} </p></li>

                            <li> <h2>Company's Tag Line </h2> <p> {EnterpriseTagLine === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Tag Line
                            </span> : EnterpriseTagLine} </p> </li>

                            <li> <h2>Year Of Establishment </h2> <p> {EnterpriseEstablishmentYear === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Establisment Year
                            </span> : EnterpriseEstablishmentYear} </p> </li>

                            {/* <li> <h2>CIN Number </h2> <p> {EnterpriseCinNumber === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add CIN Number
                            </span> : EnterpriseCinNumber} </p> </li> */}

                            <div className='part2_1'>
                                <div className='addressList' >
                                    <li> <h2>Company's Address </h2><p> {EnterpriseAddress === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add Address
                                    </span> : EnterpriseAddress}</p> </li>

                                    <li> <h2>City & Pin code </h2><p>  {EnterpriseCity === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add City
                                    </span> : EnterpriseCity}
                                        , ( {EnterprisePincode === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                            PinCode
                                        </span> : EnterprisePincode} ) </p> </li>

                                    <li> <h2>State & Country </h2><p>  {EnterpriseState === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add State
                                    </span> : EnterpriseState}
                                        , ( {EnterpriseCountry === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                            Country
                                        </span> : EnterpriseCountry} ) </p> </li>
                                </div>

                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2567.94728017178!2d77.32928067387674!3d28.60006928555702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4503fffffff%3A0x5ad8f15161798a45!2sstartelelogic!5e1!3m2!1sen!2suk!4v1720507222477!5m2!1sen!2suk" id='GoogleMap' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <li><h2>About Us </h2><p> {EnterpriseAboutUs === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add About Us
                            </span> : EnterpriseAboutUs} </p></li>

                            <li><h2>CIN Number  </h2> <p> {EnterpriseCinNumber === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add CIN Number
                            </span> : EnterpriseCinNumber} </p> </li>

                            <li><h2>Company Type  </h2> <p>{EnterpriseType === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Company Type
                            </span> : EnterpriseType} </p></li>

                            <li><h2>Company's Website </h2>
                                {
                                    EnterpriseWebsiteLink === "" ? <p><span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                        Add Website's Link
                                    </span> </p> :
                                        <a href={EnterpriseWebsiteLink} target='_blank' >Click to view the website</a>
                                }
                            </li>

                            <li><h2>Contact Numbers  </h2> <p>{
                                EnterpriseMobileNumbers.length == 0 ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                    Add Number
                                </span> : EnterpriseMobileNumbers.map((number) => {
                                    return number
                                })} </p>
                            </li>

                            <li><h2>Contact Email Id </h2> <p>{EnterpriseEmailId === "" ? <span id="AddButton" onClick={() => setOpenEditEnterpriseModal(true)}>
                                Add Email Id
                            </span> : EnterpriseEmailId}</p></li>
                        </ul>
                    </div>
                </div>
            </div >

            {/* MODALS START FROM HERE  */}
            {/* Modal for opening and editing enterprise details  */}
            <Modal
                open={OpenEditEnterpriseModal}
            >
                <Box sx={ModalStyle2} className='EnterpriseProfilePageModals' >
                    <>
                        <h2>
                            {
                                CheckCompanyProfileID == "true" ? "Edit Enterprise Details" : "Add Enterprise Details"
                            }
                        </h2>

                        <p style={{ color: 'grey' }} > Here are the essential details that will enable the job seekers to connect with you and understand more about you.</p>

                        <div id='Type1' >
                            <h3>Company Name </h3>
                            <p>Please enter the full name of your company here</p>
                            <input
                                type="text"
                                placeholder='Enter name here'
                                value={EnterpriseName}
                                onChange={(e) => setEnterpriseName(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Company's Tag Line </h3>
                            <p>Please enter a suitable tagline for the company</p>
                            <input
                                type="text"
                                placeholder='Enter tag-line here'
                                value={EnterpriseTagLine}
                                onChange={(e) => setEnterpriseTagLine(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Year of establishment </h3>
                            <p>Kindly select the year of establishment of your company</p>
                            <FormControl style={{ width: "100%" }}>
                                <Select
                                    inputProps={{ tabIndex: "5" }}
                                    value={EnterpriseEstablishmentYear ? EnterpriseEstablishmentYear : currentYear}
                                    onChange={(e) => setEnterpriseEstablishmentYear(e.target.value)}
                                    style={{ width: "100%", height: "42px", color: '#666666' }}
                                >
                                    {
                                        yearsArray.map((year, index) => {
                                            return <MenuItem key={index} value={year} > {year} </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        <div id='Type1' >
                            <h3>Company's CIN Number </h3>
                            <p>Please enter the CIN Number of the company</p>
                            <input
                                type="text"
                                placeholder='Enter CIN here'
                                value={EnterpriseCinNumber}
                                onChange={(e) => setEnterpriseCinNumber(e.target.value)}
                            />
                        </div>

                        <div id='Type1'>
                            <h3>Address Details </h3>
                            <p>These are the address details of your compamy</p>
                            <br />
                            <>
                                <p>Please Select your country</p>
                                <FormControl style={{ width: "100%" }}>
                                    <Select
                                        inputProps={{ tabIndex: "5" }}
                                        value={EnterpriseCountry ? EnterpriseCountry : "0"}
                                        onChange={(e) => setEnterpriseCountry(e.target.value)}
                                        style={{ width: "100%", height: "42px", color: '#666666' }}
                                    >
                                        <MenuItem value="0" disabled>--Select Your Country--</MenuItem>
                                        {updatedCountries.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem value="Other"> Other </MenuItem> */}
                                    </Select>
                                </FormControl>
                            </>
                            <br />
                            <>
                                <p>Please Select your State</p>
                                <FormControl style={{ width: "100%" }}>
                                    <Select
                                        inputProps={{ tabIndex: "5" }}
                                        value={EnterpriseState ? EnterpriseState : "0"}
                                        onChange={(e) => setEnterpriseState(e.target.value)}
                                        style={{ width: "100%", height: "42px", color: '#666666' }}
                                    >
                                        <MenuItem value="0" disabled>--Select Your State--</MenuItem>
                                        {AllStates.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem value="Other"> Other </MenuItem> */}
                                    </Select>
                                </FormControl>
                            </>
                            <br />
                            <>
                                <p>Please Select your City</p>
                                <FormControl style={{ width: "100%" }}>
                                    <Select
                                        inputProps={{ tabIndex: "5" }}
                                        value={EnterpriseCity ? EnterpriseCity : "0"}
                                        onChange={(e) => setEnterpriseCity(e.target.value)}
                                        style={{ width: "100%", height: "42px", color: '#666666' }}
                                    >
                                        <MenuItem value="0" disabled>--Select Your City--</MenuItem>
                                        {AllCities.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* <MenuItem value="Other"> Other </MenuItem> */}
                                    </Select>
                                </FormControl>
                            </>
                            <br />
                            <>
                                <p>Company's Address</p>
                                <input
                                    type="text"
                                    placeholder='Enter the address here'
                                    value={EnterpriseAddress}
                                    onChange={(e) => setEnterpriseAddress(e.target.value)}
                                />
                            </>
                            <br />
                            <>
                                <p>Pincode</p>
                                <input
                                    type="text"
                                    placeholder='Enter the pincode eg : 121009'
                                    value={EnterprisePincode}
                                    onChange={(e) => setEnterprisePincode(e.target.value)}
                                />
                            </>
                        </div>

                        <div id='Type1' >
                            <h3>About Us </h3>
                            <p>Please provide a brief description of the company</p>
                            <OutlinedInput
                                multiline
                                rows={5}  // Adjust the number of rows as needed
                                type="text"
                                value={EnterpriseAboutUs}
                                onChange={(e) => setEnterpriseAboutUs(e.target.value)}
                                style={{ width: "100%" }}
                                placeholder='Enter about us here'
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Company Type </h3>
                            <p>Please provide the company type for eg: IT Firm </p>
                            <input
                                type="text"
                                placeholder='Enter here for eg : IT Firm'
                                value={EnterpriseType}
                                onChange={(e) => setEnterpriseType(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Website Link </h3>
                            <p>Please provide the link of the company's website</p>
                            <input
                                type="text"
                                placeholder='Enter the link here'
                                value={EnterpriseWebsiteLink}
                                onChange={(e) => setEnterpriseWebsiteLink(e.target.value)}
                            />
                        </div>

                        {/* <div id='Type1'>
                            <h3>E Mail Address</h3>
                            <h2 style={{ display: 'flex', padding: '0px', alignItems: 'center' }} >
                                <ContactMailRounded style={{ color: '#232a58', marginRight: '10px' }} />
                                mohit.chauhan2275@gmail.com
                            </h2>

                            <Accordion style={{ boxShadow: '0px 0px 0px white' }} >
                                <AccordionSummary
                                    id="panel1-header"
                                >
                                    <p>You will be contacted via this email address
                                        <span>Change Email Id.</span></p>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Enter Email ID to get OTP </p>
                                            <input
                                                type="number"
                                                placeholder='Enter OTP'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Get OTP
                                        </Button>
                                    </div>

                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Verify OTP recieved</p>
                                            <input
                                                type="number"
                                                placeholder='Enter Mobile Number'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div> */}

                        <div id='Type1' >
                            <h3>E Mail Id</h3>
                            <p>Please provide the official email ID of the company for contact purposes</p>
                            <input
                                type="text"
                                placeholder='Enter the link here'
                                value={EnterpriseEmailId}
                                onChange={(e) => setEnterpriseEmailId(e.target.value)}
                            />
                        </div>

                        <div id='Type1' >
                            <h3>Mobile Number </h3>

                            <p>You will be contacted via the given mobile numbers. </p>

                            <input
                                type="text"
                                placeholder='Enter the link here'
                                value={EnterpriseMobileNumbers}
                                onChange={(e) => setEnterpriseMobileNumbers(e.target.value)}
                            />

                            <h2 style={{ display: 'flex', padding: '0px', alignItems: 'center' }} >
                                <Call style={{ color: '#232a58', marginRight: '10px' }} />
                                82955-52737
                                <DeleteOutline style={{ color: 'indianRed', marginLeft: "13px" }} />
                            </h2>

                            {/* <Accordion style={{ boxShadow: '0px 0px 0px white' }} >
                                <AccordionSummary id="panel1-header" >
                                    <p> <span> Add New Number</span> </p>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Enter number to get OTP </p>
                                            <input
                                                type="number"
                                                placeholder='Enter Mobile Number'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Get OTP
                                        </Button>
                                    </div>

                                    <div id='Type1' style={{
                                        flexDirection: isSmallScreen ? 'column' : 'row',
                                        alignItems: isSmallScreen ? 'flex-start' : 'flex-end'
                                    }} >
                                        <div>
                                            <p>Verify OTP recieved </p>
                                            <input
                                                type="number"
                                                placeholder='Enter OTP'
                                                style={{ width: '400px' }}
                                            />
                                        </div>

                                        <Button
                                            className='Button_2'
                                            variant="outlined"
                                            color="inherit"
                                            style={{ margin: isSmallScreen ? '10px 0px' : '0px 10px' }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </AccordionDetails>
                            </Accordion> */}
                        </div>

                        <div>
                            {
                                CheckCompanyProfileID == "true" &&
                                <Button
                                    onClick={HandleEditEnterprise}
                                    className='Button_1'
                                    variant="contained"
                                    style={{ marginRight: '12px' }}
                                >
                                    Update
                                </Button>
                            }

                            {
                                CheckCompanyProfileID == "false" &&
                                <Button
                                    onClick={HandleCreateEnterprise}
                                    className='Button_1'
                                    variant="contained"
                                    style={{ marginRight: '12px' }}
                                >
                                    Create
                                </Button>
                            }

                            <Button
                                onClick={HandleCancelButton}
                                className='Button_2'
                                variant="outlined"
                                color="inherit"
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                </Box>
            </Modal >
        </>
    )
}

export default EnterpriseProfilePage
