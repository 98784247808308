import React, { useEffect, useRef, useState } from 'react';
import './CodingRound.css';
import { Bars, DNA } from "react-loader-spinner";
import { Box, Button, Modal, OutlinedInput } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import StarteleName from "../../../Assets/StarteleLogo.png";

import { Done, Padding, Stream, WarningAmber } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";

import CodeEditorScreen from './CodeEditorScreen';
import { toast } from 'react-toastify';

// These are for the code Editor screen

const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: "none",
};

const CodingRound = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"
    const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen status
    const assessmentStartedRef = useRef(false);

    const [TimerAtBottom, setTimerAtBottom] = useState(false);  // To change the position of the timer for easy view.

    useEffect(() => {                  // This is for moving the timer top and bottom on scrolling
        const handleScroll = () => {
            const scrolled = window.scrollY;
            if (scrolled > 50 && !TimerAtBottom) {
                setTimerAtBottom(true);
            } else if (scrolled <= 50 && TimerAtBottom) {
                setTimerAtBottom(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [TimerAtBottom]);

    const navigate = useNavigate();

    const handleFullscreen = () => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }

        setIsFullscreen(true); // Set to true when entering fullscreen
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        setIsFullscreen(false); // Reset to false when exiting fullscreen
        setShowMarksModal(true); // Show the marks modal when exiting fullscreen intentionally;
    }

    const UserUuid = localStorage.getItem("UserUuid");
    const VacancyIdForStartAssessment = localStorage.getItem("VacancyIdForStartAssessment");
    const [InstructionsModal, setInstructionsModal] = useState(true);
    const [ShowMarksModal, setShowMarksModal] = useState(false);
    const [ShowCheatingMarksModal, setShowCheatingMarksModal] = useState(false);

    const [StartTimer, setStartTimer] = useState(false);
    const [TimeLimit, setTimeLimit] = useState("");

    const [questionText, setQuestionText] = useState("");
    const [roundType, setRoundType] = useState("coding");
    const [difficultyLevel, setDifficultyLevel] = useState("");
    const [roundNo, setRoundNo] = useState("4/4");
    const [roundId, setRoundId] = useState("");
    const [questionId, setQuestionId] = useState("");
    const [MarksObtained, setMarksObtained] = useState("")
    const [MarksObtainedLoader, setMarksObtainedLoader] = useState(false)

    const [AnswerText, setAnswerText] = useState("")

    const handleAnswerTextFrom = (data) => {
        setAnswerText(data)
    };

    //This function is for the  initilizing the assessment
    const InitializeAssessment = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            vacancy_uuid: VacancyIdForStartAssessment,
            round_no: roundNo,
            round_type: roundType
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/interview_assessment`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // setRoundType(result.interview_detail[0].round_type);
                setDifficultyLevel(result.interview_detail[0].difficulty_level);
                setTimeLimit(result.interview_detail[0].timer);
                // setRoundNo(result.interview_detail[0].round_no);

                HandleInterviewDetails(
                    // result.interview_detail[0].round_type,
                    result.interview_detail[0].difficulty_level,
                    result.interview_detail[0].question,
                    result.interview_detail[0].limit,
                );
            })
    }

    // This function is for handeling the interview details & fetching the Question , QuestionId , RoundId
    const HandleInterviewDetails = (difficulty_level, questionTopic, limit) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            round_type: roundType,
            difficulty_level: difficulty_level,
            questions: questionTopic,
            limit: limit,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_AI_API_URL}/interview/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setStartTimer(true)

                setRoundId(result.round_id);
                setQuestionId(result.question_ids);
                setQuestionText(result.questions);
            })
    }

    const StartAssessment = () => {
        assessmentStartedRef.current = true;

        InitializeAssessment()
        setInstructionsModal(false)
        handleFullscreen()

        toast.info("Timer will get start once you get the question.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });

        // window.scrollTo(0, 0)
    }

    const SubmitAnswer = () => {
        const formdata = new FormData();
        formdata.append("round_id", roundId);
        formdata.append("question_id", questionId);
        formdata.append("answer_text", AnswerText);
        formdata.append("round_name", roundType);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(`${REACT_APP_AI_API_URL}/analyze-answer/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setMarksObtainedLoader(false); // Stop the loader

                if (result.status == "0") {
                    setMarksObtained("Zero");
                } else if (result.status == "1") {
                    setMarksObtained(result.result);
                }
                assessmentStartedRef.current = false;
            })
            .catch((error) => {
                setMarksObtainedLoader(false); // Stop the loader on error
            });
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            // for full screen
            if (!document.fullscreenElement && isFullscreen) {
                if (assessmentStartedRef.current) {
                    //   exitFullscreen();
                    AutoSubmitAnswer();
                }
            }
            setIsFullscreen(!!document.fullscreenElement); // Update fullscreen status
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, [isFullscreen]);

    useEffect(() => {
        // for tab change
        const handleBlur = () => {
            if (assessmentStartedRef.current) {
                AutoSubmitAnswer();
            }
        };
        window.addEventListener("blur", handleBlur);
        return () => {
            window.removeEventListener("blur", handleBlur);
        };
    }, []);

    const AutoSubmitAnswer = () => {
        setShowCheatingMarksModal(true)
        setMarksObtained("Zero");
        setStartTimer(false);

        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error("Error attempting to exit fullscreen mode:", err);
            });
        }
    };

    const HandleOnTimerStop = () => {
        setStartTimer(false)
        setTimeLimit(0)
        setMarksObtainedLoader(true)

        toast.success("Time’s up! Your response has been submitted.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });
        SubmitAnswer()
    }

    const ClickSubmitAnswer = () => {
        setStartTimer(false)
        // setTimeLimit("0")
        setMarksObtainedLoader(true)

        toast.success("The response is submitted and is currently being processed.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });

        SubmitAnswer()
    };

    const RoundComplete = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid,
            "vacancy_uuid": VacancyIdForStartAssessment,
            "round_no": roundNo,
            "round_type": roundType,
            "round_marks": MarksObtained,
            "round_status": "true"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/assessment_detail`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    navigate("/applied_vacancy")
                }
            })
    }

    return (
        <>
            <div className="CodingRound">
                <KeyboardEventHandler
                    handleKeys={['F11', 'esc', 'ctrl+tab', 'alt+tab', 'meta']}
                    isDisabled={true}
                    onKeyEvent={(key, e) => {
                        console.log(`Key pressed: ${key}`);
                        e.preventDefault();
                    }}
                />

                <img src={StarteleName} id="Logo" />

                <div className="RoundInfo">
                    <div className="RoundInfoLeft">
                        <h2>Round Name : This is the Coding Round</h2>
                        <h3>Difficulty Level : Level {difficultyLevel} </h3>
                        <p>Round : 4 / 4 </p>
                    </div>

                    <div id="trialBox" className={TimerAtBottom ? "move-bottom" : "move-top"}>
                        <CountdownCircleTimer
                            isPlaying={StartTimer}
                            fill={"yellow"}
                            duration={TimeLimit}
                            key={TimeLimit} // To ensure the timer resets when TimeLimit changes
                            colors={["#9ef01a", "#c6db34", "#f7b801", "#f8961e", "#dd1c1a"]}
                            colorsTime={[TimeLimit, TimeLimit * 0.75, TimeLimit * .5, TimeLimit * .25, 0]}
                            size={140}
                            strokeWidth={15}
                            trailStrokeWidth={10}
                            trailColor={"#7AB4F566"}
                            onComplete={HandleOnTimerStop}
                        >
                            {({ remainingTime }) => (
                                <div style={{ color: "white", fontSize: "30px" }}>
                                    {remainingTime}
                                </div>
                            )}
                        </CountdownCircleTimer>
                    </div>
                </div>

                <div className="Section_1">
                    <div className="Box_1">
                        <p>
                            Note : You can check your work by compiling the code. If it meets the requirements of the question, you can submit the assessment by clicking on the 'SUBMIT' button below. Otherwise, the assessment will auto-submit when the time is up.
                        </p>

                        <h1>
                            Ques. {" "}
                            {questionText ? (
                                questionText
                            ) : (
                                <Bars visible={true} height="30" width="30" />
                            )}
                        </h1>
                    </div>

                    <CodeEditorScreen handleAnswerTextFrom={handleAnswerTextFrom} />

                    <div className="Box_2">
                        {
                            (!MarksObtained || (MarksObtained == null)) &&
                            <button id="SubmitButton"
                                style={{ backgroundColor: '#7bb4f566' }}
                                onClick={ClickSubmitAnswer}
                            >
                                {
                                    MarksObtainedLoader ?
                                        "Loading ... " :
                                        "Submit Code"
                                }
                            </button>
                        }


                        {
                            (MarksObtained) &&
                            <button id="SubmitButton"
                                style={{ backgroundColor: '#10e746cc' }}
                                onClick={exitFullscreen}
                            >
                                View Result
                            </button>
                        }
                    </div>
                </div>
            </div >


            {/* Modal for instructions before assessment  */}
            <Modal Modal open={InstructionsModal} >
                <Box sx={ModalStyle2} className="CodingRoundModal">
                    <div>
                        <h3> Important Instructions for Coding Round</h3>

                        <p style={{ color: "grey" }}>
                            {" "}
                            Please mug up these important instructions carefully before
                            attemting the assessment .
                        </p>
                    </div>

                    <ul>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please do not refresh the page once the assessment gets start.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please do not switch browser tabs or window while completing the assessment or your assessment will get auto submit.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please ensure that your internet connection and power supply are functioning properly. Before starting the assessment, confirm that your device's keyboard and other necessary peripherals are working correctly.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" />This is the coding round. The timer will start once you click the 'START' button below. An IDE screen is provided for writing the code for the question, along with an option to select the coding language.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> You can check your work by compiling the code. If it meets the requirements of the question, you can submit the assessment by clicking on the 'SUBMIT' button. Otherwise, the assessment will auto-submit when the time is up.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> The timeline for this round will be displayed at the top of the assessment page as a Timer. Please adhere to the specified timings.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> After completing the assessment you will be redirected to the applied jobs page.
                            </p>
                        </li>

                    </ul>

                    <div>
                        <Button
                            className="Button_1"
                            variant="contained"
                            style={{ margin: "0px 10px" }}
                            onClick={StartAssessment}
                        >
                            Start
                        </Button>

                        <Button
                            onClick={() => navigate("/applied_vacancy")}
                            style={{ color: "indianRed" }}
                            variant="outlined"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={ShowMarksModal}>
                <Box sx={ModalStyle2} className="CodingRoundModal" style={{ width: '50%', padding: '25px 20px' }} >
                    <h3> Assessment Submitted Successsfully</h3>

                    <p style={{ color: "grey", textAlign: 'center' }}>
                        These are your provisional marks based on your responses. The final
                        marks will be sent to your email followed by the further assessment round if
                        qualified.
                    </p>

                    <center>
                        {
                            MarksObtained ?
                                <h1 id="MarksObtained" >
                                    {MarksObtained} / 100
                                </h1>
                                : (
                                    <div style={{ display: 'flex', justifyContent: 'center' }} >
                                        <Bars visible={true} height="30" width="70" color="#9c88ff" />
                                    </div>
                                )
                        }
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: 'scale(1.2)' }}
                        onClick={RoundComplete}
                    >
                        Complete
                    </Button>
                </Box>
            </Modal>

            <Modal open={ShowCheatingMarksModal}>
                <Box
                    sx={ModalStyle2}
                    className="ExtemporeRoundModal"
                    style={{ width: "50%", padding: "25px 20px" }}
                >

                    <h3 style={{ color: 'indianred' }} > *** Assessment submitted  ***</h3>

                    <p style={{ color: "indianred", textAlign: "center" }}>
                        Since you attempted to switch tabs or exit full-screen mode, which violates the assessment guidelines outlined in the instructions, your assessment has been submitted, and your obtained marks are displayed below.
                    </p>

                    <center>
                        {MarksObtained ? (
                            <h1 id="MarksObtained">{MarksObtained} / 100</h1>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Bars visible={true} height="30" width="70" color="#9c88ff" />
                            </div>
                        )}
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: "scale(1.2)" }}
                        onClick={RoundComplete}
                    >
                        Complete
                    </Button>
                </Box>
            </Modal >
        </>
    )
}

export default CodingRound
