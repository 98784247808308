import React, { useEffect, useState } from "react";
import "./SeekerHomePage.css";
import { H1, H2 } from "../Typography";
import Avatar from "../../Assets/Avtar.png";
import {
  Button,
  ToggleButton,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  Box,
  Modal,
} from "@mui/material";
import {
  AttachMoney,
  Bookmark,
  BookmarkBorderOutlined,
  BusinessCenterOutlined,
  BusinessOutlined,
  Delete,
  Edit,
  Email,
  GpsFixed,
  HouseRounded,
  LocationOnOutlined,
  Phone,
  WorkHistoryRounded,
  WorkOutline,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import CloseIcon from "@mui/icons-material/Close";
import VerifiedIcon from "@mui/icons-material/Verified";
import TaskIcon from "@mui/icons-material/Task";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import { toast } from "react-toastify";

import Logo from "../../Assets/StarteleLogo.png";
import CoverPhoto from "../../Assets/EnterpriseCoverPhoto.jpg";
import SentImg from "../../Assets/Files sent.gif";
import { Bars } from "react-loader-spinner";

const ModalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "10px",
  border: "none",
  p: 3,
  animationName: "none",
};

const ProfileHome = () => {
  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
  const customId = "forNotShowingMultipleToast"

  const navigate = useNavigate();

  const ProfileCompletionPercentage = localStorage.getItem("ProfileCompletionPercentage");


  const FullName = localStorage.getItem("FullName");
  const UserUuid = localStorage.getItem("UserUuid");
  const Designation = localStorage.getItem("Designation");
  const Experience = localStorage.getItem("Experience");
  const isResumeUploaded = localStorage.getItem("CheckResume") === "true";
  const CheckProfileId = localStorage.getItem("CheckProfileId");




  // Loader state for show the button loader
  const [AllDataResponse, setAllDataResponse] = useState("")

  const getTruncatedText = (text) => {
    const words = text.split("");
    const wordLimit = 150;
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join("") + "...";
    }
    return text;
  };

  // Modal state for applied successfully
  const [OpenAppliedSuccesfullyModal, setOpenAppliedSuccesfullyModal] = useState(false);

  const handleApply = (vacancy_uuid) => {

    if (!isResumeUploaded) {
      toast("Please complete your profile", {
        position: 'top-right',
        autoClose: 2000
      })
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      vacancy_uuid: vacancy_uuid,
      apply_status: "",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };


    fetch(`${REACT_APP_BASE_API_URL}/apply`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        if (result.Status == "1") {
          setOpenAppliedSuccesfullyModal(true);
          fetchAppliedVacancies();
        } else {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return;
        }
      })
      .catch((error) => console.error(error));
  };

  const [vacancies, setVacancies] = useState([]);
  const [appliedVacancies, setAppliedVacancies] = useState([]);
  const [vacanciesTitle, setVacanciesTitle] = useState([]);
  const [selectedRole, setSelectedRole] = useState("0");


  const filteredVacancies = selectedRole === "0" ? vacancies : vacancies.filter(vacancy => vacancy.job_title === selectedRole);

  const HandleViewVacancyPage = (vacancyId, enterpriseId) => {
    // This function is to handle the Read More button to view vacancy
    localStorage.setItem("VacancyIdForViewVacancyPage", vacancyId);
    localStorage.setItem("EnterpriseIdForViewVacancyPage", enterpriseId);
    navigate("/vacancy_page");
  };

  const fetchJobVacancies = async () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };
    try {
      const response = await fetch(
        `${REACT_APP_BASE_API_URL}/view_vacancy_by_seeker`,
        requestOptions
      );
      const data = await response.json();
      if (data && data.Status === "1") {
        setVacancies(data.vacancies);
        setAllDataResponse(data.Status);
      } else {
        console.error("Failed to fetch job vacancies:", data.Msg);
      }
    } catch (error) {
      console.error("Error fetching job vacancies:", error);
    }
  };
  const fetchAppliedVacancies = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_API_URL}/view_applied_vacancies`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);

        setAppliedVacancies(result.apply);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchAppliedVacancies();
    fetchJobVacancies();
  }, []);

  const [SaveJob, setSavejob] = useState(false);

  // Seekar graph states start here
  const [allExperience, setAllExperience] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [months, setMonths] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const [SelectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [SelectedMonth, setSelectedMonth] = useState(
    (new Date().getMonth() + 1).toString()
  );

  const generateYearsArray = (startYear, endYear) => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };
  const currentYear = new Date().getFullYear();
  const yearsArray = generateYearsArray(1950, currentYear);

  // const handleMonth = (e) => {
  //     setSelectedMonth(e.target.value);
  // };

  const handleYear = (e) => {
    setSelectedYear(e.target.value);
  };

  const staticData = [
    {
      name: "January",
      value: 12,
    },
    {
      name: "February",
      value: 0,
    },
    {
      name: "March",
      value: 22,
    },
    {
      name: "April",
      value: 0,
    },
    {
      name: "May",
      value: 0,
    },
    {
      name: "June",
      value: 0,
    },
    {
      name: "July",
      value: 9,
    },
    {
      name: "August",
      value: 0,
    },
    {
      name: "September",
      value: 0,
    },
    {
      name: "October",
      value: 0,
    },
    {
      name: "November",
      value: 12,
    },
    {
      name: "December",
      value: 0,
    },
  ];

  const getOption = () => {
    return {
      baseOption: {
        timeline: {
          show: false,
          autoPlay: true,
          axisType: "category",
          bottom: 20,
          data: months,
          height: null,
          inverse: true,
          left: null,
          orient: "vertical",
          playInterval: 500,
          right: 50,
          top: 20,
          width: 55,
          label: {
            normal: {
              textStyle: {
                color: "#aaa",
              },
            },
            emphasis: {
              textStyle: {
                color: "#333",
              },
            },
          },
          symbol: "none",
          lineStyle: {
            color: "#aaa",
          },
          checkpointStyle: {
            color: "#354EF6",
            borderColor: "transparent",
            borderWidth: 2,
          },
          controlStyle: {
            showNextBtn: false,
            showPrevBtn: false,
            normal: {
              color: "#354EF6",
              borderColor: "#354EF6",
            },
            emphasis: {
              color: "#5d71f7",
              borderColor: "#5d71f7",
            },
          },
        },

        color: ["#a29bfe"],

        title: {
          subtext: "",
          textAlign: "left",
          left: "3%",
          top: 10,
        },

        tooltip: { backgroundColor: "#dfe6e9", borderWidth: 0, padding: 12 },

        legend: {
          data: ["data"],
          itemGap: 35,
          itemHeight: 18,
          right: "11%",
          top: 20,
          show: false,
        },

        calculable: true,

        grid: {
          top: 80,
          bottom: 130,
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              label: {
                show: true,
                formatter: function (params) {
                  return params.value.replace("\n", "");
                },
              },
            },
          },
        },
        xAxis: [
          {
            axisLabel: {
              interval: 0,
              rotate: 40,
              textStyle: {
                baseline: "top",
                color: "#333",
                fontSize: 11,
                fontWeight: "bold",
              },
            },
            axisLine: { lineStyle: { color: "#aaa" }, show: true },
            axisTick: { show: false },
            data: staticData.map((item) => item.name),
            splitLine: { show: false },
            type: "category",
          },
        ],

        yAxis: [
          {
            axisLabel: {
              textStyle: { fontSize: 11 },
            },
            axisLine: { show: false },
            axisTick: { show: false },
            name: "Profile view",
            splitLine: {
              lineStyle: {
                type: "dotted",
              },
            },
            type: "value",
          },
        ],

        series: [
          {
            name: "Profile view",
            type: "line",
            barWidth: "30%",
            data: staticData.map((item) => item.value),
          },
        ],
      },
      options: months.map((month) => ({
        series: [
          {
            stack: "group",
            data: staticData,
          },
        ],
        title: {
          text: "",
        },
      })),
    };
  };

  const [SelectApplyRole, setSelectApplyRole] = useState("0");


  //For viewing all Experience
  const viewAllExperience = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_experience`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setAllDataResponse(result.Status)
        setAllExperience(result.experiences);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    viewAllExperience();
  }, []);


  return (
    <>
      <div className="SeekerHomePage">
        <div className="profile_section">
          <div className="Profile_left">
            <div id="one">
              <div id="welcome">
                <h2>
                  Welcome to <span> startele-Hire</span>{" "}
                </h2>
                <p>
                  {" "}
                  Introducing our AI-powered interview platform designed to
                  revolutionize the hiring process for job seekers and
                  enterprises alike. Refine your skills and boost your
                  confidence with our intelligent practice tools. Experience
                  seamless, efficient, and effective hiring with us, and connect
                  with a vast network of professionals.{" "}
                </p>
              </div>
            </div>

            <div id="two">
              <div className="JobCardDetail">
                <h3>Select Vacancy </h3>
                <p>
                  Here is the list of all vacancies , please select the role you
                  wish to apply for from the dropdown menu below.{" "}
                </p>

                <div className="SelectElement" style={{ marginTop: '12px', height: '50px' }} >
                  <select value={selectedRole}
                    // tabIndex="4"
                    onChange={(e) => setSelectedRole(e.target.value)}
                  >
                    <option value="0">All vacancies</option>
                    {vacancies.map((vacancy, index) => {
                      return (
                        <option value={vacancy.job_title}>
                          {vacancy.job_title}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <FormControl style={{ width: "100%" }}>

                </FormControl>
              </div>

              {/* <div className="cardBox">
                {selectedRole === "0"
                  ? vacancies.map((vacancy) => (
                      <div className="JobCard" key={vacancy.vacancy_uuid}>
                        <h3>{vacancy.job_title}</h3>
                        <div>
                          <h1>{vacancy.job_title}</h1>
                          <p>
                            <WorkOutline id="icon" /> Startelelogic Pvt. Ltd
                          </p>
                          <p>
                            <BusinessCenterOutlined id="icon" />{" "}
                            {vacancy.job_experience}
                          </p>
                          <p>
                            <LocationOnOutlined id="icon" />{" "}
                            {vacancy.job_location}
                          </p>
                          <p>
                            <AttachMoney id="icon" /> {vacancy.job_salary}
                          </p>
                          <hr />
                          <h2>
                            {vacancy.job_description
                              ? getTruncatedText(vacancy.job_description)
                              : "Add job description ...."}
                          </h2>

                          {isResumeUploaded ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() =>
                                  HandleViewVacancyPage(
                                    vacancy.vacancy_uuid,
                                    vacancy.tenant_uuid
                                  )
                                }
                                style={{
                                  fontSize: "13px",
                                  color: "white",
                                  border: "1px solid #011015",
                                  backgroundColor: `#8ecae6`,
                                  border: "0px ",
                                }}
                              >
                                Read More
                              </Button>

                              <Button
                                variant="contained"
                                style={{
                                  fontSize: "13px",
                                  color: "white",
                                  backgroundColor: "#176D96",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleApply(vacancy.vacancy_uuid)
                                }
                              >
                                APPLY
                              </Button>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={() =>
                                  HandleViewVacancyPage(
                                    vacancy.vacancy_uuid,
                                    vacancy.tenant_uuid
                                  )
                                }
                                style={{
                                  fontSize: "13px",
                                  color: "white",
                                  border: "1px solid #011015",
                                  backgroundColor: `#8ecae6`,
                                  border: "0px ",
                                }}
                              >
                                Read More
                              </Button>

                              <Tooltip title="Resume must be uploaded to apply">
                                <Button
                                  disabled={true}
                                  variant="contained"
                                  style={{
                                    fontSize: "13px",
                                    color: "white",
                                    backgroundColor: "#176D96",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleApply(vacancy.vacancy_uuid)
                                  }
                                >
                                  APPLY
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  : vacancies
                      .filter((vacancy) => vacancy.job_title === selectedRole)
                      .map((vacancy) => (
                        <div className="JobCard" key={vacancy.vacancy_uuid}>
                          <h3>{vacancy.job_title}</h3>
                          <div>
                            <h1>{vacancy.job_title}</h1>
                            <p>
                              <WorkOutline id="icon" /> Startelelogic Pvt. Ltd
                            </p>
                            <p>
                              <BusinessCenterOutlined id="icon" />{" "}
                              {vacancy.job_experience}
                            </p>
                            <p>
                              <LocationOnOutlined id="icon" />{" "}
                              {vacancy.job_location}
                            </p>
                            <p>
                              <AttachMoney id="icon" /> {vacancy.job_salary}
                            </p>
                            <hr />
                            <h2>
                              {vacancy.job_description
                                ? getTruncatedText(vacancy.job_description)
                                : "Add job description ...."}
                            </h2>

                            {isResumeUploaded ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    HandleViewVacancyPage(
                                      vacancy.vacancy_uuid,
                                      vacancy.tenant_uuid
                                    )
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color: "white",
                                    border: "1px solid #011015",
                                    backgroundColor: `#8ecae6`,
                                    border: "0px ",
                                  }}
                                >
                                  Read More
                                </Button>

                                <Button
                                  variant="contained"
                                  style={{
                                    fontSize: "13px",
                                    color: "white",
                                    backgroundColor: "#176D96",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    handleApply(vacancy.vacancy_uuid)
                                  }
                                >
                                  APPLY
                                </Button>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    HandleViewVacancyPage(
                                      vacancy.vacancy_uuid,
                                      vacancy.tenant_uuid
                                    )
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color: "white",
                                    border: "1px solid #011015",
                                    backgroundColor: `#8ecae6`,
                                    border: "0px ",
                                  }}
                                >
                                  Read More
                                </Button>

                                <Tooltip title="Resume must be uploaded to apply">
                                  <Button
                                    disabled={true}
                                    variant="contained"
                                    style={{
                                      fontSize: "13px",
                                      color: "white",
                                      backgroundColor: "#176D96",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleApply(vacancy.vacancy_uuid)
                                    }
                                  >
                                    APPLY
                                  </Button>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
              </div> */}

              {AllDataResponse === "1" ? (
                <div className="cardBox">

                  {
                    vacancies.length < 1 ? (
                      <div className="JobCard" >
                        <h3>-- -- </h3>
                        <div>
                          <h1>No Vacancy Currently</h1>
                          <p>
                            <WorkOutline id="icon" /> Company Name
                          </p>
                          <p>
                            <BusinessCenterOutlined id="icon" />{" "}
                            Experience
                          </p>
                          <p>
                            <LocationOnOutlined id="icon" />{" "}
                            Location
                          </p>
                          <p>
                            <AttachMoney id="icon" /> Salary
                          </p>
                          <hr />
                          <h2>
                            Here will be the description of the vacancy ( skills , elborated responsibilities etc.) ...
                          </h2>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                fontSize: "13px",
                                color: "white",
                                border: "1px solid #176D96",
                                backgroundColor: "#176D96",
                                border: "0px",
                              }}
                              disabled
                            >
                              Read More
                            </Button>

                            <Button
                              variant="contained"
                              style={{
                                fontSize: "13px",
                                color: "white",
                                backgroundColor: "#176D96",
                                cursor: "default",
                              }}
                              disabled
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) :
                      filteredVacancies.map((vacancy) => {
                        const isApplied = appliedVacancies.some(
                          (applied) => applied.vacancy_uuid === vacancy.vacancy_uuid
                        );
                        return (
                          <div className="JobCard" key={vacancy.vacancy_uuid}>
                            <h3>{vacancy.job_title}</h3>
                            <div>
                              <h1>{vacancy.job_title}</h1>
                              <p>
                                <WorkOutline id="icon" /> Startelelogic Pvt. Ltd
                              </p>
                              <p>
                                <BusinessCenterOutlined id="icon" />{" "}
                                {vacancy.job_experience}
                              </p>
                              <p>
                                <LocationOnOutlined id="icon" />{" "}
                                {vacancy.job_location}
                              </p>
                              <p>
                                <AttachMoney id="icon" /> {vacancy.job_salary}
                              </p>
                              <hr />
                              <h2>
                                {vacancy.job_description
                                  ? getTruncatedText(vacancy.job_description)
                                  : "Add job description ...."}
                              </h2>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "10px",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    HandleViewVacancyPage(
                                      vacancy.vacancy_uuid,
                                      vacancy.tenant_uuid
                                    )
                                  }
                                  style={{
                                    fontSize: "13px",
                                    color: "white",
                                    border: "1px solid #176D96",
                                    backgroundColor: "#176D96",
                                    border: "0px",
                                  }}
                                >
                                  Read More
                                </Button>

                                {isResumeUploaded ? (
                                  <Tooltip title={isApplied ? "You have already applied" : "You can now apply"} >
                                    <span>
                                      <Button
                                        variant="contained"
                                        style={{
                                          fontSize: "13px",
                                          color: "white",
                                          backgroundColor: isApplied ? "green" : "#176D96",
                                          cursor: isApplied ? "default" : "pointer",
                                        }}
                                        onClick={() => !isApplied && handleApply(vacancy.vacancy_uuid)}
                                        disabled={isApplied}
                                      >
                                        {isApplied ? "Applied" : "APPLY"}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Resume must be uploaded to apply">
                                    <span>
                                      <Button
                                        variant="contained"
                                        style={{
                                          fontSize: "13px",
                                          color: "white",
                                          backgroundColor: isApplied ? "green" : "#176D96",
                                          cursor: "default",
                                        }}
                                        disabled
                                      >
                                        {isApplied ? "Applied" : "APPLY"}
                                      </Button>
                                    </span>
                                  </Tooltip>
                                )}

                              </div>
                            </div>
                          </div>
                        );
                      })
                  }
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: '200px',
                  }}
                >
                  <Bars
                    height="40"
                    width="60"
                    color="#9c88ff"
                    ariaLabel="bars-loading"
                    visible={true}
                  />
                </div>
              )}


            </div>

            {/* <div className='Seekar_graph'>
                        <ReactEcharts
                            style={{ height: '90vh', width: '100%' }}
                            option={getOption()}
                            opts={{ renderer: "svg" }}
                        />

                        <div className='Seekar_date_select'>
                            <Select
                                value={SelectedYear}
                                onChange={handleYear}
                                displayEmpty
                                style={{ height: "30px", width: "10%", margin: "10px 10px" }}
                                MenuProps={{
                                    getcontentanchorel: null,
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="0" disabled>
                                    Select a year
                                </MenuItem>
                                {
                                    yearsArray.map((year, index) => {
                                        return <MenuItem key={index} value={year}>{year}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                    </div> */}
          </div>

          <div style={{opacity: AllDataResponse === "1" ? "1" :".3" }} className="Profile_right">
            <div id="one">
              <div id="profile">
                <div id="ProfileImage">
                  {/* <img src={Avatar} /> */}
                  <div
                    style={{
                      left: "10px",
                      color: "#333",
                      fontSize: "40px",
                      width: "140px",
                      height: "140px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textTransform: 'uppercase'
                    }}
                  >
                    {FullName && FullName.split(' ').map(name => name.charAt(0)).join('')}
                  </div>
                </div>
              </div>
              <h2>{FullName}</h2>
              <div id="ProfileDetails">
                <p>
                  Designation : <span> {Designation}</span>
                </p>
                <p>
                  Experience : <span>  {allExperience?.length > 0 ? "Experience" : Experience} </span>
                </p>
                {/* <p>
                Skills : <span> HTML , CSS , React.js , </span>
              </p> */}
              </div>
              <Button
                variant="contained"
                onClick={() => navigate("/profilePage")}
                style={{
                  fontSize: "15px",
                  width: "50%",
                  color: "white",
                  border: "1px solid #011015",
                  backgroundColor: "#176D96",
                  border: "0px ",
                  marginTop: "10px",
                }}
              >
                {CheckProfileId == "true" ? "View Profile" : "Complete Profile"}
              </Button>
            </div>
            {/* <hr id='borderLine' />
                    <div id="two">
                        <ul>
                            <li> <HouseRounded id="icon" /> <p> Home </p></li>
                            <li> <WorkHistoryRounded id="icon" /> <p> Recommended Jobs   </p></li>
                            <li> <BusinessOutlined id="icon" /> <p> Companies   </p></li>
                            <li> <HouseRounded id="icon" /><p> Home </p></li>
                        </ul>
                    </div> */}
          </div>
        </div >
      </div >

      {/* Applied Successfully Modal  */}
      <Modal Modal
        open={OpenAppliedSuccesfullyModal}
        onClose={() => setOpenAppliedSuccesfullyModal(false)}
      >
        <Box sx={{ ...ModalStyle2 }}>
          <CloseIcon
            onClick={() => setOpenAppliedSuccesfullyModal(false)}
            style={{
              cursor: "pointer",
              position: "absolute",
              right: "15px",
              color: "black",
              fontSize: "35px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center", // Center align text
              rowGap: "10px",
            }}
          >
            <img src={SentImg} style={{ height: "280px", width: "auto" }} />
            <h1
              style={{
                color: "#232a58",
                fontSize: "23px",
                fontWeight: "400",
              }}
            >
              You have Successfully Applied for the Job !
            </h1>
            {/* <p
              style={{
                fontSize: "17px",
                fontWeight: "400",
                color: "grey",
              }}
            >
              You can now visit the applied jobs page (accessible via the top navbar) and proceed with the first round of your interview by clicking the "Start Assessment" button.
            </p> */}

            <div>
              <Button
                className="Button_1"
                variant="contained"
                style={{ margin: "0px 10px" }}
                onClick={() => navigate('/applied_vacancy')}
              >
                View Applied Jobs
              </Button>

              {/* <Button
                onClick={() => setOpenAppliedSuccesfullyModal(false)}
                style={{ color: "indianRed" }}
                variant="outlined"
                color="inherit"
              >
               Close
              </Button> */}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileHome;
