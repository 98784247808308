import React, { useEffect, useState } from 'react';
import './EditVacancy.css';
import { Autocomplete, Button, FormControl, makeStyles, OutlinedInput, TextField } from '@mui/material';
import { HouseRounded, LocationOn, WorkHistory, WorkHistoryRounded } from '@mui/icons-material';
import Logo from '../../../Assets/StarteleLogo.png';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';

const EditVacancy = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()
    const EnterpriseUuid = localStorage.getItem("EnterpriseUuid");  //This is the tenant uuid
    const EditVacancyUUID = localStorage.getItem("EditVacancyUUID") // This is the Vacancy UUID

    // console.log(EditVacancyUUID)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    const [JobTitle, setJobTitle] = useState("")
    const [JobExperience, setJobExperience] = useState("")
    const [JobSalary, setJobSalary] = useState("")
    const [JobLocation, setJobLocation] = useState("")
    const [JobOpening, setJobOpening] = useState("")
    const [JobRole, setJobRole] = useState("")
    const [JobDescription, setJobDescription] = useState("")
    const [JobResponsibilities, setJobResponsibilities] = useState("")
    const [JobQualification, setJobQualification] = useState("")
    const [JobSkills, setJobSkills] = useState('')

    const [SkillsArray, setSkillsArray] = useState([]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && JobSkills.trim() !== '') {
            setSkillsArray([...SkillsArray, JobSkills.trim()]);
            setJobSkills('');
        }
    };



    const handleDelete = (chipToDelete) => () => {
        setSkillsArray((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    const handleViewParticularVacancy = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "vacancy_uuid": EditVacancyUUID
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_particular_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setJobTitle(result.vacancies[0].job_title)
                setJobExperience(result.vacancies[0].job_experience)
                setJobSalary(result.vacancies[0].job_salary)
                setJobLocation(result.vacancies[0].job_location)
                setJobOpening(result.vacancies[0].job_opening)
                setJobRole(result.vacancies[0].job_role)
                setJobDescription(result.vacancies[0].job_description)
                setJobResponsibilities(result.vacancies[0].job_responsibilities)
                setJobQualification(result.vacancies[0].job_qualifications)
                const skills = (JSON.parse(result.vacancies[0].job_skills))
                setSkillsArray(skills)

                // const Job_Skills = JSON.parse(result.vacancies[0].job_skills);

                // setJobSkills(Job_Skills);

            })
            .catch((error) => console.error(error));
    }

    const HandleEditVacancy = () => {


        const Skills = SkillsArray.map((item) => {
            if (typeof item === "object" && item.hasOwnProperty("skill")) {
                return item.skill;
            } else {
                return item;
            }
        });

        if (!JobTitle || !JobExperience || !JobSalary || !JobLocation || !JobOpening || !JobDescription || !JobResponsibilities || !JobQualification || !JobRole || Skills.length == 0) {
            toast.warn("Please fill in all required fields", {
                position: "top-right",
                toastId: customId,
                autoClose: 2000,
            });
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseUuid,
            "vacancy_uuid": EditVacancyUUID,
            "job_title": JobTitle,
            "job_experience": JobExperience,
            "job_salary": JobSalary,
            "job_location": JobLocation,
            "job_opening": JobOpening,
            "job_description": JobDescription,
            "job_responsibilities": JobResponsibilities,
            "job_qualifications": JobQualification,
            "job_skills": Skills,
            "job_role": JobRole
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        // console.log(raw, 'This is raw');

        fetch(`${REACT_APP_BASE_API_URL}/edit_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status === "1") {
                    toast.success(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    navigate('/all_vacancies');
                } else {
                    toast.error(result.Msg, {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 2000,
                    });
                    return;
                }
            })
    }

    useEffect(() => {
        handleViewParticularVacancy()
    }, []);



    return (
        <div className='EditVacancy'>
            <div className='Profile_left' >
                <div className="section_1">
                    <h2>Edit <span> Vacancy <WorkHistory /> </span> </h2>
                    <p> Welcome to the Edit Vacancy page. Here, you can update the job by providing essential details such as the job title, description, and requirements. This user-friendly form ensures your listings are thorough and appealing.</p>
                </div>

                <div className='EditVacancyModals' >
                    <h2>
                        Fill in the required details.
                    </h2>

                    <div id='Type1' >
                        <h3>Job Title <span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please enter the required job title here eg : Web Developer</p>
                        <input
                            type="text"
                            placeholder='Enter job title here'
                            value={JobTitle}
                            onChange={(e) => setJobTitle(e.target.value)}
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Exprience Required <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the required experience for eg : Freshers , 1-2 Years , 3+ Years</p>
                        <input
                            type="text"
                            placeholder='Enter experience here'
                            value={JobExperience}
                            onChange={(e) => setJobExperience(e.target.value)}
                        />
                    </div>


                    <div id='Type1' >
                        <h3>Offered Salary <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the offered salary for eg : 4-5 LPA</p>
                        <input
                            type="text"
                            placeholder='Enter salary amount here'
                            value={JobSalary}
                            onChange={(e) => setJobSalary(e.target.value)}
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Job Location <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the required Job location for eg : Delhi, Banglore</p>
                        <input
                            type="text"
                            placeholder='Enter location here'
                            value={JobLocation}
                            onChange={(e) => setJobLocation(e.target.value)}
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Total Openings <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the total openings for this role for eg: 3, 4</p>
                        <input
                            type="number"
                            placeholder='Enter number of openings here'
                            value={JobOpening}
                            onChange={(e) => setJobOpening(e.target.value)}
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Role Here <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the particular role for this vacancy eg: Front End Developer</p>
                        <input
                            type="text"
                            placeholder='Enter the role name here'
                            value={JobRole}
                            onChange={(e) => setJobRole(e.target.value)}
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Job Description <span style={{ color: 'red' }}>*</span> </h3>
                        <p>Please provide a detailed job description for this vacancy.</p>
                        <OutlinedInput
                            multiline
                            rows={5}
                            type="text"
                            value={JobDescription}
                            onChange={(e) => setJobDescription(e.target.value)}
                            style={{ width: "100%" }}
                            placeholder='Enter decsription here'
                        />
                    </div>

                    <div id='Type1'>
                        <h3>Job Responsibilities <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please list down the responsibilities involved in this job vacancy for eg:
                            <br />
                            1. Design integrated UI / UX designs using HTML , CSS , Javascript.
                            <br />
                            2. Developing front-end by liaising with the design team
                        </p>
                        <OutlinedInput
                            multiline
                            rows={5}
                            type="text"
                            value={JobResponsibilities}
                            onChange={(e) => setJobResponsibilities(e.target.value)}
                            style={{ width: "100%" }}
                            placeholder='Enter decsription here'
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Qualification Required <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the qualification required for this vacancy for eg:B.Tech / MCA / etc. </p>
                        <input
                            type="text"
                            placeholder='Enter qualification here'
                            value={JobQualification}
                            onChange={(e) => setJobQualification(e.target.value)}
                        />
                    </div>

                    <div id='Type1' >
                        <h3>Job Skills <span style={{ color: 'red' }}>*</span></h3>
                        <p>Please specify the skills to be required in this vacancy. </p>

                        <input
                            type="text"
                            placeholder='Enter the skill and press enter.'
                            value={JobSkills}
                            onChange={(e) => setJobSkills(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <div id='SkillsBox' >
                            {SkillsArray.map((data, index) => (
                                <Chip
                                    key={index}
                                    label={data}
                                    onDelete={handleDelete(data)}
                                />
                            ))}
                        </div>
                    </div>

                    <div>
                        <Button
                            onClick={HandleEditVacancy}
                            className='Button_1'
                            variant="contained"
                            style={{ marginRight: '12px' }}
                        >
                            Edit Vacancy
                        </Button>

                        <Button
                            onClick={() => navigate('/all_vacancies')}
                            className='Button_2'
                            variant="outlined"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>

            <div className='Profile_right' >
                <div id='one' >
                    <div id="profile">
                        <div id='ProfileImage'>
                            <img src={Logo} />
                        </div>
                    </div>
                    <h2>Startele Logic Pvt. Ltd.</h2>
                    <p>IT Services & Consulting </p>
                    <p> <LocationOn id="icon" /> Noida , Uttar Pradesh </p>
                    <p>Leading company in software development</p>
                    <Button variant="contained"
                        onClick={() => navigate('/profilePage')}
                        style={{
                            fontSize: '15px',
                            width: '50%',
                            color: 'white',
                            border: "1px solid #4B64FF",
                            backgroundColor: '#4B64FF',
                            border: '0px ',
                            marginTop: '10px'
                        }}
                    >
                        View More
                    </Button>
                </div>
            </div>

        </div>
    )
}

export default EditVacancy
