import React, { useEffect, useRef, useState } from 'react';
import './QuizRound.css';

import { Bars, ColorRing, DNA, Hourglass } from "react-loader-spinner";
import { Box, Button, FormControl, FormControlLabel, FormLabel, Modal, OutlinedInput, Radio, RadioGroup } from "@mui/material";
import StarteleName from "../../../Assets/StarteleLogo.png";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import { Done, Padding, Stream, WarningAmber } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { toast } from 'react-toastify';


const ModalStyle2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
    borderRadius: "0px",
    border: "none",
    animationName: "none",
};

const QuizRound = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen status
    const assessmentStartedRef = useRef(false);

    const navigate = useNavigate();

    const UserUuid = localStorage.getItem("UserUuid");
    const VacancyIdForStartAssessment = localStorage.getItem("VacancyIdForStartAssessment");

    const [InstructionsModal, setInstructionsModal] = useState(true);
    const [ShowMarksModal, setShowMarksModal] = useState(false);
    const [ShowCheatingMarksModal, setShowCheatingMarksModal] = useState(false);

    const [StartTimer, setStartTimer] = useState(false);
    const [TimeLimit, setTimeLimit] = useState("");

    const [difficultyLevel, setDifficultyLevel] = useState("");
    const [roundNo, setRoundNo] = useState("3/4");
    const [roundType, setRoundType] = useState("quiz");
    const [roundId, setRoundId] = useState("");
    const [questionLimit, setquestionLimit] = useState("");

    const [MarksObtained, setMarksObtained] = useState(0)
    const [SelectedAnswerIndex, setSelectedAnswerIndex] = useState("")
    const [MarksObtainedLoader, setMarksObtainedLoader] = useState(false)

    const [QuestionArray, setQuestionArray] = useState([]);
    const [QuestionIDsArray, setQuestionIDsArray] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [IndividualMarks, setIndividualMarks] = useState([]);
    const [ShowResultButton, setShowResultButton] = useState(false);
    const [quizSubmitted, setQuizSubmitted] = useState(false);

    const handleFullscreen = () => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen();
        }
        setIsFullscreen(true); // Set to true when entering fullscreen
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }

        setIsFullscreen(false); // Reset to false when exiting fullscreen
        setShowMarksModal(true); // Show the marks modal when exiting fullscreen intentionally;
    }

    const handleAnswerChange = (e, questionIndex, optionIndex) => {
        console.log(`Selected Option Index: ${optionIndex}`);
        const updatedAnswers = [...userAnswers];
        updatedAnswers[questionIndex] = e.target.value;
        setUserAnswers(updatedAnswers);
        setSelectedAnswerIndex(optionIndex);
    };

    //This function is for the  initilizing the assessment
    const InitializeAssessment = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            user_uuid: UserUuid,
            vacancy_uuid: VacancyIdForStartAssessment,
            round_no: roundNo,
            round_type: roundType
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(
            `${REACT_APP_BASE_API_URL}/interview_assessment`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                // setRoundType(result.interview_detail[0].round_type);
                setDifficultyLevel(result.interview_detail[0].difficulty_level);
                setquestionLimit(result.interview_detail[0].limit);
                setTimeLimit(result.interview_detail[0].timer);
                // setRoundNo(result.interview_detail[0].round_no);

                HandleInterviewDetails(
                    // result.interview_detail[0].round_type,
                    result.interview_detail[0].difficulty_level,
                    result.interview_detail[0].question,
                    result.interview_detail[0].limit,
                );
            })
    }

    // This function is for handeling the interview details & fetching the Question , QuestionId , RoundId
    const HandleInterviewDetails = (difficulty_level, questionTopic, limit) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            round_type: roundType,
            difficulty_level: difficulty_level,
            questions: questionTopic,
            limit: limit,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${REACT_APP_AI_API_URL}/interview/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                setStartTimer(true)
                setRoundId(result.round_id);

                const parsedQuestions = result.questions[0].split('#$, ').map((ques) => {
                    const [question, ...options] = ques.split('$');

                    // Filter out invalid options (like empty strings or options with double '$')
                    const validOptions = options.filter(option => option.trim() !== "" && !option.includes("$$"));

                    return {
                        question: question.trim(),
                        options: validOptions.slice(0, 4)  // Ensure only the first 4 valid options are taken
                    };
                });

                setQuestionArray(parsedQuestions)
                setQuestionIDsArray((result.question_ids).split(','))
            })
    }

    console.log(QuestionArray, 'arraayy___');

    const StartAssessment = () => {
        assessmentStartedRef.current = true;  // This is for tab change and exit full screen action.

        InitializeAssessment()
        setInstructionsModal(false)
        handleFullscreen()

        toast.info("Timer will get start once you get the questions.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });
    }

    const AddAllMarks = (marksArray) => {
        const finalScore = marksArray.reduce((total, mark) => total + mark, 0);
        setMarksObtained(finalScore);
    };

    const SubmitAnswer = () => {
        AddAllMarks(IndividualMarks)
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            // for full screen
            if (!document.fullscreenElement && isFullscreen) {
                if (assessmentStartedRef.current) {
                    //   exitFullscreen();
                    AutoSubmitAnswer();
                }
            }
            setIsFullscreen(!!document.fullscreenElement); // Update fullscreen status
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
        };
    }, [isFullscreen]);

    useEffect(() => {
        // for tab change
        const handleBlur = () => {
            if (assessmentStartedRef.current) {
                AutoSubmitAnswer();
            }
        };
        window.addEventListener("blur", handleBlur);
        return () => {
            window.removeEventListener("blur", handleBlur);
        };
    }, []);

    const AutoSubmitAnswer = () => {
        setShowCheatingMarksModal(true)
        setMarksObtained("Zero");
        setStartTimer(false);

        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error("Error attempting to exit fullscreen mode:", err);
            });
        }
    };

    const HandleOnTimerStop = () => {
        setStartTimer(false)
        setTimeLimit("0")
        setMarksObtainedLoader(false);

        toast.success("Time’s up! Your response has been submitted.", {
            position: "top-right",
            toastId: customId,
            autoClose: 4000,
        });

        setShowResultButton(true);
        setQuizSubmitted(true);
        SubmitAnswer()
        assessmentStartedRef.current = false;
    }

    const ClickSubmitAnswer = () => {
        setMarksObtainedLoader(true);

        const formdata = new FormData();
        formdata.append("round_id", roundId);
        formdata.append("question_id", QuestionIDsArray[currentQuestionIndex]);
        // formdata.append("answer_text", userAnswers[currentQuestionIndex]);
        formdata.append("answer_text", SelectedAnswerIndex);
        formdata.append("round_name", roundType);

        const requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(`${REACT_APP_AI_API_URL}/analyze-answer/`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                const correctAnswer = result.result;
                const userAnswer = SelectedAnswerIndex;

                const mark = (correctAnswer == userAnswer) ? 1 : 0;

                setIndividualMarks(prevMarksArray => [...prevMarksArray, mark]);

                if (currentQuestionIndex < QuestionArray.length - 1) {
                    setMarksObtainedLoader(false);
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                } else {
                    AddAllMarks([...IndividualMarks, mark]);

                    toast.success("The response is submitted and is currently being processed.", {
                        position: "top-right",
                        toastId: customId,
                        autoClose: 4000,
                    });

                    setStartTimer(false)  // This is to stop the timer
                    setShowResultButton(true);
                    setQuizSubmitted(true);
                    setMarksObtainedLoader(false);
                    assessmentStartedRef.current = false;
                }
            });
    };

    console.log(IndividualMarks, 'Individual score ');
    console.log(MarksObtained, 'final score')

    const RoundComplete = () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid,
            "vacancy_uuid": VacancyIdForStartAssessment,
            "round_no": roundNo,
            "round_type": roundType,
            "round_marks": (MarksObtained / QuestionArray.length).toString(),
            "round_status": "true"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/assessment_detail`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.Status == "1") {
                    navigate("/applied_vacancy")
                }
            })
    }

    return (
        <>
            <div className="QuizRound" >
                {/* These are for keyboard event keys handeling  */}
                <KeyboardEventHandler
                    handleKeys={['F11', 'esc', 'ctrl+tab', 'alt+tab', 'meta']}
                    isDisabled={true}
                    onKeyEvent={(key, e) => {
                        e.preventDefault();
                    }}
                />

                <img src={StarteleName} id="Logo" />

                <div className="RoundInfo">
                    <div className="RoundInfoLeft">
                        <h2>Round Name : This is the Quiz Round</h2>
                        <h3>Difficulty Level : Level {difficultyLevel} </h3>
                        <p>Round : 3 / 4 </p>
                    </div>

                    <div id="trialBox">
                        <CountdownCircleTimer
                            isPlaying={StartTimer}
                            fill={"yellow"}
                            duration={TimeLimit}
                            key={TimeLimit} // To ensure the timer resets when TimeLimit changes    
                            colors={["#9ef01a", "#c6db34", "#f7b801", "#f8961e", "#dd1c1a"]}
                            colorsTime={[TimeLimit, TimeLimit * 0.75, TimeLimit * .5, TimeLimit * .25, 0]}
                            size={140}
                            strokeWidth={15}
                            trailStrokeWidth={10}
                            trailColor={"#7AB4F566"}
                            onComplete={HandleOnTimerStop}
                        >
                            {({ remainingTime }) => (
                                <div style={{ color: "white", fontSize: "30px" }}>
                                    {remainingTime}
                                </div>
                            )}
                        </CountdownCircleTimer>
                    </div>
                </div>

                <div className="Section_1">
                    <div className="Box_1">

                        {
                            QuestionArray.length == 0 ? (
                                <div style={{
                                    padding: '50px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    rowGap: '20px'
                                }} >
                                    <Hourglass visible={true} height="70" width="70" colors={['#306cce', '#72a1ed']} />

                                    Fetching the questions .. Be ready !!
                                </div>
                            ) : (
                                <>
                                    <h1>Total Questions: {QuestionArray.length}</h1>
                                    <div className="questionBox">
                                        <FormLabel>
                                            <h1>
                                                Ques {currentQuestionIndex + 1}. {QuestionArray[currentQuestionIndex].question}
                                            </h1>
                                        </FormLabel>
                                        <RadioGroup
                                            aria-labelledby={`question-${currentQuestionIndex}`}
                                            name={`question-${currentQuestionIndex}`}
                                            value={userAnswers[currentQuestionIndex] || ""}
                                            onChange={(e) => {
                                                // Finding the index of the selected option
                                                const selectedOptionIndex = QuestionArray[currentQuestionIndex].options.indexOf(e.target.value);
                                                handleAnswerChange(e, currentQuestionIndex, selectedOptionIndex);
                                            }}
                                        >

                                            {QuestionArray[currentQuestionIndex].options.map((option, i) => (
                                                <FormControlLabel
                                                    key={i}
                                                    value={option}
                                                    control={<Radio />}
                                                    label={option}
                                                    id='options'
                                                />
                                            ))}
                                        </RadioGroup>

                                        <div>
                                            {!quizSubmitted && (
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        fontSize: "13px",
                                                        color: "white",
                                                        backgroundColor: "#176D96",
                                                        cursor: "pointer",
                                                        marginTop: '10px',
                                                        float: 'right',
                                                        width: '100px',
                                                    }}
                                                    onClick={ClickSubmitAnswer}
                                                    disabled={MarksObtainedLoader}
                                                >

                                                    {MarksObtainedLoader ? (
                                                        <Bars visible={true} height="24" width="30" color="white" />
                                                    ) : currentQuestionIndex === QuestionArray.length - 1 ? (
                                                        "Submit"
                                                    ) : (
                                                        "Next"
                                                    )}
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        <p>
                            Note : This is the Quiz round. Each question has 4 options. Select the most appropriate answer for each question within the time limit displayed at the top of the page. Be sure to submit your answers before the time runs out or else it will get autosubmit with the marked responses.
                        </p>
                    </div>

                    <div className="Box_2">
                        {ShowResultButton && (
                            <button id="SubmitButton" onClick={exitFullscreen}>
                                {MarksObtainedLoader ? "Loading..." : "View Result"}
                            </button>
                        )}
                    </div>
                </div>
            </div >

            {/* Modal for instructions before assessment  */}
            <Modal Modal open={InstructionsModal} >
                <Box sx={ModalStyle2} className="QuizRoundModal">
                    <div>
                        <h3> Important Instructions</h3>

                        <p style={{ color: "grey" }}>
                            {" "}
                            Please mug up these important instructions carefully before
                            attemting the assessment .
                        </p>
                    </div>

                    <ul>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please do not refresh the page once the assessment gets start.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please do not switch browser tabs or window while completing the assessment or your assessment will get auto submit.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Please ensure that you are connected to the internet.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" />This is the Quiz round. Each question has 4 options. Select the most appropriate answer for each question within the time limit. Be sure to submit your answers before the time runs out.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> Once your question is submitted, it cannot be revisited.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> The timeline for this round will be displayed at the top of the assessment page as a Timer. Please adhere to the specified timings.
                            </p>
                        </li>
                        <li>
                            <p>
                                <WarningAmber id="TickIcon" /> After completing the assessment you will be redirected to the applied jobs page.
                            </p>
                        </li>
                    </ul>

                    <div>
                        <Button
                            className="Button_1"
                            variant="contained"
                            style={{ margin: "0px 10px" }}
                            onClick={StartAssessment}
                        >
                            Start
                        </Button>

                        <Button
                            onClick={() => navigate("/applied_vacancy")}
                            style={{ color: "indianRed" }}
                            variant="outlined"
                            color="inherit"
                        >
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={ShowMarksModal}>
                <Box sx={ModalStyle2} className="QuizRoundModal" style={{ width: '50%', padding: '25px 20px' }} >
                    <h3> Assessment Submitted Successsfully</h3>

                    <p style={{ color: "grey", textAlign: 'center' }}>
                        These are your provisional marks based on your responses. The final
                        marks will be sent to your email followed by the further assessment round if
                        qualified.
                    </p>

                    <center>
                        <h1 id="MarksObtained">{MarksObtained} / {questionLimit}</h1>

                        {/* {MarksObtained ? (
                            <h1 id="MarksObtained">{MarksObtained} / {questionLimit}</h1>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Bars visible={true} height="30" width="70" color="#9c88ff" />
                            </div>
                        )} */}
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: 'scale(1.2)' }}
                        onClick={RoundComplete}
                    >
                        Complete
                    </Button>
                </Box>
            </Modal>

            <Modal open={ShowCheatingMarksModal}>
                <Box
                    sx={ModalStyle2}
                    className="ExtemporeRoundModal"
                    style={{ width: "50%", padding: "25px 20px" }}
                >

                    <h3 style={{ color: 'indianred' }} > *** Assessment submitted  ***</h3>

                    <p style={{ color: "indianred", textAlign: "center" }}>
                        Since you attempted to switch tabs or exit full-screen mode, which violates the assessment guidelines outlined in the instructions, your assessment has been submitted, and your obtained marks are displayed below.
                    </p>

                    <center>
                        <h1 id="MarksObtained">{MarksObtained} / {questionLimit} </h1>

                        {/* {MarksObtained ? (
                            <h1 id="MarksObtained">{MarksObtained} / {questionLimit} </h1>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <Bars visible={true} height="30" width="70" color="#9c88ff" />
                            </div>
                        )} */}
                    </center>

                    <Button
                        className="Button_1"
                        variant="contained"
                        style={{ margin: "0px auto", transform: "scale(1.2)" }}
                        onClick={RoundComplete}
                    >
                        Complete
                    </Button>
                </Box>
            </Modal >
        </>
    )
}

export default QuizRound
