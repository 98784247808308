import React from "react";

const OutputWindow = ({ outputDetails }) => {
    const getOutput = () => {
        let statusId = outputDetails?.status?.id;

        if (statusId === 6) {
            // compilation error
            return (
                <pre className="px-2 py-1 font-normal text-xs text-red-500">
                    {atob(outputDetails?.compile_output)}
                </pre>
            );
        } else if (statusId === 3) {
            return (
                <pre className="px-2 py-1 font-normal text-xs text-green-500">
                    {atob(outputDetails.stdout) !== null
                        ? `${atob(outputDetails.stdout)}`
                        : null}
                </pre>
            );
        } else if (statusId === 5) {
            return (
                <pre className="px-2 py-1 font-normal text-xs text-red-500">
                    {`Time Limit Exceeded`}
                </pre>
            );
        } else {
            return (
                <pre className="px-2 py-1 font-normal text-xs text-red-500">
                    {atob(outputDetails?.stderr)}
                </pre>
            );
        }
    };
    return (
        <>
            <h1
                style={{
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    backgroundClip: "text",
                    color: "white",
                    backgroundImage: "linear-gradient(to right, #1E293B, #334155)",
                    marginTop: '35px'

                }}
            >
                Output
            </h1>

            <div
                style={{
                    width: "98%",
                    height: "14rem",
                    backgroundColor: "#1e293b",
                    borderRadius: "0.375rem",
                    color: "white",
                    fontWeight: "normal",
                    fontSize: "0.9rem",
                    overflowY: "auto",
                    padding: "10px",
                    boxShadow: '0px 0px 5px white',
                }}
            >
                {outputDetails ? <>{getOutput()}</> : ''}
            </div>
        </>
    );
};

export default OutputWindow;