import React from 'react'
import SeekerProfilePage from './SeekerProfilePage'
import EnterpriseProfilePage from './EnterpriseProfilePage';

const ProfilePage = () => {
    const Account_Type = localStorage.getItem("Account_Type");

    return (
        <>
            {
                Account_Type == "Job_Seeker" &&
                <SeekerProfilePage />
            }

            {
                Account_Type == "Enterprise" &&
                <EnterpriseProfilePage />
            }
        </>
    )
}

export default ProfilePage;
