import React from "react";

const OutputDetails = ({ outputDetails }) => {
    return (
        <div className="metrics-container mt-4 flex flex-col space-y-3">
            <p style={{ color: "white", fontSize: '19px', margin: '5px 0px 0px 10px', fontWeight: '400' }} >
                Status :{" "}
                <span style={{ color: '#a7c957', }}>
                    {outputDetails?.status?.description}
                </span>
            </p>
            <p style={{ color: "white", fontSize: '19px', margin: '5px 0px 0px 10px', fontWeight: '400' }} >
                Memory :{" "}
                <span style={{ color: '#a7c957', }}>
                    {outputDetails?.memory}
                </span>
            </p>
            <p style={{ color: "white", fontSize: '19px', margin: '5px 0px 0px 10px', fontWeight: '400' }} >
                Time :{" "}
                <span style={{ color: '#a7c957', }}>
                    {outputDetails?.time}
                </span>
            </p>
        </div >
    );
};

export default OutputDetails;