import React, { useEffect, useState } from 'react';
import './VacancyPage.css';
import { Button, makeStyles, OutlinedInput } from '@mui/material';
import { AccountBalanceWallet, BusinessCenter, EventAvailable, History, HouseRounded, LocationOn, Mail, Money, Phone, WorkHistory, WorkHistoryRounded } from '@mui/icons-material';
import Logo from '../../../Assets/StarteleLogo.png';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';

const VacancyPage = () => {
    const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
    const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
    const customId = "forNotShowingMultipleToast"

    const navigate = useNavigate()

    const VacancyIdForViewVacancyPage = localStorage.getItem('VacancyIdForViewVacancyPage')
    const EnterpriseIdForViewVacancyPage = localStorage.getItem("EnterpriseIdForViewVacancyPage");  //This is the tenant uuid
    const CurrentDate = new Date()

    const convertDateFormat = (isoDate) => {
        const date = new Date(isoDate);
        const TimeGap = CurrentDate - date;

        const seconds = Math.floor(TimeGap / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);
        const months = Math.floor(days / 30);

        if (months > 0) {
            return `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (weeks > 0) {
            return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };

    function FormatDescription(description) {
        return description.replace(/\n/g, '<br/>');
    }

    const [VacancyArray, setVacancyArray] = useState([])
    const [CompanyArray, setCompanyArray] = useState([])
    const [CompanyName, setCompanyName] = useState("")
    const [CompanyType, setCompanyType] = useState("")
    // const [PageLoader, setPageLoader] = useState(true)

    const HandleViewVacancy = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "tenant_uuid": EnterpriseIdForViewVacancyPage,
            "vacancy_uuid": VacancyIdForViewVacancyPage
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_API_URL}/view_particular_vacancy`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setVacancyArray(result.vacancies)
                setCompanyArray(result.enterprises)
                setCompanyName(result.enterprises[0].company_name)
                setCompanyType(result.enterprises[0].company_type)
            })
    }

    useEffect(() => {
        HandleViewVacancy()
    }, [])

    return (
        <div className='VacancyPage'>
            <div className='Profile_left' >
                <div className="section_1">
                    <h2>Vacancy <span> Details <WorkHistory /> </span> </h2>
                    <p>  This section provides all the pertinent information about the job role, responsibilities, qualifications, and other essential criteria required for potential candidates. Please review the information carefully to ensure you meet the necessary requirements and understand the expectations for the position.  </p>
                </div>

                {
                    (VacancyArray.length < 1) ?
                        <div style={{ display: 'grid', placeItems: 'center', width: "100%", height: "200px" }} >
                            <Bars
                                height="50"
                                width="140"
                                color="#176D96"
                                ariaLabel="bars-loading"
                                visible={true}
                            />
                        </div> :
                        VacancyArray.map((data) => {
                            return <>
                                <div className="section_2">
                                    <h2>{data.job_role} </h2>
                                    <h3>{CompanyName}</h3>

                                    <div id="part_1">
                                        <ul>
                                            <li>
                                                <BusinessCenter id="icon" /> <p>Company Type : {CompanyType} </p>
                                            </li>
                                            <li>
                                                <LocationOn id="icon" />
                                                <p>
                                                    Location : {data.job_location}
                                                </p>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>
                                                <AccountBalanceWallet id="icon" />
                                                <p>
                                                    <span>Salary : {data.job_salary}</span>
                                                </p>
                                            </li>

                                            <li>
                                                <>
                                                    <EventAvailable id="icon" />
                                                    <p> Experience : {data.job_experience} </p>
                                                </>
                                            </li>
                                        </ul>
                                        <li>
                                            <p style={{ color: 'grey', fontSize: 'small' }} ><History id="icon" />
                                                Posted {convertDateFormat(data.created_at)}
                                            </p>
                                        </li>
                                    </div>
                                </div>

                                <div className="section_2">
                                    <h3 style={{ marginBottom: '8px' }}>Job Description</h3>
                                    <p dangerouslySetInnerHTML={{ __html: FormatDescription(data.job_description) }} />
                                </div>

                                <div className="section_2">
                                    <h3 style={{ marginBottom: '8px' }}>Job Responsibilities</h3>
                                    <p dangerouslySetInnerHTML={{ __html: FormatDescription(data.job_responsibilities) }} />
                                </div>

                                <div className="section_3">
                                    <p>Role Name : <span> {data.job_title} </span></p>
                                    <p>Job Location : <span>{data.job_location}</span></p>
                                    <p>Total Vacancies : <span>{data.job_opening}</span></p>
                                    <p>Emloyment Type : <span>Full Time, Permanent</span></p>
                                    <p>Education : <span>{data.job_qualifications} </span></p>
                                    <p>Required Skills  <br />

                                        <div id='SkillsBox' >
                                            {JSON.parse(data.job_skills).map((v) => (
                                                <Chip key={v} label={v} />
                                            ))}
                                        </div>
                                    </p>
                                </div>
                            </>
                        })
                }
            </div>

            <div className='Profile_right' >

                {(CompanyArray.length < 1) ?
                    <div style={{ width: "100%", height: "150px", display: 'grid', placeItems: 'center' }} >
                        <Bars
                            height="30"
                            width="50"
                            color="#176D96"
                            ariaLabel="bars-loading"
                            visible={true}
                        />
                    </div> :
                    CompanyArray.map((data) => {
                        return (
                            <div id='one' >
                                <div id="profile">
                                    <div id='ProfileImage'>
                                        <img src={Logo} />
                                    </div>
                                </div>

                                <h2>{data.company_name}</h2>
                                <p>{data.company_type} </p>
                                <p> <LocationOn id="icon" /> {data.company_city} {" , "} {data.company_state} </p>
                                <p>{data.company_tag_line}</p>
                                {/* <Button variant="contained"
                                    onClick={() => navigate('/profilePage')}
                                    style={{
                                        fontSize: '15px',
                                        width: '50%',
                                        color: 'white',
                                        border: "1px solid #4B64FF",
                                        backgroundColor: '#4B64FF',
                                        border: '0px ',
                                        marginTop: '10px'
                                    }}
                                >
                                    View More
                                </Button> */}
                            </div>
                        )
                    })
                }
            </div>

        </div >
    )
}

export default VacancyPage
