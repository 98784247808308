
import React, { useState } from "react";

import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code }) => {
  const [value, setValue] = useState(code || "");

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  const handleEditorWillMount = (monaco) => {
    monaco.editor.defineTheme('vs-dark-custom', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {},
    });
  };

  const handleEditorCopyPasteOption = (editor, monaco) => {
    editor.onKeyDown((event) => {
      if (event.ctrlKey || event.metaKey) {
        // Disable Ctrl+C (Copy) and Ctrl+V (Paste)
        if (event.code === 'KeyC' || event.code === 'KeyV') {
          event.preventDefault();
        }
      }
    });

    editor.onDidPaste((event) => {
      event.preventDefault();
    });
  };

  return (
    <Editor
      width={`100%`}
      height={'450px'}
      language={language || "javascript"}
      value={value}
      theme="vs-dark"
      defaultValue="// Start writing your code from the next line "
      onChange={handleEditorChange}
      beforeMount={handleEditorWillMount}
      onMount={handleEditorCopyPasteOption}

    />
  );
};

export default CodeEditorWindow;