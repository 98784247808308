import React, { useEffect, useState } from "react";
import "./SeekerProfilePage.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  AccountBalanceWallet,
  Add,
  Boy,
  BoyOutlined,
  BusinessCenter,
  BusinessCenterRounded,
  BusinessOutlined,
  Call,
  CheckBox,
  ContactMailRounded,
  CreateRounded,
  Delete,
  Edit,
  EventAvailable,
  GirlOutlined,
  GpsFixed,
  GradientSharp,
  HorizontalRule,
  HouseRounded,
  LocationOn,
  Mail,
  MailOutline,
  Male,
  Phone,
  WorkHistoryOutlined,
} from "@mui/icons-material";
import Image from "../../Assets/Avtar.png";
import { H1, H2 } from "../Typography";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Autocomplete,
  TextField,
  Tabs,
  Tooltip,
  Chip,
  InputBase,
  outlinedInputClasses,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { saveAs } from "file-saver";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Country, State, City } from "country-state-city";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SimpleCard from "../../Components/SimpleCard";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

import DegreeList from "../../constants/degrees.json";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const ModalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: "0px",
  border: "none",
  p: 3,
  animationName: "none",
  maxHeight: "80vh", // Set a maximum height for the modal
  overflowY: "auto", // Enable vertical scrolling when content overflows
};

const SeekerProfilePage = () => {
  const REACT_APP_BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
  const REACT_APP_AI_API_URL = process.env.REACT_APP_AI_API_URL;
  const customId = "forNotShowingMultipleToast"

  const FullName = localStorage.getItem("FullName");
  const UserUuid = localStorage.getItem("UserUuid");
  const Designation = localStorage.getItem("Designation");
  const Experience = localStorage.getItem("Experience");

  const EmailId = localStorage.getItem("EmailId");
  const MobileNumber = localStorage.getItem("MobileNumber");
  const CheckProfileId = localStorage.getItem("CheckProfileId");

  // API Reponse status states to manage loading
  const [AllDataResponse, setAllDataResponse] = useState("0");



  const navigate = useNavigate();

  const CurrentDate = new Date();

  const convertDateFormat = (isoDate) => {
    // For converting the date format for DOB
    const date = new Date(isoDate);
    const dateString = date.toDateString().split(" ");
    return `${dateString[1]} ${dateString[2]} , ${dateString[3]}`;
  };

  const generateYearsArray = (startYear, endYear) => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };
  const currentYear = new Date().getFullYear();
  const yearsArray = generateYearsArray(1980, currentYear + 3);
  const MonthsArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [ProfessionalSummary, setProfessionalSummary] = useState("");
  const [canUseSpace, setCanUseSpace] = useState(false);
  const [
    OpenEditProfessionalSummaryModal,
    setOpenEditProfessionalSummaryModal,
  ] = useState(false);

  const [ProfileSection, setProfileSection] = useState("1");
  const handleProfileChangeTab = (setValue, newValue) => {
    setProfileSection(newValue);
  };

  // Resume Information states and modal opening states
  const [ResumeInfoSection, setResumeInfoSection] = useState("1");
  const [ResumeName, setResumeName] = useState("");
  const [Resumefile, setResumeFile] = useState("");
  const [ShowResumeLoader, setShowResumeLoader] = useState(false)

  const [GetResume_data, setGetResume_data] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setResumeFile(file.name);
    }
    handleResumeUploadApi(file);
  };

  const handleResumeUploadApi = (file) => {
    setResumeName("")
    setShowResumeLoader(true)

    const formdata = new FormData();
    formdata.append("user_uuid", UserUuid);
    formdata.append("DocumentShortName", file.name);
    formdata.append("Document", file);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };


    fetch(`${REACT_APP_BASE_API_URL}/resume_upload`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success("Resume Uploaded Successfully", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          handleGetResume();
          localStorage.setItem("CheckResume", "true");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleResumeInfoChangeTab = (setValue, newValue) => {
    setResumeInfoSection(newValue);
  };

  const handleGetResume = () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_API_URL}/get_resume_data?user_uuid=${UserUuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setGetResume_data(result.resumes.Content);
          setResumeName(result.resumes.Name);
          setShowResumeLoader(false)
        }
      })
  };

  const viewResume = async () => {
    try {
      const apiUrl = `${REACT_APP_BASE_API_URL}/get_resume_data?user_uuid=${UserUuid}`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.Status === "1") {
        setGetResume_data(data.file_content);

        const base64String = data.file_content;
        if (!base64String) {
          console.error("Base64 string is empty or undefined.");
          return;
        }

        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });

        const url = URL.createObjectURL(blob);
        window.open(url, "_blank");
      } else {
        console.error("Failed to fetch PDF:", data.Msg);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    }
  };

  useEffect(() => {
    handleGetResume();
  }, []);

  const triggerFileInput = () => {
    document.getElementById("resume-input").click();
  };

  // Personal Information States and modal opening states

  const [PersonalInfoSection, setPersonalInfoSection] = useState("1");
  const handleProfileInfoChangeTab = (setValue, newValue) => {
    setPersonalInfoSection(newValue);
  };

  // Profile details states here
  const [OpenCreateProfileModal, setOpenCreateProfileModal] = useState(true); // This profile will open initially when user login after sign in
  useEffect(() => {
    if (CheckProfileId == "true") {
      setOpenCreateProfileModal(false);
    } else {
      setOpenCreateProfileModal(true);
    }
  }, []);

  const [ProfileFullName, setProfileFullName] = useState(FullName);
  const [ProfileGender, setProfileGender] = useState("0");
  const [ProfileEmail, setProfileEmail] = useState("");
  const [DOB, setDOB] = useState(CurrentDate);
  const [ProfileLanguages, setProfileLanguages] = useState([]);
  const [ProfileMobileNumber, setProfileMobileNumber] = useState("");
  const [ProfileAddress, setProfileAddress] = useState("");
  const [ProfileCountryName, setProfileCountryName] = useState("0");
  const [ProfileStateName, setProfileStateName] = useState("0");
  const [ProfileCityName, setProfileCityName] = useState("0")
  const [ProfileMaritalStatus, setProfileMaritalStatus] = useState("0");
  const [profileNoticePeriod, setProfileNoticePeriod] = useState("")
  const [ProfileExperience, setProfileExperience] = useState("");
  const [ProfileDesignation, setProfileDesignation] = useState("");
  const [ProfileCurrentAddress, setProfileCurrentAddress] = useState("")



  const [AllStates, setAllStates] = useState([]);
  const [statesMap, setStatesMap] = useState({});
  const [AllCities, setAllCities] = useState([]);
  const countries = Country.getAllCountries();

  const countryMap = countries.reduce((acc, country) => {
    acc[country.name] = country.isoCode;
    return acc;
  }, {});

  const updatedCountries = countries.map((country) => ({
    label: country.name,
    value: country.name,
    isoCode: country.isoCode,
    ...country,
  }));

  const getStates = (countryIsoCode) => {
    const stateList = State.getStatesOfCountry(countryIsoCode);

    const statesMap = stateList.reduce((acc, state) => {
      acc[state.name] = state.isoCode;
      return acc;
    }, {});

    setStatesMap(statesMap);

    const mappedStates = stateList.map((state) => ({
      label: state.name,
      value: state.name,
      isoCode: state.isoCode,
      ...state,
    }));
    setAllStates(mappedStates);

    return mappedStates;
  };

  // Updating the selected state value
  useEffect(() => {
    if (ProfileCountryName) {
      const countryIsoCode = countryMap[ProfileCountryName];
      getStates(countryIsoCode);
    }
  }, [ProfileCountryName]);

  // Geeting the all cities name based on selected state
  const getCities = (countryIsoCode, stateIsoCode) => {
    const cityList = City.getCitiesOfState(countryIsoCode, stateIsoCode);

    const mappedCities = cityList.map((city) => ({
      label: city.name,
      value: city.name,
      ...city,
    }));
    setAllCities(mappedCities);

    return mappedCities;
  };

  // Updating the cities name in the city selectbox
  useEffect(() => {
    if (ProfileStateName) {
      const countryIsoCode = countryMap[ProfileCountryName];
      const stateIsoCode = statesMap[ProfileStateName];
      getCities(countryIsoCode, stateIsoCode);
    }
  }, [ProfileStateName, statesMap]);

  const [OpenEditBasicDetailsModal, setOpenEditBasicDetailsModal] =
    useState(false); // Basic details editing modal

  const handleProfessionalSummaryInput = (e) => {
    const input = e.target.value;

    // Remove spaces from the input value
    const trimmedInput = input.replace(/\s/g, "");

    // Check if there's any non-space character
    if (trimmedInput.length > 0) {
      setCanUseSpace(true);
    } else {
      setCanUseSpace(false);
    }

    // Allow spaces only if a non-space character has been typed
    const newValue = canUseSpace ? input : trimmedInput;

    setProfessionalSummary(newValue);
  }


  const SaveProfessionalSummary = () => {

    if (!ProfessionalSummary) {
      toast.warn("Please enter Professional Summary", {
        position: 'top-right',
        autoClose: 2000,
        toastId: customId
      })
      return;
    }

    // if(ProfessionalSummary.length < 300){
    //   toast.warn("Please enter 200 character This is the place to showcase your experience and skills. Write a concise overview to demonstrate your accomplishments. A strong summary can highlight why you are a perfect fit for the job. Use this space to advocate for yourself effectively.in professional summary", {
    //     position: "top-right",
    //     autoClose: 2000,
    //     toastId: customId
    //   })
    //   return;
    // }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      gender: ProfileGender,
      dob: DOB,
      full_name: ProfileFullName,
      permanent_address: ProfileAddress || `${ProfileCountryName} ${ProfileStateName} ${ProfileCityName}`,
      current_address: "India",
      current_city: ProfileCityName,
      current_state: ProfileStateName,
      current_country: ProfileCountryName,
      marital_status: ProfileMaritalStatus,
      language: ["English", "Hindi"],
      profile_summary: ProfessionalSummary,
    });



    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/edit_profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.Status == "1") {
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          setProfessionalSummary(ProfessionalSummary);
          setOpenEditProfessionalSummaryModal(false);
          ViewProfileBasicDetails();
        } else {
          toast.error("Unable to add Professional Summary", {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  //Code for Managing Educataion

  const [OpenAddEducationModal, setOpenAddEducationModal] = useState(false); // Add Education
  const [OpenEditEducationModal, setOpenEditEducationModal] = useState(false); // Edit Education
  const [inputValue, setInputValue] = useState("");

  const [AddEducationType, setAddEducationType] = useState("0");
  const [AddSchoolName, setAddSchoolName] = useState("");
  const [AddCollegeName, setAddCollegeName] = useState("");
  const [AddOtherCollegeName, setAddOtherCollegeName] = useState("");
  const [AddSchoolBoard, setAddSchoolBoard] = useState("");
  const [AddDegree, setAddDegree] = useState("");
  const [AddOtherDegree, setAddOtherDegree] = useState("");
  const [AddSchoolSpecilization, setAddSchoolSpecilization] = useState("");
  const [AddCollegeSpecilization, setAddCollegeSpecilization] = useState("");
  const [AddStartYear, setAddStartYear] = useState("0");
  const [AddEndYear, setAddEndYear] = useState("0");
  const [AddPercentage, setAddPercentage] = useState("");

  const [allEducation, setAllEducation] = useState([]);
  const [viewCollegeNames, setViewCollegeNames] = useState([]);
  const [particularEducation, setParticularEducation] = useState([]);

  // Validation for Education fields

  const handleOtherCollegeName = (e) => {
    const OtherCollegeNameValue = e.target.value;
    const alphabeticValue = OtherCollegeNameValue.replace(/[^a-zA-Z\s]/g, "");
    setAddOtherCollegeName(alphabeticValue);
  };

  const viewAllCollegeNames = () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_API_URL}/view_all_universities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setViewCollegeNames(result.universities);
      })
      .catch((error) => console.error(error));
  };
  const handleAddotherDegree = (e) => {
    const OtherDegreeName = e.target.value;
    const alphabeticValue = OtherDegreeName.replace(/[^a-zA-Z\s]/g, "");
    setAddOtherDegree(alphabeticValue);
  };

  const handleSchoolSpecilization = (e) => {
    const SchollSpecilization = e.target.value;
    const alphabeticValue = SchollSpecilization.replace(/[^a-zA-Z\s]/g, "");
    setAddSchoolSpecilization(alphabeticValue);
  };

  const handleCollegeSpecilization = (e) => {
    const CollegeSpeciliaztion = e.target.value;
    const alphabeticValue = CollegeSpeciliaztion.replace(/[^a-zA-Z\s]/g, "");
    setAddCollegeSpecilization(alphabeticValue);
  };



  const handlePercentage = (e) => {
    let inputValue = e.target.value;

    // Regular expression to match only numbers and decimals
    const numericValue = inputValue.replace(/[^0-9.]/g, "");

    // Limit to 3 decimal places if there's a decimal point
    const decimalIndex = numericValue.indexOf('.');
    if (decimalIndex !== -1) {
      inputValue = numericValue.slice(0, decimalIndex + 4); // 3 digits after decimal
    } else {
      inputValue = numericValue;
    }

    // Convert the numeric value to a float
    const percentageValue = parseFloat(inputValue);

    // Allow empty input or validate the percentage is between 0 and 100
    if (inputValue === "" || (!isNaN(percentageValue) && percentageValue >= 0 && percentageValue <= 100)) {
      setAddPercentage(inputValue);
    } else {
      // Optionally, handle invalid input here
      console.warn("Please enter a valid percentage between 0 and 100 with up to 3 decimal places.");
    }
  };




  //for creating new education
  const addEducation = () => {
    if (
      AddEducationType.length == 0 ||
      (AddEducationType == "10" || AddEducationType == "12"
        ? !AddSchoolName || !AddSchoolBoard || !AddSchoolSpecilization
        : AddEducationType == "Graduation" ||
          AddEducationType == "Masters" ||
          AddEducationType == "Phd"
          ? AddCollegeName.length == 0 ||
          AddDegree.length == 0 ||
          !AddCollegeSpecilization
          : !AddCollegeName) ||
      // AddCollegeName == "Other"
      // ? !AddOtherCollegeName || !AddOtherDegree
      // : false
      AddStartYear.length == 0 ||
      AddEndYear.length == 0 ||
      !AddPercentage
    ) {
      toast.warn("Please fill in all required fields", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      return;
    }

    if (AddEndYear <= AddStartYear) {
      toast.warn("End year must be greater than start year", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      education_type: AddEducationType,
      name_of_institute:
        AddEducationType == "Graduation" ||
          AddEducationType == "Masters" ||
          AddEducationType == "Phd"
          ? AddCollegeName == "other"
            ? AddOtherCollegeName
            : AddCollegeName
          : AddSchoolName,

      degree_board:
        AddEducationType == "10" || AddEducationType == "12"
          ? AddSchoolBoard
          : AddDegree == "other"
            ? AddOtherDegree
            : AddDegree,
      specialization:
        AddEducationType == "10" || AddEducationType == "12"
          ? AddSchoolSpecilization
          : AddCollegeSpecilization,
      start_year: AddStartYear.toString(),
      end_year: AddEndYear.toString(),
      percentage: AddPercentage,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/create_education`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setOpenAddEducationModal(false);
          viewAllEducation();

          setAddEducationType("0");
          setAddSchoolBoard("");
          setAddCollegeName("");
          setAddOtherCollegeName("");
          setAddSchoolBoard("");
          setAddDegree("");
          setAddOtherDegree("");
          setAddSchoolSpecilization("");
          setAddCollegeSpecilization("");
          setAddStartYear("0");
          setAddEndYear("0");
          setAddPercentage("");
        }
      })
      .catch((error) => console.error(error));
  };

  //for viewing all educations
  const viewAllEducation = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_education`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllEducation(result.educations);
      })
      .catch((error) => console.error(error));
  };

  const getEducationRank = (educationType) => {
    switch (educationType) {
      case "10":
        return 1;
      case "12":
        return 2;
      case "Graduation":
      case "Diploma":
        return 3;
      case "Masters":
      case "Post Graduation":
        return 4;
      case "PhD":
        return 5;
      default:
        return 6;
    }
  };

  const sortedEducation = Array.isArray(allEducation)
    ? allEducation.sort((a, b) => getEducationRank(a.education_type) - getEducationRank(b.education_type))
    : [];

  useEffect(() => {
    viewAllEducation();
    viewAllCollegeNames();
  }, []);

  const viewParticularEducation = (EducationId) => {
    localStorage.setItem("EducationId", EducationId);
    setOpenEditEducationModal(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      education_uuid: EducationId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_API_URL}/view_particular_education`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setParticularEducation(result.educations);
        setAddEducationType(result.educations[0].education_type);
        setAddSchoolName(result.educations[0].name_of_institute);
        setAddCollegeName(result.educations[0].name_of_institute);
        setAddDegree(result.educations[0].degree_board);
        setAddSchoolBoard(result.educations[0].degree_board);
        setAddSchoolSpecilization(result.educations[0].specialization);
        setAddCollegeSpecilization(result.educations[0].specialization);
        setAddStartYear(result.educations[0].start_year);
        setAddEndYear(result.educations[0].end_year);
        setAddPercentage(result.educations[0].perecentage);
      })
      .catch((error) => console.error(error));
  };

  const editEducation = () => {
    const EducationId = localStorage.getItem("EducationId");

    if (
      !AddEducationType ||
      (AddEducationType == "10" || AddEducationType == "12"
        ? !AddSchoolName || !AddSchoolBoard || !AddSchoolSpecilization
        : AddEducationType == "Graduation" ||
          AddEducationType == "Masters" ||
          AddEducationType == "Phd"
          ? !AddCollegeName || !AddDegree || !AddCollegeSpecilization
          : !AddCollegeName) ||
      // : AddCollegeName == "other"
      // ? !AddOtherCollegeName || !AddOtherDegree
      // : false
      !AddStartYear ||
      !AddEndYear ||
      !AddPercentage
    ) {
      toast.warn("Please fill in all required fields", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      return;
    }
    if (AddEndYear <= AddStartYear) {
      toast.warn("End year must be greater than start year", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      education_uuid: EducationId,
      education_type: AddEducationType,
      name_of_institute:
        AddEducationType == "Graduation" ||
          AddEducationType == "Masters" ||
          AddEducationType == "Phd"
          ? AddCollegeName == "Other"
            ? AddOtherCollegeName
            : AddCollegeName
          : AddSchoolName,
      degree_board:
        AddEducationType == "10" || AddEducationType == "12"
          ? AddSchoolBoard
          : AddDegree == "other"
            ? AddOtherDegree
            : AddDegree,
      specialization:
        AddEducationType == "10" || AddEducationType == "12"
          ? AddSchoolSpecilization
          : AddCollegeSpecilization,
      start_year: AddStartYear.toString(),
      end_year: AddEndYear.toString(),
      percentage: AddPercentage,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/edit_education`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setOpenEditEducationModal(false);
          viewAllEducation();

          setAddEducationType("0");
          setAddSchoolBoard("");
          setAddCollegeName("");
          setAddOtherCollegeName("");
          setAddSchoolBoard("");
          setAddDegree("");
          setAddOtherDegree("");
          setAddSchoolSpecilization("");
          setAddCollegeSpecilization("");
          setAddStartYear("0");
          setAddEndYear("0");
          setAddPercentage("");
        }
      })
      .catch((error) => console.error(error));
  };

  // const [OpenEditEducationModal, setOpenEditEducationModal] = useState(false)

  const [OpenAddExperienceModal, setOpenAddExperienceModal] = useState(false); // Add Experience
  const [OpenEditExperienceModal, setOpenEditExperienceModal] = useState(false); // Edit Experience

  const [AddJobTitle, setAddJobTitle] = useState("");
  const [AddCompanyName, setAddCompanyName] = useState("");
  const [AddCompanyLocation, setAddCompanyLocation] = useState("");
  const [AddEmploymentType, setAddEmploymentType] = useState("");
  const [AddIfCurrentJob, setAddIfCurrentJob] = useState(false);
  const [AddJoiningMonth, setAddJoiningMonth] = useState("0");
  const [AddJoiningYear, setAddJoiningYear] = useState("0");
  const [AddEndingMonth, setAddEndingMonth] = useState("0");
  const [AddNoticePeriod, setAddNoticePeriod] = useState("0");
  const [AddEndingYear, setAddEndingYear] = useState("0");
  const [AddJobDescription, setAddJobDescription] = useState("");
  const [AddYourExpSkills, setAddYourExpSkills] = useState([]);
  const [experienceSkills, setExperienceSkills] = useState([]);
  const [AddYourOtherExpSkills, setAddYourOtherExpSkills] = useState("");

  const [allExperience, setAllExperience] = useState([]);

  // console.log(allExperience.length, 'dkjfkdsjflkdsjf')


  //  For creating Experience
  const addExperience = () => {
    const Skills = experienceSkills.map((skill) => skill.skill);

    if (
      !AddJobTitle ||
      !AddCompanyName ||
      !AddCompanyLocation ||
      !AddEmploymentType ||
      AddJoiningMonth == 0 ||
      AddJoiningYear == 0 ||
      (!AddIfCurrentJob && (AddEndingMonth == 0 || AddEndingYear == 0)) ||
      !AddJobDescription ||
      // (AddYourExpSkills == "other" ? !AddYourOtherExpSkills : !AddYourExpSkills)
      Skills.length == 0 ||
      (AddIfCurrentJob && AddNoticePeriod == 0)

    ) {
      toast.warn("Please fill in all required fields", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      return;
    }

    if (!AddIfCurrentJob) {
      if (AddEndingYear < AddJoiningYear) {
        toast.warn("Ending year must be greater than starting year", {
          position: "top-right",
          toastId: customId,
          autoClose: 2000,
        });
        return;
      }
    }

    // const Skills = experienceSkills.map((skill) => skill.skill);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      job_title: AddJobTitle,
      company_name: AddCompanyName,
      company_location: AddCompanyLocation,
      employment_type: AddEmploymentType,
      joining_month: AddJoiningMonth,
      joining_year: AddJoiningYear.toString(),
      end_month: AddIfCurrentJob ? "0" : AddEndingMonth,
      end_year: AddIfCurrentJob ? "0" : AddEndingYear.toString(),
      currently_working: AddIfCurrentJob.toString(),
      job_description: AddJobDescription,
      skills: Skills,
      notice_period: !AddIfCurrentJob ? "0" : AddNoticePeriod
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };



    fetch(
      `${REACT_APP_BASE_API_URL}/create_experience`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setOpenAddExperienceModal(false);
          viewAllExperience();

          setAddJobTitle("");
          setAddCompanyName("");
          setAddCompanyLocation("");
          setAddEmploymentType("");
          setAddJoiningMonth("0");
          setAddJoiningYear("0");
          setAddEndingMonth("0");
          setAddEndingYear("0");
          setAddIfCurrentJob("");
          setAddJobDescription("");
          setAddNoticePeriod("0");
        }
      })
      .catch((error) => console.error(error));
  };


  //For viewing all Experience
  const viewAllExperience = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_experience`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllExperience(result.experiences);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    viewAllExperience();
  }, []);

  const viewParticularExperience = (ExperienceId) => {
    localStorage.setItem("ExperienceId", ExperienceId);
    setOpenEditExperienceModal(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      experience_uuid: ExperienceId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_API_URL}/view_particular_experience`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAddJobTitle(result.experiences[0].job_title);
        setAddCompanyName(result.experiences[0].company_name);
        setAddCompanyLocation(result.experiences[0].company_location);
        setAddEmploymentType(result.experiences[0].employment_type);
        setAddIfCurrentJob(result.experiences[0].currently_working === "true"); //converting to boolean from string
        setAddJoiningMonth(result.experiences[0].joining_month);
        setAddJoiningYear(result.experiences[0].joining_year);
        setAddEndingMonth(result.experiences[0].end_month);
        setAddEndingYear(result.experiences[0].end_year);
        setAddNoticePeriod(result.experiences[0].notice_period)
        setAddJobDescription(result.experiences[0].job_description);
        const skills = JSON.parse(result.experiences[0].skills);

        setExperienceSkills(skills);

        // setAddYourExpSkills(result.experiences[0].currently_working)
        // setAddYourOtherExpSkills(result.experiences[0].currently_working)
      })
      .catch((error) => console.error(error));
  };

  const editExperience = () => {
    const Skills = experienceSkills.map((item) => {
      if (typeof item === "object" && item.hasOwnProperty("skill")) {
        return item.skill;
      } else {
        return item;
      }
    });

    if (
      !AddJobTitle ||
      !AddCompanyName ||
      !AddCompanyLocation ||
      !AddEmploymentType ||
      AddJoiningMonth == 0 ||
      AddJoiningYear == 0 ||
      (!AddIfCurrentJob && (AddEndingMonth == 0 || AddEndingYear == 0)) ||
      !AddJobDescription ||
      // (AddYourExpSkills == "other" ? !AddYourOtherExpSkills : !AddYourExpSkills)
      Skills.length == 0 ||
      (AddIfCurrentJob && !AddNoticePeriod)
    ) {
      toast.warn("Please fill in all required fields", {
        position: "top-right",
        toastId: customId,
        autoClose: 2000,
      });
      return;
    }

    if (!AddIfCurrentJob) {
      if (AddEndingYear < AddJoiningYear) {
        toast.warn("Ending year must be greater than starting year", {
          position: "top-right",
          toastId: customId,
          autoClose: 2000,
        });
        return;
      }
    }

    const ExperienceId = localStorage.getItem("ExperienceId");
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      // job_description: AddJobDescription,
      user_uuid: UserUuid,
      experience_uuid: ExperienceId,
      job_title: AddJobTitle,
      company_name: AddCompanyName,
      company_location: AddCompanyLocation,
      employment_type: AddEmploymentType,
      joining_month: AddJoiningMonth,
      joining_year: AddJoiningYear.toString(),
      end_month: AddIfCurrentJob ? "0" : AddEndingMonth,
      end_year: AddIfCurrentJob ? "0" : AddEndingYear.toString(),
      currently_working: AddIfCurrentJob.toString(),
      skills: Skills,
      job_description: AddJobDescription,
      notice_period: !AddIfCurrentJob ? "0" : AddNoticePeriod

    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/edit_experience`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          setOpenEditExperienceModal(false);
          viewAllExperience();
          setAddJobTitle("");
          setAddCompanyName("");
          setAddCompanyLocation("");
          setAddEmploymentType("");
          setAddJoiningMonth("0");
          setAddJoiningYear("0");
          setAddEndingMonth("0");
          setAddEndingYear("0");
          setAddIfCurrentJob("");
          setAddJobDescription("");
          setAddNoticePeriod("0")
        }
      })
      .catch((error) => console.error(error));
  };

  const onDeleteExpSkill = (chipToDelete) => () => {
    setExperienceSkills((chips) =>
      chips.filter((chip) => chip.id !== chipToDelete)
    );
  };
  const onDeleteExpEditSkill = (chipToDelete) => {

    setExperienceSkills((chips) =>
      chips.filter((chip) => chip !== chipToDelete)
    );
  };

  const [showOtherExpSkillsInput, setShowOtherExpSkillsInput] = useState(false);

  const handleExpSkillsChange = (event, newValue) => {
    const lastElement = newValue[newValue.length - 1];
    // console.log(newValue, "new value");
    // console.log(lastElement);  
    if (lastElement && lastElement.skill === "Other") {
      setShowOtherExpSkillsInput(true);
    } else {
      const uniqueSkills = Array.from(new Set([...experienceSkills, ...newValue]));
      setExperienceSkills(uniqueSkills);
      setShowOtherExpSkillsInput(false);
    }
  };
  const changeinput = (e) => {
    if (e.target.value == "") {
      return;
    }

  };

  const handleExpSkillKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      const newSkill = { skill: event.target.value.trim() };
      // console.log(event.target.value, "new skill");
      setExperienceSkills([...experienceSkills, newSkill]);
      event.target.value = "";
    }
  };

  // States for skills and  modal opening states
  const [OpenAddYourSkillsModal, setOpenAddYourSkillsModal] = useState(false); // Add Your Skills Modal
  const [OpenEditYourSkillsModal, setOpenEditYourSkillsModal] = useState(false); // Add Your Skills Modal
  const [AddYourSkills, setAddYourSkills] = useState("0");
  const [AddYourOtherSkills, setAddYourOtherSkills] = useState("");

  const [techSkills, setTechSkills] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [skillSetList, setSkillSetList] = useState([]);
  const [softSkillSetList, setsoftSkillSetList] = useState([]);

  const onDeleteTechSkill = (chipToDelete) => () => {
    // console.log(chipToDelete);
    setTechSkills((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const onDeleteSoftSkill = (chipToDelete) => () => {
    // console.log(chipToDelete);

    setSoftSkills((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const onDeleteAddTechSkill = (chipToDelete) => () => {
    // console.log(chipToDelete);

    setTechSkills((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const onDeleteAddSoftSkill = (chipToDelete) => () => {
    // console.log(chipToDelete);

    setSoftSkills((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleAddYourSkills = () => {



    const TechSkills = techSkills.map((skill) => skill.skill);
    const SoftSkills = softSkills.map((skill) => skill.skill);



    if (TechSkills.length === 0 || SoftSkills.length === 0) {
      toast.warn("Please fill in all required fields", {
        position: 'top-right',
        autoClose: 2000,
        toastId: customId
      })
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      soft_skills: SoftSkills,
      tech_skills: TechSkills,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };



    fetch(`${REACT_APP_BASE_API_URL}/add_skills`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOpenAddYourSkillsModal(false);
        setAddYourSkills("0");
        setAddYourOtherSkills("");
        ViewAllSkills();
      })
      .catch((error) => console.error(error));
  };

  const handleEditYourSkills = () => {
    const TechSkills = techSkills.map((item) => {
      if (typeof item === "object" && item.hasOwnProperty("skill")) {
        return item.skill;
      } else {
        return item;
      }
    });

    const SoftSkills = softSkills.map((item) => {
      if (typeof item === "object" && item.hasOwnProperty("skill")) {
        return item.skill;
      } else {
        return item;
      }
    });

    if (TechSkills.length === 0 || SoftSkills.length === 0) {
      toast.warn("Please fill in all required fields", {
        position: 'top-right',
        autoClose: 2000,
        toastId: customId
      })
      return;
    }


    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      soft_skills: SoftSkills,
      tech_skills: TechSkills,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/edit_skills`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setOpenEditYourSkillsModal(false);
        setAddYourSkills("0");
        setAddYourOtherSkills("");
        ViewAllSkills();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    viewSkillSet();
    ViewAllSkills();
    viewSoftSkillSet();
  }, []);

  const viewSkillSet = () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(
      `${REACT_APP_BASE_API_URL}/view_all_skill_set`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setSkillSetList(result.all_skill_sets);
      })
      .catch((error) => console.error(error));
  };

  const viewSoftSkillSet = () => {
    const requestOptions = {
      method: "POST",
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_soft_skills`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setsoftSkillSetList(result.all_skill_sets);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const [viewTechSkills, setViewTechSkills] = useState([]);
  const [viewSoftSkills, setViewSoftSkills] = useState([]);

  const ViewAllSkills = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_skills`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        const techSkills = JSON.parse(result.skillset[0].tech_skills);
        setViewTechSkills(techSkills);
        setTechSkills(techSkills);
        const softskills = JSON.parse(result.skillset[0].soft_skills);
        setViewSoftSkills(softskills);
        setSoftSkills(softskills);
      })
      .catch((error) => console.error(error));
  };


  const [SameAsPermanentAddress, setSameAsPermanentAddress] = useState(true)

  // Effect to set initial address if SameAsPermanentAddress is true
  useEffect(() => {
    if (SameAsPermanentAddress) {
      setProfileAddress(
        `${ProfileCityName}, ${ProfileStateName}, ${ProfileCountryName}`
      );
    }
  }, [SameAsPermanentAddress, ProfileCountryName, ProfileStateName, ProfileCityName]);

  const handleCheckboxChange = () => {
    setSameAsPermanentAddress((prevSameAsPermanentAddress) => !prevSameAsPermanentAddress)
    if (!SameAsPermanentAddress) {
      setProfileAddress(
        `${ProfileCityName}, ${ProfileStateName},  ${ProfileCountryName} `
      );
    }
    else {
      setProfileAddress("");
    }
  };


  const ViewProfileBasicDetails = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/view_profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAllDataResponse(result.Status)
        setProfileFullName(result.account[0].full_name);
        localStorage.setItem("FullName", result.account[0].full_name)
        setProfileGender(result.profiles[0].gender);
        setProfileEmail(result.account[0].email_id);
        setDOB(result.profiles[0].dob);
        setProfileLanguages(result.profiles[0].language);
        setProfileMobileNumber(result.account[0].mobile_number);
        setProfileAddress(result.profiles[0].permanent_address);
        setProfileCurrentAddress(result.profiles[0].current_address)
        setProfileCountryName(result.profiles[0].current_country);
        setProfileStateName(result.profiles[0].current_state);
        setProfileCityName(result.profiles[0].current_city);
        setProfileMaritalStatus(result.profiles[0].marital_status);
        setProfessionalSummary(result.profiles[0].profile_summary);
        setProfileExperience(result.account[0].experience);
        setProfileDesignation(result.account[0].designation);

        const filteredNoticePeriod = result.notice_period.filter(item => item.notice_period !== "0");
        if (filteredNoticePeriod.length > 0) {
          setProfileNoticePeriod(filteredNoticePeriod[0].notice_period);
        }
      });
  };


  useEffect(() => {
    if (CheckProfileId == "true") {
      setOpenCreateProfileModal(false);
      ViewProfileBasicDetails();
    } else {
      setOpenCreateProfileModal(true);
    }
  }, [OpenCreateProfileModal, OpenEditBasicDetailsModal, OpenAddEducationModal, OpenEditEducationModal, OpenAddExperienceModal, OpenEditExperienceModal, OpenAddYourSkillsModal, OpenEditYourSkillsModal, OpenEditProfessionalSummaryModal]);


  const handlehomepageNavigate = () => {
    navigate('/homePage');
    window.location.reload();
    // navigate(window.location.pathname == '/homePage', {replace: true});
    setOpenCreateProfileModal(false);
  }

  const CreateProfileDetails = () => {
    if (
      ProfileGender == 0 ||
      !ProfileAddress ||
      // !ProfileCurrentAddress ||
      ProfileCityName == 0 ||
      ProfileStateName == 0 ||
      ProfileCityName == 0 ||
      ProfileMaritalStatus == 0 ||
      !ProfileLanguages
    ) {
      toast.warn("Please fill in all required fields", {
        position: "top-right",
        autoClose: 2000,
        toastId: customId,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      gender: ProfileGender,
      full_name: ProfileFullName,
      permanent_address: ProfileAddress,
      current_address: "India",
      dob: DOB.$d,
      current_country: ProfileCountryName,
      current_state: ProfileStateName,
      current_city: ProfileCityName,
      marital_status: ProfileMaritalStatus,
      language: ["Hindi", "English"],
      profile_summary: "Boost your chances of getting hired by adding a professional summary..."
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${REACT_APP_BASE_API_URL}/create_profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status === "1") {
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          localStorage.setItem(
            "CheckProfileId",
            "profile_uuid" in result.profiles[0]
          );
          setOpenCreateProfileModal(false);
        } else {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return;
        }
      });
  };

  const EditProfileDetails = () => {
    if (
      !ProfileFullName ||
      !DOB ||
      ProfileGender == 0 ||
      !ProfileAddress ||
      // !ProfileCurrentAddress ||
      ProfileCountryName == 0 ||
      ProfileStateName == 0 ||
      ProfileCityName == 0 ||
      ProfileMaritalStatus == 0 ||
      !ProfileLanguages
    ) {
      toast.warn("Please fill in all required fields", {
        position: "top-right",
        autoClose: 2000,
        toastId: customId,
      });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_uuid: UserUuid,
      gender: ProfileGender,
      dob: DOB,
      full_name: ProfileFullName,
      permanent_address: ProfileAddress || `${ProfileCountryName} ${ProfileStateName} ${ProfileCityName}`,
      current_address: "India",
      current_city: ProfileCityName,
      current_state: ProfileStateName,
      current_country: ProfileCountryName,
      marital_status: ProfileMaritalStatus,
      language: ["Hindi", "English"],
      profile_summary: "Boost your chances of getting hired by adding a professional summary...",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // console.log(raw, 'rawwwwwwwwwwwwwwwwwwwwwwww');

    fetch(`${REACT_APP_BASE_API_URL}/edit_profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == "1") {
          toast.success(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          setOpenEditBasicDetailsModal(false);
        } else if (result.Status == "0") {
          toast.error(result.Msg, {
            position: "top-right",
            toastId: customId,
            autoClose: 2000,
          });
          return;
        }
      });
  };

  const [languages, setLanguages] = useState([]);

  const handleInputChange = (e) => {
    setProfileLanguages(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && ProfileLanguages.trim() !== "") {
      e.preventDefault(); // Prevent form submission on Enter
      setLanguages([...languages, ProfileLanguages.trim()]);
      setProfileLanguages("");
    }
  };

  const handleDelete = (languageToDelete) => () => {
    setLanguages((languages) =>
      languages.filter((language) => language !== languageToDelete)
    );
  };

  const [showOtherTechSkillInput, setShowOtherTechSkillInput] = useState(false);

  const handleTechSkillsChange = (event, newValue) => {
    const uniqueSkills = Array.from(new Set([...techSkills, ...newValue]));

    // Check if "Other" is selected
    if (newValue.some((item) => item.skill === "Other")) {
      setShowOtherTechSkillInput(true);
    } else {
      setTechSkills(uniqueSkills);
      setShowOtherTechSkillInput(false);
    }
  };

  const handleTechSkillKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      const newSkill = { skill: event.target.value.trim() };
      // console.log(event.target.value, "new skill");
      setTechSkills([...techSkills, newSkill]);
      event.target.value = "";
    }
  };

  // for softskill function start here 

  const handleSoftSkillChange = (event, newValue) => {
    const uniqueSkills = Array.from(new Set([...softSkills, ...newValue]));

    // Check if "Other" is selected
    if (newValue.some((item) => item.skill === "Other")) {
      // setShowOtherSoftSkillInput(true);
    } else {
      setSoftSkills(uniqueSkills);
      // setShowOtherTechSkillInput(false);
    }
  }

  // for displaying bar of profile completed

  const [profileCompletionPercentage, setProfileCompletionPercentage] =
    useState(0);

  useEffect(() => {
    // Define the fields and their respective weightages
    const fields = [
      { key: "ProfileStateName", weight: 1 },
      { key: "ProfessionalSummary", weight: 1 },
      { key: "allEducation", weight: 1 },
      { key: "viewTechSkills", weight: 1 },
      { key: "viewSoftSkills", weight: 1 },
      { key: "GetResume_data", weight: 5 }, // GetResume_data contributes significantly
    ];

    // Calculate total weightage for all fields
    const totalWeightage = fields.reduce(
      (total, field) => total + field.weight,
      0
    );

    // Calculate completed weightage based on filled fields
    const completedWeightage = fields.reduce((total, field) => {
      if (eval(field.key)) {
        // Check if field is filled (assuming fields are boolean or can be evaluated)
        return total + field.weight;
      }
      return total;
    }, 0);

    // Calculate profile completion percentage
    let percentage = (completedWeightage / totalWeightage) * 100;

    // Ensure percentage is capped at 100
    percentage = Math.min(percentage, 100);

    setProfileCompletionPercentage(percentage);
  }, [
    ProfileStateName,
    ProfessionalSummary,
    allEducation,
    viewTechSkills,
    viewSoftSkills,
    GetResume_data, // Ensure GetResume_data is included in the dependency array
  ]);
  localStorage.setItem("ProfileCompletionPercentage", profileCompletionPercentage)



  // Remove duplicates degree from DegreeList
  const uniqueDegreeTitles = Array.from(
    new Set(DegreeList.map((degree) => degree.degree_title))
  );

  const options = uniqueDegreeTitles.concat(['Other']);
  // options.sort((a, b) => a.degree_title.localeCompare(b.degree_title));


  // Disable scroll when loader on
  useEffect(() => {
    if (AllDataResponse !== "1") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // enable scrolling when loader off
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [AllDataResponse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>

      <div style={{ opacity: AllDataResponse === "1" ? "1" : "0.3" }} className="SeekerProfilePage ">
        <h2>
          Profile <span> details</span>{" "}
        </h2>

        <div className="profile">
          <div className="one">
            <div className="one_image">
              {/* <img src={Image} /> */}
              <div
                style={{
                  left: "10px",
                  color: "#333",
                  fontSize: "70px",
                  width: "140px",
                  height: "140px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textTransform: 'uppercase'
                }}
              >
                {ProfileFullName && ProfileFullName.split(' ').map(name => name.charAt(0)).join('')}
              </div>
            </div>
            {/* <div className="one_icons">
              <Delete id="icon" />
              <Edit id="icon" />
            </div> */}
          </div>

          <hr />

          <div className="two">
            <div id="part_1">
              <div>
                <h2>
                  {" "}
                  {ProfileFullName}

                  {ProfileGender === "Male" ? (<BoyOutlined
                    style={{ fontSize: "inherit", color: "#232a58" }}
                  />) : (<GirlOutlined
                    style={{ fontSize: "inherit", color: "pink" }}
                  />)}

                </h2>
                <p>{Designation}</p>
              </div>
              {/* <p>Company Name Pvt. Ltd.ppp</p> */}
            </div>
            <div id="part_2">
              <ul>
                <li>
                  <LocationOn id="icon" />{" "}
                  <p>
                    {" "}
                    {ProfileCityName == "0" ? (
                      "No Address"
                    ) : (
                      <>
                        {ProfileCityName}
                      </>
                    )}
                  </p>
                </li>
                <li>
                  <BusinessCenter id="icon" />
                  <p>
                    <p>
                      {allExperience?.length > 0 ? "Experience" : Experience}
                    </p>
                  </p>
                </li>
                <li>
                  {profileNoticePeriod ? (
                    <>
                      <EventAvailable id="icon" />

                      <p> {profileNoticePeriod} Month Notice Period </p>
                    </>
                  )
                    :
                    (
                      <>
                        <EventAvailable id="icon" />

                        <p> 0 Month Notice Period </p>
                      </>
                    )


                  }
                </li>
              </ul>
              <ul>
                <li>
                  <Phone id="icon" />
                  <p>
                    <span>
                      {ProfileMobileNumber ? (
                        ProfileMobileNumber
                      ) : (
                        <span
                          style={{ color: "#1976D2", cursor: "pointer" }}
                          onClick={() => setOpenEditBasicDetailsModal(true)}
                        >
                          Add Mobile Number
                        </span>
                      )}{" "}
                    </span>{" "}
                  </p>
                </li>

                <li>
                  {" "}
                  <Mail id="icon" />{" "}
                  <p>
                    {" "}
                    <span>
                      {ProfileEmail ? (
                        ProfileEmail
                      ) : (
                        <span
                          style={{ color: "#1976D2", cursor: "pointer" }}
                          onClick={() => setOpenEditBasicDetailsModal(true)}
                        >
                          Add Email Id
                        </span>
                      )}{" "}
                    </span>{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <hr />

          <div className="three">
            <h2>
              Professional Summary
              <CreateRounded
                id="editIcon"
                onClick={() =>
                  setOpenEditProfessionalSummaryModal(true)
                }
              />
            </h2>
            {ProfessionalSummary ? (
              <p>{ProfessionalSummary}</p>
            ) : (
              <p>
                Boost your chances of getting hired by adding a professional
                summary...
              </p>
            )}
          </div>
        </div>

        {/* <div className="ProfileCompletePercentage">
          <h2>
            Profile Completed <span>( 30% )</span>{" "}
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
            }}
          >
            <div style={{ display: "flex", width: "100%", height: "20px" }}>
              <div
                style={{
                  backgroundColor: "#778da9",
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "10px",
                  height: "100%",
                  width: "30%",
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "#e0e1dd",
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "10px",
                  height: "100%",
                  width: "70%",
                }}
              ></div>
            </div>
          </div>
        </div> */}

        <div className="ProfileCompletePercentage">
          <h2>
            Profile Completed{" "}
            <span>({profileCompletionPercentage.toFixed(0)}%)</span>{" "}
          </h2>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "70%",
            }}
          >
            <div style={{ display: "flex", width: "100%", height: "20px", borderRadius: "12px", overflow: 'hidden' }}>
              <div
                style={{
                  backgroundColor: "#176D96",
                  borderTopLeftRadius: "12px",
                  borderBottomLeftRadius: "10px",
                  height: "100%",
                  width: `${profileCompletionPercentage}%`,
                }}
              ></div>
              <div
                style={{
                  backgroundColor: "#e0e1dd",
                  width: `${100 - profileCompletionPercentage}%`,
                  borderTopRightRadius: "12px",
                  borderBottomRightRadius: "10px",
                  height: "100%",
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="ProfileDetails">
          <TabContext value={ProfileSection}>
            <Box>
              <TabList onChange={handleProfileChangeTab} centered>
                <Tab label="Personal Information" value="1" />
                {/* <Tab label="Achievements" value="2" /> */}
                <Tab label="Resume" value="3" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <TabContext value={PersonalInfoSection}>
                  <TabList
                    onChange={handleProfileInfoChangeTab}
                    orientation="vertical"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      width: "16%",
                    }}
                  >
                    <Tab label="Basic Details" value="1" />
                    <Tab label="Education" value="2" />
                    <Tab label="Experience" value="3" />
                    <Tab label="Skills" value="4" />
                  </TabList>

                  <TabPanel
                    value="1"
                    style={{ paddingTop: "0px", width: "100%" }}
                    className="BasicDetails"
                  >
                    <h2>
                      Basic Details{" "}
                      <CreateRounded
                        id="editIcon"
                        onClick={() => setOpenEditBasicDetailsModal(true)}
                      />{" "}
                    </h2>

                    <ul>
                      <li>
                        <p> Name </p>{" "}
                        <span>
                          {ProfileFullName ? (
                            ProfileFullName
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}{" "}
                        </span>{" "}
                      </li>
                      <li>
                        <p> Mobile Number </p>{" "}
                        <span>
                          {ProfileMobileNumber ? (
                            ProfileMobileNumber
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}{" "}
                        </span>{" "}
                      </li>
                      <li>
                        <p> Email Id </p>{" "}
                        <span>
                          {ProfileEmail ? (
                            ProfileEmail
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}{" "}
                        </span>
                      </li>
                      <li>
                        <p>Permanent Address </p>{" "}
                        <span>
                          {ProfileAddress ? (
                            ProfileAddress
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}
                        </span>
                      </li>
                      <li>
                        <p> Gender </p>{" "}
                        <span>
                          {" "}
                          {ProfileGender !== "0" ? (
                            ProfileGender
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}{" "}
                        </span>
                      </li>
                      <li>
                        <p> Marital Status </p>{" "}
                        <span>
                          {" "}
                          {ProfileMaritalStatus !== "0" ? (
                            ProfileMaritalStatus
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}{" "}
                        </span>
                      </li>
                      <li>
                        <p> Date of Birth </p>
                        <span>
                          {DOB ? (
                            convertDateFormat(DOB)
                          ) : (
                            <span
                              style={{ color: "#1976D2", cursor: "pointer" }}
                              onClick={() => setOpenEditBasicDetailsModal(true)}
                            >
                              Add
                            </span>
                          )}{" "}
                        </span>{" "}
                      </li>
                      <li>
                        {/* <p> Languages </p> */}
                        {/* <div style={{ marginTop: "7px" }}> */}
                        {/* {
                                                        ProfileLanguages ? (
                                                            ProfileLanguages.map((language, index) => (
                                                                <span key={index} id='language'>{language}</span>
                                                            ))
                                                           
                                                        ) : (
                                                            <span style={{ color: '#1976D2', cursor: 'pointer' }} onClick={() => setOpenEditBasicDetailsModal(true)}>Add</span>
                                                        )
                                                    } */}
                        {/* </div> */}
                      </li>
                      <li>
                        <p>Current Address </p>
                        {ProfileStateName !== "0" &&
                          ProfileCountryName !== "0" &&
                          ProfileCityName !== "0" ? (
                          <>

                            <span>
                              {ProfileCityName !== "0" ? (
                                ProfileCityName
                              ) : (
                                <span
                                  style={{
                                    color: "#1976D2",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setOpenEditBasicDetailsModal(true)
                                  }
                                >
                                  Add City
                                </span>
                              )}{", "}
                              {ProfileStateName ? (
                                ProfileStateName
                              ) : (
                                <span
                                  style={{
                                    color: "#1976D2",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    setOpenEditBasicDetailsModal(true)
                                  }
                                >
                                  Add State
                                </span>
                              )}{", "}

                              {ProfileCountryName && ProfileCountryName}

                            </span>


                          </>
                        ) : (
                          <span
                            style={{ color: "#1976D2", cursor: "pointer" }}
                            onClick={() => setOpenEditBasicDetailsModal(true)}
                          >
                            Add Address
                          </span>
                        )}
                      </li>
                    </ul>

                    <div style={{ marginTop: "20px" }}>
                      <h2>
                        Professional Summary{" "}
                        <CreateRounded
                          id="editIcon"
                          onClick={() =>
                            setOpenEditProfessionalSummaryModal(true)
                          }
                        />{" "}
                      </h2>
                      <p>
                        {ProfessionalSummary
                          ? ProfessionalSummary
                          : <p>
                            Boost your chances of getting hired by adding a professional
                            summary...
                          </p>}
                      </p>
                    </div>
                  </TabPanel>

                  <TabPanel
                    value="2"
                    style={{ paddingTop: "0px", width: "100%" }}
                    className="BasicDetails"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>Education Details </h2>
                      {/* <h2
                        style={{ cursor: "pointer" }}
                        onClick={() => setOpenAddEducationModal(true)}
                      >
                        <Add id="editIcon" /> Add New Education
                      </h2> */}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "30px",
                        gap: "20px",
                      }}
                    >
                      <div>
                        <h2
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenAddEducationModal(true)}
                        >
                          <Add id="editIcon" /> Add New Education
                        </h2>
                      </div>
                      {sortedEducation.length > 0 ? (
                        sortedEducation.map((data) => (
                          <div key={data.id}>
                            <h2>
                              {data.education_type === "10"
                                ? "10th"
                                : data.education_type === "12"
                                  ? "12th"
                                  : data.education_type}
                              <CreateRounded
                                id="editIcon"
                                //   onClick={() => setOpenEditEducationModal(true)}
                                onClick={() =>
                                  viewParticularEducation(data.education_uuid)
                                }
                              />
                            </h2>

                            <p>{data.degree_board}</p>
                            <p style={{ color: "#444445" }}>
                              {data.name_of_institute}, {data.specialization} ,{" "}
                              {data.perecentage}%
                            </p>
                            <p style={{ color: "grey" }}>
                              {data.start_year}-{data.end_year}
                            </p>
                          </div>
                        ))
                      ) : (
                        <div>No Education Record Found</div>
                      )}
                    </div>
                  </TabPanel>

                  <TabPanel
                    value="3"
                    style={{ paddingTop: "0px", width: "100%" }}
                    className="BasicDetails"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h2>Experience Details </h2>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "30px",
                        gap: "30px",
                      }}
                    >
                      <div>
                        <h2
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenAddExperienceModal(true)}
                        >
                          <Add id="editIcon" /> Add New Experience
                        </h2>

                      </div>
                      {allExperience ? (
                        allExperience.map((data) => (
                          <div key={data.id}>
                            <h2>
                              <BusinessCenterRounded id="editIcon" />{" "}
                              {data.job_title}
                              <CreateRounded
                                id="editIcon"
                                onClick={
                                  () =>
                                    viewParticularExperience(
                                      data.experience_uuid
                                    )
                                  // setOpenEditExperienceModal(true)
                                }
                              />
                              {/* <DeleteIcon id="DeleteIcon" style={{color: 'red' ,cursor: 'pointer'}} /> */}
                            </h2>
                            <p>{data.company_name}. {" "}
                              {data.employment_type == "FullTime"
                                ? "Full Time"
                                : "Internship"}{" "}
                            </p>
                            <p style={{ color: "#444445" }}>
                              {data.company_location}
                            </p>
                            <p style={{ color: "#444445" }}>
                              {data.joining_month} {data.joining_year} -{" "}
                              {data.currently_working == "true"
                                ? "Till Date"
                                : data.end_month + " " + data.end_year}{" "}

                            </p>

                            <p style={{ color: "grey" }}>
                              {JSON.parse(data.skills).join(", ")}
                            </p>
                            {
                              <p style={{ color: "#444445" }}>
                                {data.currently_working == "true" && (data.notice_period + " Month Notice Period")}
                              </p>
                            }

                            {/* <p style={{ color: "#444445" }}>
                              {data.job_description}
                            </p> */}
                          </div>
                        ))
                      ) : (
                        <div>No Experience Found</div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel
                    value="4"
                    style={{ paddingTop: "0px", width: "100%" }}
                    className="BasicDetails"
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h2>
                        Skills
                        {viewTechSkills.length != "0" &&
                          viewSoftSkills.length != "0" && (
                            <CreateRounded
                              id="editIcon"
                              onClick={() => {
                                setOpenEditYourSkillsModal(
                                  !OpenEditYourSkillsModal
                                );
                                ViewAllSkills();
                              }}
                            />
                          )}
                      </h2>
                      {/* <p>
                        Please add your Tech skills and Soft skills as you want{" "}
                      </p> */}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "20px   ",
                      }}
                    >
                      {viewTechSkills.length > 0 &&
                        viewSoftSkills.length > 0 ? (
                        <>
                          <h2>Tech Skills </h2>

                          <Box
                            mt={3}
                            mb={3}
                            sx={{
                              "& > :not(:last-child)": { marginRight: 1 },
                              "& > *": { marginBottom: 1 },
                            }}
                          >
                            {viewTechSkills.map((v) => (
                              <Chip key={v} label={v} />
                            ))}
                          </Box>

                          <h2>Soft Skills </h2>

                          <Box
                            mt={3}
                            mb={3}
                            sx={{
                              "& > :not(:last-child)": { marginRight: 1 },
                              "& > *": { marginBottom: 1 },
                            }}
                          >
                            {viewSoftSkills.map((v) => (
                              <Chip key={v} label={v} />
                            ))}
                          </Box>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{
                              fontSize: "16px",
                              padding: "3px 10px",
                              color: "blue",
                              border: "1px solid blue",
                              borderRadius: "15px",
                              // marginTop: "4px",
                              width: "140px",
                              maxWidth: "120px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              // textOverflow: "ellipsis",
                              display: "inline-block",
                              fontFamily: "inherit",
                            }}
                            onClick={() =>
                              setOpenAddYourSkillsModal(!OpenAddYourSkillsModal)
                            }
                          >
                            <span
                              style={{
                                fontSize: "19px",
                                verticalAlign: "middle",
                              }}
                            >
                              +
                            </span>
                            Add Skill
                          </Button>
                        </>
                      )}
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </TabPanel>

            {/* <TabPanel value="2" className='tabPanel' id='tabPanelOne' >
                            This is 2
                        </TabPanel> */}

            <TabPanel value="3" className="tabPanel" id="tabPanelOne">
              <Box
                sx={{
                  flexGrow: 1,
                  bgcolor: "background.paper",
                  display: "flex",
                }}
              >
                <TabContext value={ResumeInfoSection}>
                  <TabList
                    onChange={handleResumeInfoChangeTab}
                    orientation="vertical"
                    sx={{
                      borderRight: 1,
                      borderColor: "divider",
                      width: "16%",
                    }}
                  >
                    <Tab label="Resume Section" value="1" />
                  </TabList>

                  <TabPanel
                    value="1"
                    style={{ paddingTop: "0px", width: "100%" }}
                    className="BasicDetails"
                  >
                    <SimpleCard>
                      <h2>Resume</h2>
                      <div className="main_box">
                        <div className="box_1">
                          {
                            ResumeName ?
                              <p style={{ padding: "10px" }}>
                                This is your current resume , click on it it to view.
                              </p> :
                              <p style={{ padding: "10px" }}>
                                Please upload your latest resume.
                              </p>
                          }

                          <div>
                            {ResumeName ?
                              <Button
                                className="Button_1"
                                variant="outlined"
                                style={{
                                  marginRight: "12px",
                                  display: "block",
                                }}
                                onClick={viewResume}
                              >
                                <Tooltip title="View" placement="top">
                                  {ResumeName}
                                </Tooltip>
                              </Button>
                              :
                              ShowResumeLoader &&
                              <div style={{ marginRight: '60px' }} >
                                <Bars
                                  height="30"
                                  width="30"
                                  color="#9c88ff"
                                  ariaLabel="bars-loading"
                                  visible={true}
                                />
                              </div>
                            }
                          </div>
                        </div>

                        <div className="box_2">
                          {ResumeName === "" ? (
                            <Button
                              className="Button_1"
                              variant="outlined"
                              style={{ marginRight: "12px", display: "block" }}
                              onClick={triggerFileInput}
                            >
                              Upload Resume
                            </Button>
                          ) : (
                            <Button
                              className="Button_1"
                              variant="contained"
                              style={{ marginRight: "12px", display: "block" }}
                              onClick={triggerFileInput}
                            >
                              Update Resume
                            </Button>
                          )}
                          <p style={{ marginTop: "10px" }}>
                            Select Resume in PDF Format only .
                          </p>


                          <input
                            type="file"
                            id="resume-input"
                            name="resume"
                            accept=".pdf"
                            onChange={handleFileChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </SimpleCard>
                  </TabPanel>
                </TabContext>
              </Box>
            </TabPanel>
          </TabContext>
        </div>
      </div>

      {AllDataResponse !== "1" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            position: "fixed",
            zIndex: '100',
            top: 0,
            overflow: 'hidden'
          }}
        >
          <Bars
            height="35"
            width="35"
            color="#9c88ff"
            ariaLabel="bars-loading"
            visible={true}
          />
        </div>
      )}

      {/* Modal for opening and editing the Prefessional Summary part  */}
      <Modal open={OpenEditProfessionalSummaryModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div
            style={{
              width: "100%",
            }}
          >
            <H2
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "400",
                margin: "5px 0px",
              }}
            >
              About Section
            </H2>

            <CloseIcon
              onClick={() => {
                setOpenEditProfessionalSummaryModal(false)
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                top: '7',
                color: "black",
                fontSize: "35px",
                // border: '2px solid red'
              }}
            />

            <p style={{ color: 'grey' }} >
              This is the place to showcase your experience and skills. Write a concise overview to demonstrate your accomplishments. A strong summary can highlight why you are a perfect fit for the job. Use this space to advocate for yourself effectively.
            </p>
            <div
              className="inputInfoBox "
              style={{ width: "100%", marginBottom: "20px" }}
            >
              <OutlinedInput
                // autoFocus={true}
                multiline
                rows={8} // Adjust the number of rows as needed
                type="text"
                value={ProfessionalSummary}
                // onChange={(e) => setProfessionalSummary(e.target.value)}
                onChange={handleProfessionalSummaryInput}
                style={{ width: "100%", marginTop: '20px' }}
                placeholder="Write here"
              />
            </div>

            <div>
              <Button
                onClick={() => {
                  SaveProfessionalSummary();
                }}
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setOpenEditProfessionalSummaryModal(false);
                  ViewProfileBasicDetails();
                }}
                className="Button_2"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for creating the basic details or part in Personal Information tab  */}
      <Modal open={OpenCreateProfileModal}
      // onClose={() => {
      //   setOpenCreateProfileModal(false);
      // }}
      >
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "20px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Complete your basic profile details.
            </H2>
            {/* <CloseIcon
              onClick={handlehomepageNavigate}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            /> */}

            <p style={{ color: "grey" }}>
              {" "}
              Here are the essential details that will enable the company to
              connect with you and understand more about you.
            </p>

            <div id="Type1">

              <h3>Name <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please enter your full name here.</p>
              <input
                type="text"
                tabIndex={1}
                placeholder="Edit your name"
                value={ProfileFullName}
                onChange={(e) => setProfileFullName(e.target.value)}
              // disabled
              // onChange={(e) => setProfileFullName(e.target.value)}
              />
            </div>

            <div id="Type1">

              <h3>Gender <span style={{ color: 'red' }}>*</span></h3>
              <p>Please select your gender.</p>
              {/* <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ tabIndex: "2" }}
                  value={ProfileGender}
                  onChange={(e) => setProfileGender(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <MenuItem value="0" disabled>
                    --Select Gender--
                  </MenuItem>
                  <MenuItem value="Male">Male </MenuItem>
                  <MenuItem value="Female">Female </MenuItem>
                  <MenuItem value="Other"> Other </MenuItem>
                </Select>
              </FormControl> */}

              <div className="SelectElement" >
                <select value={ProfileGender}
                  tabIndex="2"
                  onChange={(e) => setProfileGender(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <option value="0" disabled> -- Select Gender --</option>
                  <option value="Male"> Male </option>
                  <option value="Female"> Female </option>
                  <option value="Other"> Other </option>
                </select>
              </div>
            </div>

            <div id="Type1">
              <h3>Date of Birth <span style={{ color: 'red' }}>*</span> </h3>
              <p style={{ marginBottom: "0px" }}>
                Please select your Date of Birth.
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker", "DatePicker"]}
                  style={{ border: "3px blue solid" }}
                  tabIndex={3}
                >
                  <DatePicker
                    value={dayjs(DOB)}
                    onChange={(DOB) => setDOB(DOB)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            {/* <div id="Type1">
              <h3>Languages </h3>
              <p>Please provide the languages you can communicate in.</p>
              <input
                type="text"
                placeholder="Enter the languages"
                value={ProfileLanguages}
                onChange={(e) => setProfileLanguages(e.target.value)}
              />
            </div> */}

            <div id="Type1" style={{ margin: "0px" }}>
              <h3>Current Address <span style={{ color: 'red' }}>*</span> </h3>
              <>
                <p>Please Select your country</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "5" }}
                    value={ProfileCountryName}
                    onChange={(e) => setProfileCountryName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      --Select Your Country--
                    </MenuItem>
                    {updatedCountries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other"> Other </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={ProfileCountryName}
                    tabIndex="5"
                    onChange={(e) => setProfileCountryName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Your Country-- </option>
                    {updatedCountries.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}  </option>))}
                    <option value="Other"> Other </option>
                  </select>
                </div>
              </>
              <br />
              <>
                <p>Please Select your State </p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "6" }}
                    value={ProfileStateName}
                    onChange={(e) => setProfileStateName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      --Select Your State--
                    </MenuItem>
                    {AllStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other"> Other </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={ProfileStateName}
                    tabIndex="6"
                    onChange={(e) => setProfileStateName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Your State-- </option>
                    {AllStates.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}  </option>))}
                    <option value="Other"> Other </option>
                  </select>
                </div>
              </>
              <br />
              <>
                <p>Please Select your City</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "7" }}
                    value={ProfileCityName}
                    onChange={(e) => setProfileCityName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      --Select Your City--
                    </MenuItem>
                    {AllCities.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other"> Other </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={ProfileCityName}
                    tabIndex="7"
                    onChange={(e) => setProfileCityName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Your State-- </option>
                    {AllCities.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}  </option>))}
                    <option value="Other"> Other </option>
                  </select>
                </div>
              </>
            </div>

            <div id="Type2">
              <input
                type="checkbox"
                id="CurrentAddress"
                value={SameAsPermanentAddress}
                checked={SameAsPermanentAddress}
                onChange={handleCheckboxChange}
              // onChange={() => setSameAsPermanentAddress(!SameAsPermanentAddress)}
              />
              <label htmlFor="CurrentAddress">
                <h3> Keep Permanent Address same as Current Address </h3>
              </label>
            </div>

            {
              !SameAsPermanentAddress &&

              <div id="Type1">
                <h3>Permanent Address <span style={{ color: 'red' }}>*</span></h3>
                <p>Please enter your permanent residence address.</p>
                <input
                  type="text"
                  placeholder="Enter your address"
                  value={ProfileAddress}
                  onChange={(e) => setProfileAddress(e.target.value)}

                  tabIndex={"4"}
                />
              </div>

            }

            <div id="Type1">
              <h3>Marital Status <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please select your Marital Status.</p>
              {/* <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ tabIndex: "" }}
                  value={ProfileMaritalStatus}
                  onChange={(e) => setProfileMaritalStatus(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <MenuItem value="0" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="Single">Single </MenuItem>
                  <MenuItem value="Married">Married </MenuItem>
                </Select>
              </FormControl> */}
              <div className="SelectElement" >
                <select value={ProfileMaritalStatus}
                  tabIndex="8"
                  onChange={(e) => setProfileMaritalStatus(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <option value="0" disabled> --Select Status-- </option>

                  <option value="Single"> Single </option>
                  <option value="Married"> Married </option>
                </select>
              </div>
            </div>

            <div>
              <Button
                onClick={CreateProfileDetails}
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
                tabIndex={"9"}
              >
                Create
              </Button>
              {/* <Button
                // onClick={() => {
                //   navigate("/homePage");
                //   // window.location.href = "/homePage";
                //   // setOpenCreateProfileModal(false);
                // }} 
                onClick={handlehomepageNavigate}
                className="Button_2"
                variant="outlined"
                color="inherit"
                tabIndex={"10"}
              >
                Cancel
              </Button> */}
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for opening and editing the basic details part in Personal Information tab  */}
      <Modal open={OpenEditBasicDetailsModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "20px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Basic Details
            </H2>

            <CloseIcon
              onClick={() => {
                setOpenEditBasicDetailsModal(false)
                ViewProfileBasicDetails();
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            />

            <p style={{ color: "grey" }}>
              {" "}
              Here are the essential details that will enable the company to
              connect with you and understand more about you.
            </p>

            <div id="Type1">
              <h3>Name <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please enter your full name here.</p>
              <input
                type="text"
                placeholder="Edit your name"
                value={ProfileFullName}
                tabIndex={"1"}
                style={{ border: ProfileFullName === "" ? '1px solid red' : '1px solid #ccc' }}
                onChange={(e) => setProfileFullName(e.target.value)}
              />
            </div>

            <div id="Type1">
              <h3>Email Address</h3>
              <div style={{ display: "flex", padding: "0px" }}>
                <ContactMailRounded
                  style={{ color: "#232a58", marginRight: "8px" }}
                />
                <h2>{EmailId}</h2>
              </div>

              {/* <Accordion style={{ boxShadow: "0px 0px 0px white" }}>
                <AccordionSummary id="panel1-header">
                  <p>
                    You will be contacted via this email address
                    <span>Change Email Id.</span>
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3>Enter ID to get OTP </h3>
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      style={{ width: "60%" }}
                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Get OTP
                    </Button>
                  </div>

                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                      margin: "0px",
                    }}
                  >
                    <h3>Verify OTP recieved</h3>
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      style={{ width: "60%" }}
                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Submit
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion> */}
            </div>

            <div id="Type1">
              <h3>Gender <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please select your gender.</p>
              {/* <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ tabIndex: "2" }}
                  value={ProfileGender}
                  onChange={(e) => setProfileGender(e.target.value)}
                  // onChange={(e) => SetAccountType(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <MenuItem value="0" disabled>
                    --Select Gender--
                  </MenuItem>
                  <MenuItem value="Male">Male </MenuItem>
                  <MenuItem value="Female">Female </MenuItem>
                  <MenuItem value="Other"> Other </MenuItem>
                </Select>
              </FormControl> */}

              <div className="SelectElement" >
                <select value={ProfileGender}
                  tabIndex="2"
                  onChange={(e) => setProfileGender(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <option value="0" disabled> -- Select Gender --</option>
                  <option value="Male"> Male </option>
                  <option value="Female"> Female </option>
                  <option value="Other"> Other </option>
                </select>
              </div>
            </div>

            <div id="Type1">
              <h3>Date of Birth <span style={{ color: 'red' }}>*</span> </h3>
              <p style={{ marginBottom: "0px" }}>
                Please select your Date of Birth.
              </p>




              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["DatePicker", "DatePicker"]}
                  style={{ border: "3px blue solid" }}
                  tabIndex={3}
                >
                  <DatePicker
                    value={dayjs(DOB)}
                    onChange={(newDOB) => setDOB(newDOB)}
                    tabIndex="3"
                    style={{ border: '2px solid red' }}

                  />
                </DemoContainer>
              </LocalizationProvider>

            </div>

            {/* <div id="Type1">
              <h3>Languages </h3>
              <p>Please provide the languages you can communicate in.</p>
              <input
                type="text"
                placeholder="Enter the languages ex. Hindi "
                value={ProfileLanguages}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              <div>
                {languages.map((language, index) => (
                  <Chip
                    key={index}
                    label={language}
                    onDelete={handleDelete(language)}
                    sx={{ margin: "2px" }}
                  />
                ))}
              </div>
            </div> */}

            <div id="Type1">
              <h3>Mobile Number </h3>
              <div style={{ display: "flex" }}>
                <Call style={{ color: "#232a58", marginRight: "6px" }} />{" "}
                <h2>{MobileNumber}</h2>
              </div>

              {/* <Accordion style={{ boxShadow: "0px 0px 0px white" }}>
                <AccordionSummary id="panel1-header">
                  <p>
                    You will be contacted via this mobile number
                    <span>Change Mobile Number.</span>
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3>Enter number to get OTP </h3>
                    <input
                      type="number"
                      placeholder="Enter Mobile Number"
                      style={{ width: "60%" }}

                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Get OTP
                    </Button>
                  </div>

                  <div
                    id="Type1"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <h3>Verify OTP recieved</h3>
                    <input
                      type="number"
                      placeholder="Enter OTP"
                      style={{ width: "60%" }}
                    />
                    <Button
                      className="Button_2"
                      variant="outlined"
                      color="inherit"
                    >
                      Submit
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion> */}
            </div>


            {/* <div id="Type1">
              <h3>Current Address </h3>
              <p>Please enter your Current Address.</p>
              <input
                type="text"
                placeholder="Enter your address"
                value={ProfileCurrentAddress}
                onChange={(e) => setProfileCurrentAddress(e.target.value)}
                tabIndex={"4"}
              />
            </div> */}


            <div id="Type1" style={{ margin: "0px" }}>
              <h3>Current Address <span style={{ color: 'red' }}>*</span> </h3>
              <>
                <p>Please Select your country </p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "5" }}
                    value={ProfileCountryName}
                    onChange={(e) => setProfileCountryName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      --Select Your Country--
                    </MenuItem>
                    {updatedCountries.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other"> Other </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={ProfileCountryName}
                    tabIndex="5"
                    onChange={(e) => setProfileCountryName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Your Country-- </option>
                    {updatedCountries.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}  </option>))}
                    <option value="Other"> Other </option>
                  </select>
                </div>
              </>
              <br />
              <>
                <p>Please Select your State </p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "6" }}
                    value={ProfileStateName}
                    onChange={(e) => setProfileStateName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      --Select Your State--
                    </MenuItem>
                    {AllStates.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other"> Other </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={ProfileStateName}
                    tabIndex="6"
                    onChange={(e) => setProfileStateName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Your State-- </option>
                    {AllStates.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}  </option>))}
                    <option value="Other"> Other </option>
                  </select>
                </div>
              </>
              <br />
              <>
                <p>Please Select your City </p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "7" }}
                    value={ProfileCityName}
                    onChange={(e) => setProfileCityName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      --Select Your City--
                    </MenuItem>
                    {AllCities.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other"> Other </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={ProfileCityName}
                    tabIndex="7"
                    onChange={(e) => setProfileCityName(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Your State-- </option>
                    {AllCities.map((option) => (
                      <option key={option.value} value={option.value}>{option.label}  </option>))}
                    <option value="Other"> Other </option>
                  </select>
                </div>
              </>
            </div>

            <div id="Type2">
              <input
                type="checkbox"
                id="CurrentAddress"
                value={SameAsPermanentAddress}
                checked={SameAsPermanentAddress}
                onChange={handleCheckboxChange}
              // onChange={() => setSameAsPermanentAddress(!SameAsPermanentAddress)}
              />
              <label htmlFor="CurrentAddress">
                <h3> Keep Permanent Address same as Current Address </h3>
              </label>
            </div>

            {
              !SameAsPermanentAddress &&

              <div id="Type1">
                <h3>Permanent Address <span style={{ color: 'red' }}>*</span></h3>
                <p>Please enter your permanent residence address.</p>
                <input
                  type="text"
                  placeholder="Enter your address"
                  value={ProfileAddress}
                  onChange={(e) => setProfileAddress(e.target.value)}

                  tabIndex={"4"}
                />
              </div>

            }

            <div id="Type1">
              <h3>Marital Status <span style={{ color: 'red' }}>*</span></h3>
              <p>Please select your Marital Status.</p>
              {/* <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ tabIndex: "8" }}
                  value={ProfileMaritalStatus}
                  onChange={(e) => setProfileMaritalStatus(e.target.value)}
                  // onChange={(e) => SetAccountType(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <MenuItem value="0" disabled>
                    Select Status
                  </MenuItem>
                  <MenuItem value="Single">Single </MenuItem>
                  <MenuItem value="Married">Married </MenuItem>
                  <MenuItem value="other"> Other</MenuItem>
                </Select>
              </FormControl> */}
              <div className="SelectElement" >
                <select value={ProfileMaritalStatus}
                  tabIndex="8"
                  onChange={(e) => setProfileMaritalStatus(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <option value="0" disabled> --Select Status-- </option>

                  <option value="Single"> Single </option>
                  <option value="Married"> Married </option>
                </select>
              </div>
            </div>

            <div>
              <Button
                onClick={EditProfileDetails}
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
                tabIndex={"9"}
              >
                Update
              </Button>
              <Button
                onClick={() => {
                  setOpenEditBasicDetailsModal(false);
                  ViewProfileBasicDetails();
                  // setProfileFullName("");
                  // setDOB("");
                  // setProfileGender("");
                  // setProfileAddress("");
                  // setProfileCurrentAddress("");
                  // setProfileCountryName("");
                  // setProfileStateName("");
                  // setProfileCityName("");
                  // setProfileMaritalStatus("");

                }}
                className="Button_2"
                variant="outlined"
                color="inherit"
                tabIndex={"10"}

              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for adding the education details */}
      <Modal open={OpenAddEducationModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Education Details
            </H2>
            <CloseIcon
              onClick={() => {
                setOpenAddEducationModal(false)

              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            />

            <p style={{ color: "grey" }}>
              Providing details such as your course of study, university
              attended, and other relevant information helps recruiters
              understand your educational background.
            </p>

            <div id="Type1">
              <h3>Education <span style={{ color: 'red' }}>*</span></h3>
              <p>Please select your Education Type.</p>
              {/* <FormControl style={{ width: "100%" }}>
                <Select
                  inputProps={{ tabIndex: "1" }}
                  value={AddEducationType}

                  onChange={(e) => setAddEducationType(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <MenuItem value="0" disabled>
                    -- Select Education --
                  </MenuItem>
                  <MenuItem value="10">10th </MenuItem>
                  <MenuItem value="12">12th </MenuItem>
                  <MenuItem value="Graduation"> Graduation / Diploma </MenuItem>
                  <MenuItem value="Masters">
                    Masters / Post - Graduation
                  </MenuItem>
                  <MenuItem value="Phd">Doctorate / Phd </MenuItem>
                </Select>
              </FormControl> */}

              <div className="SelectElement" >
                <select value={AddEducationType}
                  tabIndex="1"
                  onChange={(e) => setAddEducationType(e.target.value)}
                  style={{ width: "100%", height: "42px", color: "gray" }}
                >
                  <option value="0" disabled> --Select Education-- </option>

                  <option value="10"> 10th </option>
                  <option value="12"> 12th </option>
                  <option value="Graduation"> Graduation / Diploma </option>
                  <option value="Masters"> Masters / Post - Graduation </option>
                  <option value="Phd"> Doctorate / Phd </option>
                </select>
              </div>
            </div>

            {AddEducationType == "10" || AddEducationType == "12" ? (
              <div id="Type1">
                <h3>School Name<span style={{ color: 'red' }}>*</span> </h3>
                <p>Please enter your school name here.</p>
                <input
                  type="text"
                  placeholder="Enter school name"
                  value={AddSchoolName}
                  onChange={(e) => setAddSchoolName(e.target.value)}
                  tabIndex={"2"}
                />
              </div>
            ) : (
              <>
                {/* Add Exp  */}
                <div id="Type1">
                  <h3>College / University Name <span style={{ color: 'red' }}>*</span> </h3>
                  <p>Please enter your University / College name here.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "5" }}
                      value={AddCollegeName}
                      onChange={(e) => setAddCollegeName(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "#666666",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select College/ University --{" "}
                      </MenuItem>
                      <MenuItem value="IIT"> IIT </MenuItem>

                  
                      <MenuItem value="other"> Other </MenuItem>
                    </Select>
                  </FormControl> */}

                  <input
                    type="text"
                    placeholder="Enter College name"
                    value={AddCollegeName}
                    // onChange={(e) => setAddOtherCollegeName(e.target.value)}
                    onChange={(e) => setAddCollegeName(e.target.value)}
                    tabIndex={"2"}
                  />
                </div>

                {AddCollegeName == "other" && (
                  <div id="Type1">
                    <p>Please specify other College name.</p>
                    <input
                      type="text"
                      placeholder="Enter College name"
                      value={AddOtherCollegeName}
                      // onChange={(e) => setAddOtherCollegeName(e.target.value)}
                      onChange={handleOtherCollegeName}
                    />
                  </div>
                )}
              </>
            )}

            {AddEducationType == "10" || AddEducationType == "12" ? (
              <div id="Type1">
                <h3>School Board Name <span style={{ color: 'red' }}>*</span> </h3>
                <p>
                  Please enter the education board to which your school was
                  affilated to.
                </p>
                <input
                  type="text"
                  placeholder="Enter name eg: CBSE or State Board"
                  value={AddSchoolBoard}
                  onChange={(e) => setAddSchoolBoard(e.target.value)}
                  tabIndex={"3"}
                />
              </div>
            ) : (
              <>
                <div id="Type1"  >
                  <h3>Degree <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please choose your college degree program.</p>
                  <div style={{ width: "100%" }}>
                    <Autocomplete
                      value={AddDegree}
                      onChange={(event, newValue) => setAddDegree(newValue)}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) =>
                        setInputValue(newInputValue)
                      }

                      disablePortal
                      tabIndex={"4"}
                      // options={DegreeList.map(
                      //   (degree) =>  degree.degree_title
                      // ).concat(["Other"])}
                      options={options}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Degree Program"
                          variant="outlined"
                          fullWidth

                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem
                          {...props}
                          key={option}
                          value={option}
                          disabled={option === "0"}
                        >
                          {option}
                        </MenuItem>
                      )}
                      getOptionLabel={(option) => option}
                    />
                  </div>
                </div>

                {AddDegree == "other" && (
                  <div id="Type1">
                    <p>Please specify other degree program.</p>
                    <input
                      type="text"
                      placeholder="Enter degree name here"
                      value={AddOtherDegree}
                      // onChange={(e) => setAddOtherDegree(e.target.value)}
                      onChange={handleAddotherDegree}
                    />
                  </div>
                )}
              </>
            )}

            {AddEducationType == "10" || AddEducationType == "12" ? (
              AddEducationType == "10" ? (
                <div id="Type1">
                  <h3>Specilazation </h3>
                  <p>Enter the Specilazation here</p>
                  <input
                    type="text"
                    placeholder="Enter specialization"
                    value={AddSchoolSpecilization}
                    // onChange={(e) => setAddSchoolSpecilization(e.target.value)}
                    onChange={handleSchoolSpecilization}
                    tabIndex={"5"}
                  />
                </div>
              ) : (
                <div id="Type1">
                  <h3>Specilazation </h3>
                  <p>
                    Please specify the specilization like Non-Medical , Medical
                    , etc.
                  </p>
                  <input
                    type="text"
                    placeholder="Enter specialization"
                    value={AddSchoolSpecilization}
                    // onChange={(e) => setAddSchoolSpecilization(e.target.value)}
                    onChange={handleSchoolSpecilization}
                    tabIndex={"5"}
                  />
                </div>
              )
            ) : (
              <div id="Type1">
                <h3>Specialization <span style={{ color: 'red' }}>*</span></h3>
                <p>Please specify degree specialization.</p>
                <input
                  type="text"
                  placeholder="Enter specialization here"
                  value={AddCollegeSpecilization}
                  // onChange={(e) => setAddCollegeSpecilization(e.target.value)}
                  onChange={handleCollegeSpecilization}
                  tabIndex={"5"}
                />
              </div>
            )}

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "46% 46%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div id="Type1">
                <h3>Start Year <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select the start year.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "6" }}
                    value={AddStartYear}
                    onChange={(e) => setAddStartYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select start year --{" "}
                    </MenuItem>
                    {yearsArray.map((year, index) => {
                      return (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddStartYear}
                    tabIndex="6"
                    onChange={(e) => setAddStartYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select start year-- </option>
                    {yearsArray.map((year, index) => {
                      return (
                        <option key={index} value={year}> {year} </option>);
                    })}
                  </select>
                </div>
              </div>
              <div id="Type1">
                <h3>End Year <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select the end year.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "7" }}
                    value={AddEndYear}
                    onChange={(e) => setAddEndYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select end year --{" "}
                    </MenuItem>
                    {yearsArray.map((year, index) => {
                      return (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}

                <div className="SelectElement" >
                  <select value={AddEndYear}
                    tabIndex="7"
                    onChange={(e) => setAddEndYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select end year-- </option>
                    {yearsArray.map((year, index) => {
                      return (
                        <option key={index} value={year}> {year} </option>);
                    })}
                  </select>
                </div>

              </div>
            </div>

            <div id="Type1">
              <h3>Percentage Obtained <span style={{ color: 'red' }}>*</span></h3>
              <p>Please specify percentage obtained.</p>
              <input
                type="text"
                placeholder="Enter percentage here."
                value={AddPercentage}
                // onChange={(e) => setAddPercentage(e.target.value)}
                onChange={handlePercentage}
                tabIndex={"8"}
              />
            </div>

            <div>
              <Button
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
                onClick={addEducation}
                tabIndex={"9"}
              >
                Add Education
              </Button>
              <Button
                onClick={() => {
                  setOpenAddEducationModal(false);
                  setAddEducationType("0");
                  setAddSchoolBoard("");
                  setAddCollegeName("");
                  setAddOtherCollegeName("");
                  setAddSchoolBoard("");
                  setAddDegree("");
                  setAddOtherDegree("");
                  setAddSchoolSpecilization("");
                  setAddCollegeSpecilization("");
                  setAddStartYear("0");
                  setAddEndYear("0");
                  setAddPercentage("");
                }}
                tabIndex={"10"}
                className="Button_2"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for Editing the education details */}
      <Modal open={OpenEditEducationModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          {particularEducation.map((data) => (
            <div className="ModalInputs">
              <H2
                style={{
                  fontSize: "22px",
                  width: "100%",
                  fontWeight: "400",
                  margin: "10px 0px",
                }}
              >
                Edit Education Details
              </H2>
              <CloseIcon
                onClick={() => {
                  setOpenEditEducationModal(false)

                }}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "15px",
                  color: "black",
                  fontSize: "35px",
                }}
              />

              <div id="Type1">
                <h3>Education <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select your Education Type.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "1" }}
                    value={AddEducationType}
                    onChange={(e) => setAddEducationType(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select Education --
                    </MenuItem>
                    <MenuItem value="10">10th </MenuItem>
                    <MenuItem value="12">12th </MenuItem>
                    <MenuItem value="Graduation">Graduation / Diploma</MenuItem>
                    <MenuItem value="Masters">
                      Masters / Post - Graduation
                    </MenuItem>
                    <MenuItem value="Phd">Doctorate / Phd </MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddEducationType}
                    tabIndex="1"
                    onChange={(e) => setAddEducationType(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Education-- </option>

                    <option value="10"> 10th </option>
                    <option value="12"> 12th </option>
                    <option value="Graduation"> Graduation / Diploma </option>
                    <option value="Masters"> Masters / Post - Graduation </option>
                    <option value="Phd"> Doctorate / Phd </option>
                  </select>
                </div>
              </div>

              {AddEducationType == "10" || AddEducationType == "12" ? (
                <div id="Type1">
                  <h3>School Name <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please enter your school name here.</p>
                  <input
                    type="text"
                    placeholder="Enter school name"
                    value={AddSchoolName}
                    onChange={(e) => setAddSchoolName(e.target.value)}
                    tabIndex={"2"}
                  />
                </div>
              ) : (
                <>
                  <div id="Type1">
                    <h3>College / University Name <span style={{ color: 'red' }}>*</span></h3>
                    <p>Please enter your University / College name here.</p>
                    {/* <FormControl style={{ width: "100%" }}>
                      <Select
                        inputProps={{ tabIndex: "5" }}
                        value={AddCollegeName}
                        onChange={(e) => setAddCollegeName(e.target.value)}
                        style={{
                          width: "100%",
                          height: "42px",
                          color: "#666666",
                        }}
                      >
                        <MenuItem value="0" disabled>
                          -- Select College/ University --{" "}
                        </MenuItem>
                        <MenuItem value="IIT"> IIT </MenuItem>

                     
                        <MenuItem value="other"> Other </MenuItem>
                      </Select>
                    </FormControl> */}
                    <input
                      type="text"
                      placeholder="Enter College name"
                      value={AddCollegeName}
                      // onChange={(e) => setAddOtherCollegeName(e.target.value)}
                      onChange={(e) => setAddCollegeName(e.target.value)}
                      tabIndex={"2"}
                    />
                  </div>

                  {AddCollegeName == "other" && (
                    <div id="Type1">
                      <p>Please specify other College name.</p>
                      <input
                        type="text"
                        placeholder="Enter College name"
                        value={AddOtherCollegeName}
                        // onChange={(e) => setAddOtherCollegeName(e.target.value)}
                        onChange={handleOtherCollegeName}
                      />
                    </div>
                  )}
                </>
              )}

              {AddEducationType == "10" || AddEducationType == "12" ? (
                <div id="Type1">
                  <h3>School Board Name </h3>
                  <p>
                    Please enter the education board to which your school was
                    affilated to.
                  </p>
                  <input
                    type="text"
                    placeholder="Enter name eg: CBSE or State Board"
                    value={AddSchoolBoard}
                    onChange={(e) => setAddSchoolBoard(e.target.value)}
                    tabIndex={"3"}
                  />
                </div>
              ) : (
                <>
                  <div id="Type1" >
                    <h3>Degree <span style={{ color: 'red' }}>*</span></h3>
                    <p>Please choose your college degree program.</p>
                    <div style={{ width: "100%" }}>
                      <Autocomplete
                        value={AddDegree}
                        onChange={(event, newValue) => setAddDegree(newValue)}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) =>
                          setInputValue(newInputValue)
                        }
                        tabIndex={"4"}
                        disablePortal
                        // options={DegreeList.map(
                        //   (degree) => degree.degree_title
                        // ).concat(["Other"])}
                        options={options}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Select Degree Program"
                            variant="outlined"
                            style={{
                              width: "100%",
                              height: "42px",
                              color: "#666666",
                              border: "none",
                              outline: "0",
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem
                            {...props}
                            key={option}
                            value={option}
                            disabled={option === "0"}
                          >
                            {option}
                          </MenuItem>
                        )}
                        getOptionLabel={(option) => option}
                      />
                    </div>
                  </div>

                  {AddDegree == "other" && (
                    <div id="Type1">
                      <p>Please specify other degree program.</p>
                      <input
                        type="text"
                        placeholder="Enter degree name here"
                        value={AddOtherDegree}
                        // onChange={(e) => setAddOtherDegree(e.target.value)}
                        onChange={handleAddotherDegree}
                      />
                    </div>
                  )}
                </>
              )}

              {AddEducationType == "10" || AddEducationType == "12" ? (
                AddEducationType == "10" ? (
                  <div id="Type1">
                    <h3>Specilazation </h3>
                    <p>Enter the Specilazation here</p>
                    <input
                      type="text"
                      placeholder="Enter specialization"
                      value={AddSchoolSpecilization}
                      // onChange={(e) =>
                      //   setAddSchoolSpecilization(e.target.value)
                      // }
                      onChange={handleSchoolSpecilization}
                      tabIndex={"5"}
                    />
                  </div>
                ) : (
                  <div id="Type1">
                    <h3>Specilazation <span style={{ color: 'red' }}>*</span></h3>
                    <p>
                      Please specify the specilization like Non-Medical ,
                      Medical , etc.
                    </p>
                    <input
                      type="text"
                      placeholder="Enter specialization"
                      value={AddSchoolSpecilization}
                      // onChange={(e) =>
                      //   setAddSchoolSpecilization(e.target.value)
                      // }
                      onChange={handleSchoolSpecilization}
                      tabIndex={"5"}
                    />
                  </div>
                )
              ) : (
                <div id="Type1">
                  <h3>Specialization <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please specify degree specialization.</p>
                  <input
                    type="text"
                    placeholder="Enter specialization here"
                    value={AddCollegeSpecilization}
                    // onChange={(e) => setAddCollegeSpecilization(e.target.value)}
                    onChange={handleCollegeSpecilization}
                    tabIndex={"5"}
                  />
                </div>
              )}

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "46% 46%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div id="Type1">
                  <h3>Start Year <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please select the start year.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "6" }}
                      value={AddStartYear}
                      onChange={(e) => setAddStartYear(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "gray",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select start year --{" "}
                      </MenuItem>
                      {yearsArray.map((year, index) => {
                        return (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                  <div className="SelectElement" >
                    <select value={AddStartYear}
                      tabIndex="6"
                      onChange={(e) => setAddStartYear(e.target.value)}
                      style={{ width: "100%", height: "42px", color: "gray" }}
                    >
                      <option value="0" disabled> --Select start year-- </option>
                      {yearsArray.map((year, index) => {
                        return (
                          <option key={index} value={year}> {year} </option>);
                      })}
                    </select>
                  </div>
                </div>
                <div id="Type1">
                  <h3>End Year <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please select the end year.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "7" }}
                      value={AddEndYear}
                      onChange={(e) => setAddEndYear(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "gray",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select end year --{" "}
                      </MenuItem>
                      {yearsArray.map((year, index) => {
                        return (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}

                  <div className="SelectElement" >
                    <select value={AddEndYear}
                      tabIndex="7"
                      onChange={(e) => setAddEndYear(e.target.value)}
                      style={{ width: "100%", height: "42px", color: "gray" }}
                    >
                      <option value="0" disabled> --Select end year-- </option>
                      {yearsArray.map((year, index) => {
                        return (
                          <option key={index} value={year}> {year} </option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>

              <div id="Type1">
                <h3>Percentage Obtained <span style={{ color: 'red' }}>*</span></h3>
                <p>Please specify percentage obtained.</p>
                <input
                  type="text"
                  placeholder="Enter percentage here."
                  value={AddPercentage}
                  // onChange={(e) => setAddPercentage(e.target.value)}
                  onChange={handlePercentage}
                  tabIndex={"8"}
                />
              </div>

              <div>
                <Button
                  className="Button_1"
                  variant="contained"
                  style={{ marginRight: "12px" }}
                  onClick={editEducation}
                  tabIndex={"9"}
                >
                  Update Education
                </Button>
                <Button
                  onClick={() => {
                    setOpenEditEducationModal(false);
                    setAddEducationType("0");
                    setAddSchoolBoard("");
                    setAddCollegeName("");
                    setAddOtherCollegeName("");
                    setAddSchoolBoard("");
                    setAddDegree("");
                    setAddOtherDegree("");
                    setAddSchoolSpecilization("");
                    setAddCollegeSpecilization("");
                    setAddStartYear("0");
                    setAddEndYear("0");
                    setAddPercentage("");
                  }}
                  tabIndex={"10"}
                  className="Button_2"
                  variant="outlined"
                  color="inherit"
                >
                  Cancel
                </Button>
              </div>
            </div>
          ))}
        </Box>
      </Modal>

      {/* Modal for adding the Experience details */}
      <Modal open={OpenAddExperienceModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Add Experience Details
            </H2>
            <CloseIcon
              onClick={() => {
                setOpenAddExperienceModal(false)

              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            />


            <p style={{ color: "grey" }}>
              {" "}
              Including information such as your job title and the name of the
              company helps employers grasp your work experience.
            </p>

            <div id="Type1">
              <h3>Job Title <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please provide your job title in the company.</p>
              <input
                type="text"
                placeholder="Enter job title Ex: Developer "
                value={AddJobTitle}
                onChange={(e) => setAddJobTitle(e.target.value)}
                tabIndex={"1"}
              />
            </div>

            <div id="Type1">
              <h3>Company Name <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please provide the full name of the company.</p>
              <input
                type="text"
                placeholder="Enter Company name Ex: Microsoft "
                value={AddCompanyName}
                onChange={(e) => setAddCompanyName(e.target.value)}
                tabIndex={"2"}
              />
            </div>

            <div id="Type1">
              <h3>Company Location <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please provide the location of the company located.</p>
              <input
                type="text"
                placeholder="Enter Company location Ex: City , State ( Gurgaon , Haryana )"
                value={AddCompanyLocation}
                onChange={(e) => setAddCompanyLocation(e.target.value)}
                tabIndex={"3"}
              />
            </div>

            <div id="Type1">
              <h3>Employment Type <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please provide the employment type.</p>
              <RadioGroup
                defaultValue="Full Time"
                value={AddEmploymentType}
                onChange={(e) => setAddEmploymentType(e.target.value)}
                tabIndex={"4"}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <FormControlLabel
                    value="FullTime"
                    control={<Radio />}
                    className="checkBoxStyling"
                    label="Full Time"

                  />
                  <FormControlLabel
                    value="Internship"
                    control={<Radio />}
                    className="checkBoxStyling"
                    label="Internship"
                  />
                </div>
              </RadioGroup>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "46% 46%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div id="Type1">
                <h3>Joining Date <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select the joining month.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "5" }}
                    value={AddJoiningMonth}
                    onChange={(e) => setAddJoiningMonth(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select Month --{" "}
                    </MenuItem>
                    {MonthsArray.map((month, index) => {
                      return (
                        <MenuItem key={index} value={month}>
                          {month}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddJoiningMonth}
                    tabIndex="5"
                    onChange={(e) => setAddJoiningMonth(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Month -- </option>
                    {MonthsArray.map((month, index) => {
                      return (
                        <option key={index} value={month}> {month} </option>);
                    })}
                  </select>
                </div>
              </div>
              <div id="Type1">
                <h3 style={{ color: "white" }}>.</h3>
                <p>Please select the joining year.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "6" }}
                    value={AddJoiningYear}
                    onChange={(e) => setAddJoiningYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select year --{" "}
                    </MenuItem>
                    {yearsArray.map((year, index) => {
                      return (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddJoiningYear}
                    tabIndex="6"
                    onChange={(e) => setAddJoiningYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select year-- </option>
                    {yearsArray.map((year, index) => {
                      return (
                        <option key={index} value={year}> {year} </option>);
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div id="Type2">
              <input
                type="checkbox"
                id="CurrentJobConfim"
                value={AddIfCurrentJob}
                onChange={() => setAddIfCurrentJob(!AddIfCurrentJob)}
              />
              <label htmlFor="CurrentJobConfim">
                <h3>Are you currently working in this employment </h3>
              </label>
            </div>

            {!AddIfCurrentJob && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "46% 46%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div id="Type1">
                  <h3>Ending Date <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please select the ending month.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "7" }}
                      value={AddEndingMonth}
                      onChange={(e) => setAddEndingMonth(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "gray",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select Month --{" "}
                      </MenuItem>
                      {MonthsArray.map((month, index) => {
                        return (
                          <MenuItem key={index} value={month}>
                            {month}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                  <div className="SelectElement" >
                    <select value={AddEndingMonth}
                      tabIndex="7"
                      onChange={(e) => setAddEndingMonth(e.target.value)}
                      style={{ width: "100%", height: "42px", color: "gray" }}
                    >
                      <option value="0" disabled> --Select Month -- </option>
                      {MonthsArray.map((month, index) => {
                        return (
                          <option key={index} value={month}> {month} </option>);
                      })}
                    </select>
                  </div>
                </div>
                <div id="Type1">
                  <h3 style={{ color: "white" }}>.</h3>
                  <p>Please select the ending year.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "8" }}
                      value={AddEndingYear}
                      onChange={(e) => setAddEndingYear(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "gray",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select year --{" "}
                      </MenuItem>
                      {yearsArray.map((year, index) => {
                        return (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}

                  <div className="SelectElement" >
                    <select value={AddEndingYear}
                      tabIndex="8"
                      onChange={(e) => setAddEndingYear(e.target.value)}
                      style={{ width: "100%", height: "42px", color: "gray" }}
                    >
                      <option value="0" disabled> --Select  year-- </option>
                      {yearsArray.map((year, index) => {
                        return (
                          <option key={index} value={year}> {year} </option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {AddIfCurrentJob &&
              <div id="Type1">
                <h3>Notice Period <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select the notice period for your current company</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "7" }}
                    value={AddNoticePeriod}
                    onChange={(e) => setAddNoticePeriod(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <MenuItem value="0" disabled>-- Select -- </MenuItem>
                    <MenuItem value="1">1 Month </MenuItem>
                    <MenuItem value="2">2 Months</MenuItem>
                    <MenuItem value="3">3 Months</MenuItem>
                    <MenuItem value="4">4 Months</MenuItem>
                    <MenuItem value="5">5 Months</MenuItem>
                    <MenuItem value="6">6 Months</MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddNoticePeriod}
                    tabIndex="7"
                    onChange={(e) => setAddNoticePeriod(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select -- </option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>

                  </select>
                </div>
              </div>
            }

            <div id="Type1">
              <h3>Skills <span style={{ color: 'red' }}>*</span></h3>
              <p>Please choose your Skills.</p>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  options={skillSetList}
                  value={experienceSkills}
                  getOptionLabel={(option) => option.skill}
                  tabIndex={"9"}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .css-16d15bc-MuiInputBase-root-MuiInput-root::before ":
                    {
                      borderColor: "white",
                    },
                  }}
                  disablePortal
                  // onChange={(e, newValue) => setExperienceSkills(newValue)}
                  // onInputChange={changeinput}
                  onChange={handleExpSkillsChange}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Please Choose Your Tech Skills or Choose Other"
                    />
                  )}
                />
                <Box
                  mt={3}
                  mb={3}
                  sx={{
                    "& > :not(:last-child)": { marginRight: 1 },
                    "& > *": { marginBottom: 1 },
                  }}
                >
                  {showOtherExpSkillsInput && (
                    <TextField
                      variant="outlined"
                      placeholder="Please specify other skill"
                      fullWidth
                      onKeyPress={(e) => {
                        handleExpSkillKeyPress(e);
                      }}
                    />
                  )}

                  {experienceSkills &&
                    experienceSkills.map((v) => (
                      <Chip
                        key={v.id}
                        label={v.skill}
                        style={{ margin: "5px" }}
                        onDelete={onDeleteExpSkill(v.id)}
                      />
                    ))}
                </Box>
              </div>
            </div>

            {AddYourExpSkills == "other" && (
              <div id="Type1">
                <p>Please specify your other skills.</p>
                <input
                  type="text"
                  placeholder="Enter your other skills"
                  value={AddYourOtherExpSkills}
                  onChange={(e) => setAddYourOtherExpSkills(e.target.value)}
                />
              </div>
            )}

            <div id="Type1">
              <h3>Job Description <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please provide the description of your job.</p>
              <OutlinedInput
                // autoFocus={true}
                multiline
                rows={5} // Adjust the number of rows as needed
                type="text"
                value={AddJobDescription}
                onChange={(e) => setAddJobDescription(e.target.value)}
                tabIndex={"10"}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                placeholder="Type description here ."
              />
            </div>

            <div>
              <Button
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
                onClick={addExperience}
                tabIndex={"11"}
              >
                Add Experience
              </Button>
              <Button
                onClick={() => {
                  setOpenAddExperienceModal(false);
                  setAddJobTitle("");
                  setAddCompanyName("");
                  setAddCompanyLocation("");
                  setAddEmploymentType("");
                  setAddJoiningMonth("0");
                  setAddJoiningYear("0");
                  setAddEndingMonth("0");
                  setAddEndingYear("0");
                  setAddIfCurrentJob("");
                  setAddJobDescription("");
                  setExperienceSkills([]);
                  setAddNoticePeriod("0")
                }}
                tabIndex={"12"}
                className="Button_2"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for editing the Experience details */}
      <Modal open={OpenEditExperienceModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Edit Experience Details
            </H2>
            <CloseIcon
              onClick={() => {
                setOpenEditExperienceModal(false)

              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            />

            <div id="Type1">
              <h3>Job Title <span style={{ color: 'red' }}>*</span> </h3>
              <p>Please provide your job title in the company.</p>
              <input
                type="text"
                placeholder="Enter job title Ex: Developer "
                value={AddJobTitle}
                onChange={(e) => setAddJobTitle(e.target.value)}
                tabIndex={"1"}
              />
            </div>

            <div id="Type1">
              <h3>Company Name <span style={{ color: 'red' }}>*</span></h3>
              <p>Please provide the full name of the company.</p>
              <input
                type="text"
                placeholder="Enter Company name Ex: Microsoft "
                value={AddCompanyName}
                onChange={(e) => setAddCompanyName(e.target.value)}
                tabIndex={"2"}
              />
            </div>

            <div id="Type1">
              <h3>Company Location <span style={{ color: 'red' }}>*</span></h3>
              <p>Please provide the location of the company located.</p>
              <input
                type="text"
                placeholder="Enter Company location Ex: City , State ( Gurgaon , Haryana )"
                value={AddCompanyLocation}
                onChange={(e) => setAddCompanyLocation(e.target.value)}
                tabIndex={"3"}
              />
            </div>

            <div id="Type1">
              <h3>Employment Type <span style={{ color: 'red' }}>*</span></h3>
              <p>Please provide the employment type.</p>
              <RadioGroup
                defaultValue="Full Time"
                value={AddEmploymentType}
                onChange={(e) => setAddEmploymentType(e.target.value)}
                tabIndex={"4"}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <FormControlLabel
                    value="FullTime"
                    control={<Radio />}
                    className="checkBoxStyling"
                    label="Full Time"
                  />
                  <FormControlLabel
                    value="Internship"
                    control={<Radio />}
                    className="checkBoxStyling"
                    label="Internship"
                  />
                </div>
              </RadioGroup>
            </div>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "46% 46%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div id="Type1">
                <h3>Joining Date <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select the joining month.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "5" }}
                    value={AddJoiningMonth}
                    onChange={(e) => setAddJoiningMonth(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "#666666" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select Month --{" "}
                    </MenuItem>
                    {MonthsArray.map((month, index) => {
                      return (
                        <MenuItem key={index} value={month}>
                          {month}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddJoiningMonth}
                    tabIndex="5"
                    onChange={(e) => setAddJoiningMonth(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select Month -- </option>
                    {MonthsArray.map((month, index) => {
                      return (
                        <option key={index} value={month}> {month} </option>);
                    })}
                  </select>
                </div>
              </div>
              <div id="Type1">
                <h3 style={{ color: "white" }}>.</h3>
                <p>Please select the joining year.</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "6" }}
                    value={AddJoiningYear}
                    onChange={(e) => setAddJoiningYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "#666666" }}
                  >
                    <MenuItem value="0" disabled>
                      -- Select year --{" "}
                    </MenuItem>
                    {yearsArray.map((year, index) => {
                      return (
                        <MenuItem key={index} value={year}>
                          {year}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddJoiningYear}
                    tabIndex="6"
                    onChange={(e) => setAddJoiningYear(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select year-- </option>
                    {yearsArray.map((year, index) => {
                      return (
                        <option key={index} value={year}> {year} </option>);
                    })}
                  </select>
                </div>
              </div>
            </div>

            <div id="Type2">
              <input
                type="checkbox"
                id="CurrentJobConfim"
                checked={AddIfCurrentJob}
                onChange={() => setAddIfCurrentJob(!AddIfCurrentJob)}
              />
              <label htmlFor="CurrentJobConfim">
                <h3>Are you currently working in this employment </h3>
              </label>
            </div>

            {!AddIfCurrentJob && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "46% 46%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div id="Type1">
                  <h3>Ending Date <span style={{ color: 'red' }}>*</span></h3>
                  <p>Please select the ending month.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "7" }}
                      value={AddEndingMonth}
                      onChange={(e) => setAddEndingMonth(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "#666666",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select Month --{" "}
                      </MenuItem>
                      {MonthsArray.map((month, index) => {
                        return (
                          <MenuItem key={index} value={month}>
                            {month}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                  <div className="SelectElement" >
                    <select value={AddEndingMonth}
                      tabIndex="7"
                      onChange={(e) => setAddEndingMonth(e.target.value)}
                      style={{ width: "100%", height: "42px", color: "gray" }}
                    >
                      <option value="0" disabled> --Select Month -- </option>
                      {MonthsArray.map((month, index) => {
                        return (
                          <option key={index} value={month}> {month} </option>);
                      })}
                    </select>
                  </div>
                </div>
                <div id="Type1">
                  <h3 style={{ color: "white" }}>.</h3>
                  <p>Please select the ending year.</p>
                  {/* <FormControl style={{ width: "100%" }}>
                    <Select
                      inputProps={{ tabIndex: "8" }}
                      value={AddEndingYear}
                      onChange={(e) => setAddEndingYear(e.target.value)}
                      style={{
                        width: "100%",
                        height: "42px",
                        color: "#666666",
                      }}
                    >
                      <MenuItem value="0" disabled>
                        -- Select year --{" "}
                      </MenuItem>
                      {yearsArray.map((year, index) => {
                        return (
                          <MenuItem key={index} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl> */}
                  <div className="SelectElement" >
                    <select value={AddEndingYear}
                      tabIndex="8"
                      onChange={(e) => setAddEndingYear(e.target.value)}
                      style={{ width: "100%", height: "42px", color: "gray" }}
                    >
                      <option value="0" disabled> --Select  year-- </option>
                      {yearsArray.map((year, index) => {
                        return (
                          <option key={index} value={year}> {year} </option>);
                      })}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {AddIfCurrentJob &&
              <div id="Type1">
                <h3>Notice Period <span style={{ color: 'red' }}>*</span></h3>
                <p>Please select the notice period for your current company</p>
                {/* <FormControl style={{ width: "100%" }}>
                  <Select
                    inputProps={{ tabIndex: "7" }}
                    value={AddNoticePeriod}
                    onChange={(e) => setAddNoticePeriod(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "#666666" }}
                  >
                    <MenuItem value="0" disabled>-- Select -- </MenuItem>
                    <MenuItem value="1">1 Month </MenuItem>
                    <MenuItem value="2">2 Months</MenuItem>
                    <MenuItem value="3">3 Months</MenuItem>
                    <MenuItem value="4">4 Months</MenuItem>
                    <MenuItem value="5">5 Months</MenuItem>
                    <MenuItem value="6">6 Months</MenuItem>
                  </Select>
                </FormControl> */}
                <div className="SelectElement" >
                  <select value={AddNoticePeriod}
                    tabIndex="7"
                    onChange={(e) => setAddNoticePeriod(e.target.value)}
                    style={{ width: "100%", height: "42px", color: "gray" }}
                  >
                    <option value="0" disabled> --Select -- </option>
                    <option value="1">1 Month</option>
                    <option value="2">2 Months</option>
                    <option value="3">3 Months</option>
                    <option value="4">4 Months</option>
                    <option value="5">5 Months</option>
                    <option value="6">6 Months</option>

                  </select>
                </div>
              </div>
            }

            <div id="Type1">
              <h3>Skills <span style={{ color: 'red' }}>*</span></h3>
              <p>Please choose your Skills.</p>
              <div style={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  options={skillSetList}
                  value={experienceSkills}
                  getOptionLabel={(option) => option.skill}
                  tabIndex={"9"}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .css-16d15bc-MuiInputBase-root-MuiInput-root::before ":
                    {
                      borderColor: "white",
                    },
                  }}
                  disablePortal
                  // onChange={(e, newValue) => setExperienceSkills(newValue)}
                  onChange={handleExpSkillsChange}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Please Choose Your Tech Skills or Choose Other"
                    />
                  )}
                />
                <Box
                  mt={3}
                  mb={3}
                  sx={{
                    "& > :not(:last-child)": { marginRight: 1 },
                    "& > *": { marginBottom: 1 },
                  }}
                >
                  {showOtherExpSkillsInput && (
                    <TextField
                      variant="outlined"
                      placeholder="Please specify other skill"
                      fullWidth
                      onKeyDown={(e) => {
                        handleExpSkillKeyPress(e);
                      }}
                    />
                  )}
                  {experienceSkills ? (
                    experienceSkills.map((v) => (
                      <Chip
                        key={v.id ? v.id : v}
                        label={v.skill ? v.skill : v}
                        style={{ margin: "5px" }}
                        onDelete={() => {
                          onDeleteExpEditSkill(v);
                        }}
                      />
                    ))
                  ) : (
                    <p>No Skills Selected</p>
                  )}
                </Box>
              </div>
            </div>

            {AddYourExpSkills == "other" && (
              <div id="Type1">
                <p>Please specify your other skills.</p>
                <input
                  type="text"
                  placeholder="Enter your other skills"
                  value={AddYourOtherExpSkills}
                  onChange={(e) => setAddYourOtherExpSkills(e.target.value)}
                />
              </div>
            )}

            <div id="Type1">
              <h3>Job Description <span style={{ color: 'red' }}>*</span></h3>
              <p>Please provide the description of your job.</p>
              <OutlinedInput
                // autoFocus={true}
                multiline
                tabIndex={"10"}
                rows={5} // Adjust the number of rows as needed
                type="text"
                value={AddJobDescription}
                onChange={(e) => setAddJobDescription(e.target.value)}
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  fontSize: "14px",
                }}
                placeholder="Type description here ."
              />
            </div>

            <div>
              <Button
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
                onClick={editExperience}
                tabIndex={"11"}
              >
                Update Experience
              </Button>
              <Button
                onClick={() => {
                  setOpenEditExperienceModal(false);
                  setAddJobTitle("");
                  setAddCompanyName("");
                  setAddCompanyLocation("");
                  setAddEmploymentType("");
                  setAddJoiningMonth("0");
                  setAddJoiningYear("0");
                  setAddEndingMonth("0");
                  setAddEndingYear("0");
                  setAddIfCurrentJob("");
                  setAddJobDescription("");
                  setExperienceSkills([]);
                  setAddNoticePeriod("0");
                }}
                tabIndex={"12"}
                className="Button_2"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for adding the Your Skills details */}
      <Modal open={OpenAddYourSkillsModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Add Your Skills
            </H2>
            <CloseIcon
              onClick={() => {
                setOpenAddYourSkillsModal(false)

              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            />

            <div id="Type1">
              <h3>Skills <span style={{ color: 'red' }}>*</span></h3>
              <FormControl style={{ width: "100%" }}>
                {/* Tech Skills   */}

                <Autocomplete
                  multiple
                  options={skillSetList}
                  value={techSkills}
                  getOptionLabel={(option) => option.skill}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .css-16d15bc-MuiInputBase-root-MuiInput-root::before ":
                    {
                      borderColor: "white",
                    },
                  }}
                  disablePortal
                  // onChange={(e, newValue) => setTechSkills(newValue)}
                  onChange={handleTechSkillsChange}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Please Choose Your Tech Skills or Choose Other"
                    />
                  )}
                />
                <Box
                  mt={3}
                  mb={3}
                  sx={{
                    "& > :not(:last-child)": { marginRight: 1 },
                    "& > *": { marginBottom: 1 },
                  }}
                >
                  {showOtherTechSkillInput && (
                    <TextField
                      variant="outlined"
                      placeholder="Please specify other skill"
                      fullWidth
                      onKeyPress={(e) => {
                        handleTechSkillKeyPress(e);
                      }}
                    />
                  )}
                  {techSkills.map((v) => (
                    <Chip
                      key={v.id}
                      label={v.skill}
                      style={{ margin: "5px" }}
                      onDelete={onDeleteAddTechSkill(v)}
                    />
                  ))}
                </Box>
              </FormControl>

              {/* Soft Skills   */}
              <FormControl style={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  options={softSkillSetList}
                  getOptionLabel={(option) => option.skill}
                  value={softSkills}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .css-16d15bc-MuiInputBase-root-MuiInput-root::before ":
                    {
                      borderColor: "white",
                    },
                  }}
                  disablePortal
                  // onChange={(e, newValue) => setSoftSkills(newValue)}
                  onChange={handleSoftSkillChange}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Please Choose Your Soft Skills"
                    />
                  )}
                />
                <Box
                  mt={3}
                  sx={{
                    "& > :not(:last-child)": { marginRight: 1 },
                    "& > *": { marginBottom: 1 },
                  }}
                >
                  {AddYourSkills == "other" && (
                    <div id="Type1">
                      <p>Please specify your other skills.</p>
                      <input
                        type="text"
                        placeholder="Enter your other skills"
                        value={AddYourOtherSkills}
                        onChange={(e) => setAddYourOtherSkills(e.target.value)}
                      />
                    </div>
                  )}

                  {softSkills.map((v) => (
                    <Chip
                      key={v.id}
                      label={v.skill}
                      style={{ margin: "5px" }}
                      onDelete={onDeleteAddSoftSkill(v)}
                    />
                  ))}
                </Box>
              </FormControl>
            </div>

            <div>
              <Button
                onClick={handleAddYourSkills}
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
              >
                Add Skills
              </Button>
              <Button
                onClick={() => {
                  setOpenAddYourSkillsModal(false);
                  setAddYourSkills("0");
                  setAddYourOtherSkills("");
                }}
                className="Button_2"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for editing the Your Skills details  */}
      <Modal open={OpenEditYourSkillsModal}>
        <Box sx={ModalStyle2} style={{ width: "60vw", overflowY: "auto" }}>
          <div className="ModalInputs">
            <H2
              style={{
                fontSize: "22px",
                width: "100%",
                fontWeight: "400",
                margin: "10px 0px",
              }}
            >
              Update Your Skills
            </H2>
            <CloseIcon
              onClick={() => {
                setOpenEditYourSkillsModal(false)

              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "15px",
                color: "black",
                fontSize: "35px",
              }}
            />

            <div id="Type1">
              <h3>Skills <span style={{ color: 'red' }}>*</span></h3>
              <FormControl style={{ width: "100%" }}>
                {/* Tech Skills   */}

                <Autocomplete
                  multiple
                  options={skillSetList}
                  value={techSkills}
                  getOptionLabel={(option) => option.skill}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .css-16d15bc-MuiInputBase-root-MuiInput-root::before ":
                    {
                      borderColor: "white",

                    },
                    "& .MuiAutocomplete-listbox": {
                      position: "relative", // Ensure dropdown uses relative positioning within its container
                    },
                  }}
                  disablePortal
                  //   onChange={(e, newValue) => setTechSkills(newValue)}
                  onChange={handleTechSkillsChange}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Please Choose Your Tech Skills or Choose Other"
                    />
                  )}

                />
                <Box
                  mt={3}
                  mb={3}
                  sx={{
                    "& > :not(:last-child)": { marginRight: 1 },
                    "& > *": { marginBottom: 1 },
                  }}
                >
                  {showOtherTechSkillInput && (
                    <TextField
                      variant="outlined"
                      placeholder="Please specify other skill"
                      fullWidth
                      onKeyPress={(e) => {
                        handleTechSkillKeyPress(e);
                      }}
                    />
                  )}
                  {techSkills.map((v) => (
                    <Chip
                      key={v.id ? v.id : v}
                      label={v.skill ? v.skill : v}
                      style={{ margin: "5px" }}
                      onDelete={onDeleteTechSkill(v)}
                    />
                  ))}
                </Box>
              </FormControl>

              {/* Soft Skills   */}
              <FormControl style={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  options={softSkillSetList}
                  getOptionLabel={(option) => option.skill}
                  value={softSkills}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline .css-16d15bc-MuiInputBase-root-MuiInput-root::before ":
                    {
                      borderColor: "white",
                    },
                  }}
                  disablePortal
                  // onChange={(e, newValue) => setSoftSkills(newValue)}
                  onChange={handleSoftSkillChange}
                  renderTags={() => null}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      variant="outlined"
                      placeholder="Please Choose Your Soft Skills"
                    />
                  )}
                />
                <Box
                  mt={3}
                  sx={{
                    "& > :not(:last-child)": { marginRight: 1 },
                    "& > *": { marginBottom: 1 },
                    "& .MuiAutocomplete-listbox": {
                      position: "relative", // Ensure dropdown uses relative positioning within its container
                    },
                  }}
                >
                  {AddYourSkills == "other" && (
                    <div id="Type1">
                      <p>Please specify your other skills.</p>
                      <input
                        type="text"
                        placeholder="Enter your other skills"
                        value={AddYourOtherSkills}
                        onChange={(e) => setAddYourOtherSkills(e.target.value)}
                      />
                    </div>
                  )}
                  {softSkills.map((v) => (
                    <Chip
                      style={{ margin: "5px" }}
                      key={v.id ? v.id : v}
                      label={v.skill ? v.skill : v}
                      onDelete={onDeleteSoftSkill(v)}
                    />
                  ))}
                </Box>
              </FormControl>
            </div>

            <div>
              <Button
                onClick={handleEditYourSkills}
                className="Button_1"
                variant="contained"
                style={{ marginRight: "12px" }}
              >
                Update Skills
              </Button>
              <Button
                onClick={() => {
                  setOpenEditYourSkillsModal(false);
                  setAddYourSkills("0");
                  setAddYourOtherSkills("");
                }}
                className="Button_2"
                variant="outlined"
                color="inherit"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SeekerProfilePage;
